import React from 'react';
import Button from "@/components/common/Buttons/Button";
import {BUTTON_VARIANTS} from "@/constants";
import useGlobals from "@/hooks/useGlobals";

const BeWithUsButton = ({ className= '', variant=BUTTON_VARIANTS.PRIMARY, recapEnabled = false, ...props }) => {
	const beWithUs = useGlobals('beWithUsButtonText')
	return (
		<Button
			className={`${className} be-with-us`}
			variant={variant}
			href={`/badz-z-nami`}
			as="link"
			{...props}
		>
			{beWithUs}
		</Button>
	);
};

export default BeWithUsButton;
