import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-30, &-30i {
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(43.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(22, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-31, &-31i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        // line-height:${getFontSize(20, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-32, &-32i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(24, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(40, BREAKPOINTS.DESKTOP)};
        }
    }
   &-33, &-33i {
        font-size: ${getFontSize(10, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(15, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(18, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(12, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        }
    }
   &-34, &-34i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(16.8, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(16.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(16.8, BREAKPOINTS.DESKTOP)};
        }
    }
   &-35, &-35i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(14, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(19.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(19.2, BREAKPOINTS.DESKTOP)};
        }
    }
   &-36, &-36i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(39, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(56, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(69, BREAKPOINTS.DESKTOP)};
        }
    }
   &-37, &-37i {
		font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
        }
    }
	&-38, &-38i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
		}
	}
	&-39, &-39i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(36.5, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
		}
	}
`
