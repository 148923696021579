import React from 'react';
import styled from 'styled-components';
import CommonButton from "@/components/common/Buttons/Button";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {AnimatePresence, motion} from "framer-motion";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Icon from "@/components/common/Icon/Icon";
import {filters} from "@/components/AccessibilityTool/constants";
import useAccessibilitySettings, {ACTIONS} from "@/components/AccessibilityTool/useAccessibilitySettings";
import RangeControls from "@/components/AccessibilityTool/RangeControlls";

const ToggleButton = styled.div`
    z-index: 10001;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    left: var(--paddingX);
    --size: ${getCssSizeValue(29, BREAKPOINTS.MOBILE)};;
    height: var(--size);
    width: var(--size);
    background-color: ${({theme}) => theme.colors.white};
    border-radius: 50%;
    cursor: pointer;

    .icon {
        svg {
            path, circle {
                fill: ${({theme}) => theme.colors.richBlack};
            !important;
            }
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        --size: ${getCssSizeValue(35, BREAKPOINTS.TABLET)};
        left: calc(0.2 * var(--paddingX));
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(35, BREAKPOINTS.DESKTOP)};
    }
`;

const ToolWrapper = styled(motion.div)`
    position: fixed;
    bottom: 60px;
    left: 20px;
    right: 20px;
    z-index: 10001;
    background: ${({theme}) => theme.colors.richBlack};
    color: ${({theme}) => theme.colors.white};
    transform-origin: bottom left;
    padding: 25px 15px;

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding: 25px 15px;
        right: 40%;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        right: 70%;
		max-height: 50vh;
        bottom: 65px;
        padding: ${getCssSizeValue(25, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(15, BREAKPOINTS.DESKTOP)};
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: 30px;
        font-size: 16px;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-bottom: 24px;
        font-size: 24px;
    }
`;

const CloseResetButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;

    .button {
        width: 100%;
    }
`

const Options = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
	gap: 15px;


    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
    }

    button {
        width: 100%;

        &.active {
            background: ${({theme}) => theme.colors.chilliRed} !important;
        }
    }
`;

export const Button = styled(CommonButton)`
    font-size: 12px;
    padding: 5px 5px !important;

	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		font-size: 16px !important;
		padding: 5px 10px !important;
	}
    
`

const AccessibilityTool = () => {
	const {
		state,
		dispatch,
		reset,
		setFilter,
		toggleHighlightLinks,
		toggleHighlightHeadings,
		toggleScreenReader,
		incrementRangeValue,
		decrementRangeValue
	} = useAccessibilitySettings();

	return (
		<>
			<ToggleButton onClick={() => dispatch({type: ACTIONS.TOGGLE_EXPAND})}>
				<Icon name="accessibility"/>
			</ToggleButton>

			<AnimatePresence mode='wait'>
				{state.isExpanded && (
					<ToolWrapper
						className='tool-wrapper'
						initial={{scale: 0.7, opacity: 0}}
						animate={{scale: 1, opacity: 1}}
						exit={{scale: 0, opacity: 0}}
					>
						<Header>
							<h5 className="typo-borna">Accessibility Tools</h5>
						</Header>

						<CloseResetButtons>
							<Button variant="tertiary" onClick={() => reset()}>Reset</Button>
							<Button variant="tertiary"
								onClick={() => dispatch({type: ACTIONS.TOGGLE_EXPAND})}>Close</Button>
						</CloseResetButtons>

						<Options>
							<Button
								variant="tertiary"
								className={`${state.highlightLinks ? 'active' : ''}`}
								onClick={() => toggleHighlightLinks(!state.highlightLinks)}
							>
								Link Highlight
							</Button>

							<Button
								variant="tertiary"
								className={`${state.highlightHeadings ? 'active' : ''}`}
								onClick={() => toggleHighlightHeadings(!state.highlightHeadings)}
							>
								Heading Highlight
							</Button>
							{filters.map(({label, filter}) => {
								if (filter === state.activeFilter) {
									console.log(filter)
									console.log(state.activeFilter)
								}
								return (
									<Button
										key={label}
										variant="tertiary"
										className={state.activeFilter === filter ? "active" : ""}
										onClick={() => setFilter(filter === state.activeFilter ? null : filter)}
									>
										{label}
									</Button>
								)})}
							<Button
								variant="tertiary"
								className={state.screenReader ? "active" : ""}
								onClick={() => toggleScreenReader(!state.screenReader)}
							>
								Screen Reader
							</Button>
						</Options>

						<RangeControls
							incrementRangeValue={incrementRangeValue}
							decrementRangeValue={decrementRangeValue}
						/>
					</ToolWrapper>
				)}
			</AnimatePresence>
		</>
	);
};

export default AccessibilityTool;
