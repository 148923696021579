import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-100, &-100i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(50.4, BREAKPOINTS.DESKTOP)};
        }
    }
    &-101, &-101i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(14.4, BREAKPOINTS.MOBILE)};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(12, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(14.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(12, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(14.4, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-102, &-102i {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(20.8, BREAKPOINTS.MOBILE)};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(20.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(20.8, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-103, &-103i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(27, BREAKPOINTS.MOBILE)};
	    font-weight: ${({ theme }) => theme.fontWeight.regular};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(27, BREAKPOINTS.TABLET)};
            font-weight: ${({ theme }) => theme.fontWeight.medium};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(57.6, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-104, &-104i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({ theme }) => theme.fontWeight.medium};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-105, &-105i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
    	font-weight: ${({ theme }) => theme.fontWeight.regular};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(20.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-106, &-106i {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(28.8, BREAKPOINTS.MOBILE)};
    	font-weight: ${({ theme }) => theme.fontWeight.bold};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(57.6, BREAKPOINTS.TABLET)};
            font-weight: ${({ theme }) => theme.fontWeight.medium};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(50.4, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-107, &-107i {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    	font-weight: ${({ theme }) => theme.fontWeight.regular};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(33, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-108, &-108i {
        font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
    	font-weight: ${({ theme }) => theme.fontWeight.medium};
  
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(33, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-109, &-109i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(27, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-weight: ${({theme}) => theme.fontWeight.medium};
            font-size: ${getFontSize(25, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(25, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.DESKTOP)};
        }
	}
`
