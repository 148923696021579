import React, {useState} from 'react';
import styled from "styled-components";
import {styles} from "@/components/common/Buttons/Button";
import ArrowDown from '../../assets/dropdown_arrow_dark_blue.svg';
import {BUTTON_TYPE, BUTTON_VARIANTS} from "@/constants";

const SelectWrapper = styled.div`
	position: relative;
	display: inline-block;
	height: 50px;
	z-index: 10;
	//width: 100%;
	svg {
		position: absolute;
		right: 15px;
		top: calc(50% - 5px);
		pointer-events: none;
		transition: transform 0.15s ease-in-out;
		transform: rotate(0);
	}
	&.opened {
		svg {
			transition: transform 0.3s ease-in-out;
			transform: rotate(180deg);
		}
	}
`;

const Select = styled.div`
	appearance: none;
	//position: relative;
	padding: 15px 50px 15px 17px !important;
	height: 50px;
	position: relative;
	${styles}
`

const OtherOptionsWrapper = styled.div`
	background-color: ${({theme}) => theme.colors.white};
	// background-color: ${({theme}) => theme.colors.antiflashWhite};
	border-radius: 6px;
	opacity: 0;
	width: 100%;
	border: 1px solid ${({theme}) => theme.colors.richBlack};
	padding: 15px 50px 15px 17px;
	//height: 100px;
	position: absolute;
	top: calc(100% + 6px);
	left: 0;
	transition: opacity 0.3s ease-in-out 0.2s, transform 0.1s ease-in-out;
	transform: scaleY(0);
	transform-origin: top;
	&.opened {
		opacity: 1;
		transition: opacity 0.3s ease-in-out 0.2s, transform 0.1s ease-in-out;
		transform: scaleY(1);
		transform-origin: top;
	}
`

const CurrentOption = styled.p`
	color: ${({theme}) => theme.colors.richBlack};
`

const Option = styled.option`
	//padding: 10px;
	//font-size: 16px;
	margin-bottom: 8px;
	color: ${({theme}) => theme.colors.richBlack};
`;
export const DROPDOWN_VARIANTS = {
	...BUTTON_VARIANTS,
	MOBILE: 'mobile'
}

export const DROPDOWN_TYPE = BUTTON_TYPE

const Dropdown = ({
	options = [],
	variant=DROPDOWN_VARIANTS.PRIMARY,
	type=DROPDOWN_TYPE.CLASSIC,
	className='',
	onChange,
	children,
	...props
}) => {
	const [isSelectOpened, setIsSelectOpened] = useState(false)
	return (
		<SelectWrapper className={isSelectOpened ? 'opened' : ''}>
			<Select className={`dropdown ${className} ${variant} ${type}`} onChange={onChange} onClick={() => setIsSelectOpened(!isSelectOpened)} {...props}>
				<OtherOptionsWrapper className={isSelectOpened ? 'opened' : ''}>
					{options.map((option, index) => (
						<Option className="typo-strawford typo-strawford-34" key={option?.id || index} value={option.value}>{option.label}</Option>
					))}
				</OtherOptionsWrapper>
				<Option value="">{children}</Option>
				{/*<Option value="">{selectedValue}</Option>*/}
			</Select>
			<ArrowDown/>
		</SelectWrapper>
	);
};

export default Dropdown;
