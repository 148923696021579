import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {styles as ButtonStyles} from "@/components/common/Buttons/Button";
import {DROPDOWN_VARIANTS} from "@/components/common/Dropdown";
import {getCssSizeValue, isFunction, isStringEmpty} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Icon from "@/components/common/Icon/Icon";
import {ICONS} from "@/constants";
import {useScrollDirection} from "@/hooks/useScrollContext";

const DropdownWrapper = styled.div`
	--height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	position: relative;
	display: inline-block;
	width: fit-content;
	z-index: 10;
	min-width: ${getCssSizeValue(130, BREAKPOINTS.MOBILE)};
	height: var(--height);
	min-height: 1px;
	 @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        min-width: ${getCssSizeValue(220)};
     }
	
	.icon {
		pointer-events: none;
        width: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)};
		transition: transform 0.25s ease-in-out !important;
		transform: rotate(0) !important;
		svg  {
			transform: rotate(90deg) !important;
			path{
				fill: ${({theme}) => theme.colors.richBlack}!important;	
			}
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            width: ${getCssSizeValue(11)};
		}
	}
	
	&.opened {
		z-index: 12;
		.icon {
			transform: rotate(180deg)!important;
		}
	}
	
	&.short{
        min-width: unset;
		aspect-ratio: 1;
		.selected{
			display: flex;
			justify-content: center;
			padding-right: ${getCssSizeValue(8.4, BREAKPOINTS.MOBILE)};
		}
	}
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --height: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
    }
`;

const Selected = styled.div`
    ${ButtonStyles};//BUTTON STYLES
	min-width: 100%;
    border-radius: 4px;
	background: ${({theme}) => theme.colors.white};
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	min-height: 1px;
   	height: var(--height);
    padding: ${getCssSizeValue(6, BREAKPOINTS.MOBILE)} 
		${getCssSizeValue(16, BREAKPOINTS.MOBILE)} 
		${getCssSizeValue(6, BREAKPOINTS.MOBILE)} 
		${getCssSizeValue(8.4, BREAKPOINTS.MOBILE)};
	text-align: left;
	color: ${({theme}) => theme.colors.richBlack};
	position: relative;
	line-height: normal !important;
	
    &.variant-mobile {
        --color: ${({ theme }) => theme.colors.white};
        --supp-color: ${({ theme }) => theme.colors.richBlack};
        border: solid 1px ${({ theme }) => theme.colors.periwinkle};
		border-radius: 6px;
    }
	
	&.lang-selector {
		min-width: ${getCssSizeValue(90)};
	}
	&.primary {
		svg {
			path {
				fill: white;
			}
		}
	}
	&.no-border {
		border: none!important;
	}

	border: 1px solid ${({ theme }) => theme.colors.richBlack} !important;
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
	}
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        border-radius: 6px;
        padding: ${getCssSizeValue(14)} ${getCssSizeValue(20)} ${getCssSizeValue(14)} ${getCssSizeValue(17)} !important;
    }
`;

const Options = styled.ul`
    min-width: 100%;
	list-style: none;
    word-wrap:break-word;
	padding: ${getCssSizeValue(15)} ${getCssSizeValue(50)}${getCssSizeValue(15)} ${getCssSizeValue(17)};
	border-radius: 6px;
	margin: 6px 0 0 0;
	width: 100%;
	overflow-y: auto;
	max-height: ${getCssSizeValue(130, BREAKPOINTS.MOBILE)};
	opacity: 0;
	transition: opacity 0.3s ease-in-out 0.2s, transform 0.1s ease-in-out !important;
	transform: scaleY(0);
	transform-origin: top;
	background-color: var(--supp-color, transparent);
	color: var(--color, white);
	&.primary {
		--color: ${({ theme }) => theme.colors.white};
		--supp-color: ${({ theme }) => theme.colors.chilliRed};
		border: none;
	}
	&.secondary {
		--color: ${({ theme }) => theme.colors.richBlack};
		--supp-color: ${({ theme }) => theme.colors.antiflashWhite};
		border: none;
	}
	&.tertiary {
		--color: ${({ theme }) => theme.colors.richBlack};
		--supp-color: ${({ theme }) => theme.colors.white};
		border: solid 1px var(--color, white);
	}
    &.mobile {
        --color: ${({ theme }) => theme.colors.white};
        --supp-color: ${({ theme }) => theme.colors.richBlack};
        border: solid 1px ${({ theme }) => theme.colors.periwinkle};
        border-radius: 6px;
    }
	
	
	&.lang-selector {
		min-width: ${getCssSizeValue(90)};
	}
	&.opened {
		opacity: 1 !important;
		transform: scaleY(1) !important;
		transform-origin: top !important;
	}
	&.closed {
		opacity: 0!important;

		transform: scaleY(0)!important;
		transform-origin: top!important;
	}
	
	&.drop-up{
		position: absolute;
		top: 0;
		translate: 0 -110%;
        transform-origin: bottom !important;

        &.opened {
            opacity: 1 !important;
            transform: scaleY(1) !important;
        }
		
        &.closed {
            opacity: 0!important;
            transform: scaleY(0)!important; 
        }
	}
	
	
	&.no-border {
		border: none!important;
	}
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        max-height: ${getCssSizeValue(200)};
    }
`;

const Option = styled.li`
	cursor: pointer;
	margin-bottom: 8px;
	&.disabled {
		pointer-events: none;
		color: ${({theme}) => theme.colors.antiflashWhite};
	}
	&:last-child {
		margin-bottom: 0;
	}
`;

const SingleSelect = ({
	options,
	variant= DROPDOWN_VARIANTS.PRIMARY,
	selectedValue,
	onChange,
	placeholder,
	noBorder,
	isLangSelector,
	className='',
	name = '',
	short=false,
	dropUp=false,
	onDropDownToggle=(open)=>{},
	disabled = false,
	closeOnScroll='',
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef();
	const direction = useScrollDirection()


	useEffect(() => {
		if(isStringEmpty(closeOnScroll)) return
		if(isOpen && direction === closeOnScroll){
			setIsOpen(false)
		}
	}, [direction, closeOnScroll]);

	useEffect(() => {
		const controller = new AbortController()

		if(isOpen){
			const handleClickOutside = (e) => {
				// alert('click outside')
				if (ref.current && !ref.current.contains(e.target)) {
					setIsOpen(false);
				}
			};
			document.addEventListener('mousedown', handleClickOutside, { signal: controller.signal });
		}

		return () => {
			if(isOpen){
				controller.abort()
			}
		};
	}, [isOpen]);

	const handleSelect = (value) => {
		onChange({ target: { value, name } });
		setIsOpen(false);
	};

	const handleToggle = () => {
		if (disabled) return
		setIsOpen(!isOpen)
	}

	useEffect(() => {
		 if (isFunction(onDropDownToggle)){
			onDropDownToggle(isOpen)
		}
	},[onDropDownToggle,isOpen])

	return (
		<DropdownWrapper
			ref={ref}
			className={`select ${isOpen ? 'opened' : ''} ${className} ${short ? 'short' : ''}  `}
		>
			<Selected
				className={`selected display-flex no-hover variant-${variant} ${isLangSelector ? 'lang-selector' : ''} ${noBorder ? 'no-border' : ''}`}
				onClick={handleToggle}
			>
				<p className="typo-strawford typo-strawford-35">
					{selectedValue?.value ? options.find(option => option.value === selectedValue.value)?.label : placeholder}
				</p>
				{!short && (
					<Icon name={ICONS.CHEVRON} />
				)}
				{/*<ArrowDown/>*/}
			</Selected>
			<Options
				className={isOpen ? `options opened ${variant} ${isLangSelector ? 'lang-selector' : ''} ${noBorder ? 'no-border' : ''} ${dropUp ? 'drop-up' : ''}` : `options closed ${variant} ${isLangSelector ? 'lang-selector' : ''} ${noBorder ? 'no-border' : ''} ${dropUp ? 'drop-up' : ''}`}
			>
				{options.map((option, index) => (
					<Option
						className={`typo-strawford typo-strawford-34 ${option.disabled ? 'disabled' : ''}`}
						key={index}
						onClick={() => handleSelect(option.value)}
					>
						{option.label}
					</Option>
				))}
			</Options>
		</DropdownWrapper>
	);
};

export default SingleSelect;
