import React, {useCallback} from "react";
import Modal from "@/components/common/Modal";
import useGlobals from "@/hooks/useGlobals";
import FormSubmissionModal from "@/components/ModalVariants/FormSubmissionModal";

export default function NewsletterModal({ modalOpen, setModalOpen, modalData, loading }) {
	const { footer } = useGlobals()
	const modalCancelButtonText = footer?.newsletter?.modalCancelButtonText || ''

	const handleModalClose = useCallback(() => {
		setModalOpen(false)
	},[])

	return (
		<Modal isOpen={modalOpen} handleClose={handleModalClose} buttonText={modalCancelButtonText}>
			<FormSubmissionModal
				header={modalData?.header || ''}
				handleClose={handleModalClose}
				icon={modalData?.icon || {}}
				closeButtonText={modalCancelButtonText}
			/>
		</Modal>
	)
}