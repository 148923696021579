import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-80, &-80i {
		font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(50, BREAKPOINTS.DESKTOP)};
		}
	}
    &-81, &-81i {
		font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		line-height: normal;
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
			line-height:  ${getFontSize(26.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(26.4, BREAKPOINTS.DESKTOP)};
		}
	}
	&-82, &-82i {
		font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		line-height: normal;
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
			line-height:  ${getFontSize(26.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(15, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(15, BREAKPOINTS.DESKTOP)};
		}
	}
    &-83, &-83i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
			line-height: normal;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
		}
	}
    &-84, &-84i {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height: 150%;
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            line-height: 100%;
            font-weight: ${({theme}) => theme.fontWeight.medium};
			font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
		}
	}
    &-85, &-85i {
		font-size: ${getFontSize(34, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.semiBold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(65, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(84, BREAKPOINTS.DESKTOP)};
		}
	}    
    &-86, &-86i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: normal;
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
	}
    &-87, &-87i {
        font-size: ${getFontSize(25, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(32.5, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(25, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(25, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.DESKTOP)};
        }
	}
    &-88, &-88i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(30.6, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(30.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(30.6, BREAKPOINTS.DESKTOP)};
        }
	}    
    &-89, &-89i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(39, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(54, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
        }
	}
`
