import {useReducer, useEffect} from "react";
import {getMultipleLocalStorageItems, setCssVars} from "@/utils/utils";
import {useScreenReader} from "@/components/AccessibilityTool/useScreenReader";

export const ACTIONS = {
	TOGGLE_EXPAND: 'TOGGLE_EXPAND',
	APPLY_FILTER: 'APPLY_FILTER',
	TOGGLE_LINK_HIGHLIGHT: 'TOGGLE_LINK_HIGHLIGHT',
	TOGGLE_HEADING_HIGHLIGHT: 'TOGGLE_HEADING_HIGHLIGHT',
	UPDATE_RANGE: 'UPDATE_RANGE',
	TOOGLE_SCREEN_READER: 'TOOGLE_SCREEN_READER',
	RESET: 'RESET'
}

const initialState = {
	isExpanded: false,
	activeFilter: null,
	highlightHeadings: false,
	highlightLinks: false,
	screenReader: false,
	rangeValues: {
		"zoom": 100,
		"typoSize": 100,
	}
}

const accessibilityReducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.TOGGLE_EXPAND:
			return {...state, isExpanded: !state.isExpanded};
		case ACTIONS.APPLY_FILTER:
			return {...state, activeFilter: action.payload};
		case ACTIONS.TOGGLE_LINK_HIGHLIGHT:
			return {...state, highlightLinks: action.payload};
		case ACTIONS.TOGGLE_HEADING_HIGHLIGHT:
			return {...state, highlightHeadings: action.payload};
		case ACTIONS.TOOGLE_SCREEN_READER:
			return {...state, screenReader: action.payload};
		case ACTIONS.UPDATE_RANGE:
			return {
				...state,
				rangeValues: {
					...state.rangeValues,
					[action.payload.cssVar]: action.payload.value
				}
			};
		case ACTIONS.RESET:
			return {...initialState, isExpanded: true};
		default:
			return initialState
	}
}

export default function useAccessibilitySettings() {
	const [state, dispatch] = useReducer(accessibilityReducer, initialState, undefined);

	useEffect(() => {
		const {
			activeFilter,
			highlightHeadings,
			highlightLinks,
			screenReader
		} = getMultipleLocalStorageItems(['activeFilter', 'highlightHeadings', 'highlightLinks', 'screenReader'])

		if (activeFilter) dispatch({type: ACTIONS.APPLY_FILTER, payload: activeFilter})
		if (JSON.parse(highlightHeadings || null)) dispatch({type: ACTIONS.TOGGLE_HEADING_HIGHLIGHT, payload: true})
		if (JSON.parse(highlightLinks || null) ) dispatch({type: ACTIONS.TOGGLE_LINK_HIGHLIGHT, payload: true})
		if (JSON.parse(screenReader || null)) dispatch({type: ACTIONS.TOOGLE_SCREEN_READER, payload: true})

		setCssVars(document.documentElement, {
			activeFilter: activeFilter,
			highlightHeadings: JSON.parse(highlightHeadings || null) ? '1' : '0',
			highlightLinks: JSON.parse(highlightLinks || null) ? '1' : '0',
		});

		Object.keys(state.rangeValues).forEach(cssVar => {
			const storedValue = localStorage.getItem(cssVar);
			if (!storedValue) return
			dispatch({
				type: ACTIONS.UPDATE_RANGE,
				payload: {cssVar, value: Number(storedValue)}
			})
			setCssVars(document.documentElement, {[cssVar]: storedValue / 100});
		})
	}, []);

	useScreenReader(state.screenReader)

	const setFilter = (filter) => {
		const finalFilter = filter !== state.activeFilter ? filter : 'none'
		dispatch({type: ACTIONS.APPLY_FILTER, payload: finalFilter})
		localStorage.setItem("activeFilter", finalFilter);
		setCssVars(document.documentElement, {activeFilter: finalFilter});
	}

	const toggleHighlightHeadings = (value) => {
		dispatch({type: ACTIONS.TOGGLE_HEADING_HIGHLIGHT, payload: value})
		localStorage.setItem("highlightHeadings", String(value));
		setCssVars(document.documentElement, {highlightHeadings: value ? '1' : '0'});
	}

	const toggleHighlightLinks = (value) => {
		dispatch({type: ACTIONS.TOGGLE_LINK_HIGHLIGHT, payload: value})
		localStorage.setItem("highlightLinks", String(value));
		setCssVars(document.documentElement, {highlightLinks: value ? '1' : '0'});
	}

	const toggleScreenReader = (value) => {
		dispatch({type: ACTIONS.TOOGLE_SCREEN_READER, payload: value})
		localStorage.setItem("screenReader", String(value));
	}

	const decrementRangeValue = (cssVar, min, step) => {
		const currentValue = state.rangeValues[cssVar] || 0;
		const newValue = Math.min(currentValue + step, min);
		dispatch({ type: ACTIONS.UPDATE_RANGE, payload: { cssVar, value: newValue } });

		setCssVars(document.documentElement, { [cssVar]: newValue / 100})
		localStorage.setItem(cssVar, String(newValue));
	}

	const incrementRangeValue = (cssVar, max, step) => {
		const currentValue = state.rangeValues[cssVar] || 0;
		const newValue = Math.max(currentValue - step, max);
		dispatch({ type: ACTIONS.UPDATE_RANGE, payload: { cssVar, value: newValue } });

		setCssVars(document.documentElement, { [cssVar]: newValue / 100})
		localStorage.setItem(cssVar, String(newValue));
	};

	const reset = () => {
		dispatch({type: ACTIONS.RESET})

		setFilter(null)
		toggleScreenReader(false)
		toggleHighlightHeadings(false)
		toggleHighlightLinks(false)

		Object.entries(state.rangeValues).forEach(([cssVar, value]) => {
			setCssVars(document.documentElement, {[cssVar]: 1})
			localStorage.setItem(cssVar, String(100));
		});
	}

	return {
		state,
		dispatch,
		reset,
		setFilter,
		toggleHighlightHeadings,
		toggleHighlightLinks,
		toggleScreenReader,
		incrementRangeValue,
		decrementRangeValue
	};
}