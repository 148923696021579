import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Menu from "@/components/Menu";
import {useDispatch, useSelector} from "react-redux";
import Left from "@/components/topBar/Left";
import {useScroll, useScrollDirection} from "@/hooks/useScrollContext";
import {setIsTopBarSticky} from "@/state/topBarSlice";
import Right from "@/components/topBar/Right";
import {useIsDesktop} from "@/hooks/useDevice";

const Container = styled.div`
    --layer-1: 1;
    --layer-2: 2;
    --layer-3: 3;
    position: fixed;
    inset: 0;
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    column-gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    z-index: 100002;
    padding-top: 24px;
    pointer-events: none;
    transition: all var(--rgr-duration) var(--ease) 100ms;
    height: fit-content;
    padding-right: var(--paddingX);
    padding-left: var(--paddingX);

    > * {
        pointer-events: auto;
    }

    &.hidden {
        translate: 0 -120%;
        transition-delay: 0ms;
    }


    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(13, BREAKPOINTS.DESKTOP)};

        &.sticky {
            padding-left: ${getCssSizeValue(51)};
        }
    }
`

const STICKY_THRESHOLD_PX = 150

const TopBar = ({menu, isDarkSearch, data = {}}) => {
	const [stickyTopBar, setStickyTopBar] = useState(false);
	const isMenuOpen = useSelector((state) => state.menu.open);
	const onMenuOpenPrevSticky = useRef(stickyTopBar)
	const dispatch = useDispatch()
	const direction = useScrollDirection()
	const isDesktop = useIsDesktop()
	const visibility = useMemo(() => {
		if (isDesktop || isMenuOpen) return true
		return direction !== 'down'
	}, [isDesktop, isMenuOpen, direction])

	const handleScroll = useCallback(({scroll, ...props}) => {
		if (isMenuOpen) return
		if (scroll > STICKY_THRESHOLD_PX) {
			if (!stickyTopBar) setStickyTopBar(true);
		} else {
			if (stickyTopBar) {
				setStickyTopBar(false);
			}
		}
	}, [stickyTopBar, isMenuOpen])

	useScroll(handleScroll)

	useEffect(() => {
		dispatch(setIsTopBarSticky(stickyTopBar))
	}, [stickyTopBar]);

	useEffect(() => {
		if (isMenuOpen) {
			onMenuOpenPrevSticky.current = stickyTopBar
			setStickyTopBar(true)
		} else {
			setStickyTopBar(onMenuOpenPrevSticky.current)
		}

	}, [isMenuOpen]);

	return (
		<Container className={`${visibility ? '' : 'hidden'} ${stickyTopBar ? 'sticky' : ''}`}>
			<Left/>
			<Right isDarkSearch={isDarkSearch}/>
			<Menu items={menu}/>
		</Container>
	);
};

export default TopBar;