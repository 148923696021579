import {getResponsiveValue, precise} from "@/utils/utils";
import {useMemo} from "react";
import {useViewportWidth} from "@/hooks/useViewport";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default function useResponsiveValue(value, breakpoint = BREAKPOINTS.DESKTOP){
	const vWidth = useViewportWidth()

	return useMemo(() => {
		const rv = getResponsiveValue(value, breakpoint)
		return Math.min(precise(rv * vWidth), precise(value))
	}, [value, breakpoint, vWidth])
}
