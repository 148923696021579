import {configureStore} from '@reduxjs/toolkit';
import menu from '@/state/menuSlice';
import cookies from '@/state/cookiesSlice';
import searchBar from '@/state/searchBarSlice';
import topBar from '@/state/topBarSlice';

export default configureStore({
	reducer: {
		menu,
		searchBar,
		topBar,
		cookies
	},
});
