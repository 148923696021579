import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-90, &-90i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(31.2, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
        }
	}   
    &-91, &-91i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(31.2, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(46, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(55.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(55.2, BREAKPOINTS.DESKTOP)};
        }
	}
    &-92, &-92i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(27, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
        }
	}
	&-93, &-93i {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(22.4, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
		}
	}
	&-94, &-94i {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(20.8, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(23.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(23.4, BREAKPOINTS.DESKTOP)};
		}
	}
	&-95, &-95i {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(24, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(34, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
		}
	}
    &-96, &-96i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(25.2, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
	}
	&-97, &-97i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(28.8, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(39.6, BREAKPOINTS.DESKTOP)};
		}
	}
	&-98, &-98i {
		font-size: ${getFontSize(13, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(13, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
		}
	}
	&-99, &-99i {
		font-size: ${getFontSize(13, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(13, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
		}
	}
`
