import styled from "styled-components";
import {copyToClipboard, getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {useCallback} from "react";
import BaseToolTip, {TOOLTIP_POSITION} from "@/components/ToolTip/BaseToolTip";
import useGlobals from "@/hooks/useGlobals";
import useResponsiveValue from "@/hooks/useResponsiveValue";
import useDevice, {useIsDesktop, useIsMobile} from "@/hooks/useDevice";
import {DEVICE_TYPES} from "@/constants";

const StyledCopiedInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    pointer-events: none;

    color: ${({theme}) => theme.colors.richBlack};
    border: 2px solid ${({theme}) => theme.colors.chilliRed};
    background-color: ${({theme}) => theme.colors.white};
    padding: ${getCssSizeValue(10, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
         padding: ${getCssSizeValue(14, BREAKPOINTS.TABLET)} ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        padding: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
    }
`

function CopyToolTipContent() {
	const tooltipText = useGlobals(state => state.tooltipItemCopied)

	return (
		<StyledCopiedInfo className='typo-strawford typo-strawford-98'>{tooltipText}</StyledCopiedInfo>
	)
}

const RESPONSIVE_SIZES_MAP = {
	[DEVICE_TYPES.DESKTOP]: {
		height: 40,
		width: 230,
		breakpoint: BREAKPOINTS.DESKTOP
	},
	[DEVICE_TYPES.TABLET]: {
		height: 30,
		width: 230,
		breakpoint: BREAKPOINTS.TABLET
	},
	[DEVICE_TYPES.MOBILE]: {
		height: -50,
		width: 230,
		breakpoint: BREAKPOINTS.MOBILE
	}
}

export default function CopyToolTip({
	children,
	copyValue,
	wrapperId = 'tooltip-portal-wrapper',
	position = TOOLTIP_POSITION.RIGHT,
	spacing = 8,
	props
}) {
	const handleCopyItem = useCallback(async () => {
		if (!copyValue) return
		const res = await copyToClipboard(copyValue)
		if(res !== 'success') return console.error('Copying failed')
	}, [copyValue]);

	const {isDesktop, isTablet} = useDevice()
	const deviceType = isDesktop ? DEVICE_TYPES.DESKTOP : isTablet ? DEVICE_TYPES.TABLET : DEVICE_TYPES.MOBILE
	const responsiveOptions = RESPONSIVE_SIZES_MAP[deviceType]
	const responsiveSizes = {
		height: useResponsiveValue(responsiveOptions.height, responsiveOptions.breakpoints),
		width: useResponsiveValue(responsiveOptions.width, responsiveOptions.breakpoints)
	}

	return (
		<BaseToolTip
			TooltipContent={<CopyToolTipContent />}
			onClick={handleCopyItem}
			wrapperId={wrapperId}
			position={position}
			toolTipSizes={responsiveSizes}
			spacing={spacing}
			{...props}
		>
			{children}
		</BaseToolTip>
	)
}
