import React, {memo, useEffect, useMemo} from 'react';
import {useIsMobile} from "@/hooks/useDevice";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getCssSizeValue, removeBranchFromAsPath} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import useScrollContext from "@/hooks/useScrollContext";
import dynamic from "next/dynamic";
import {useRouter} from "next/router";
import {setIsMenuOpen} from "@/state/menuSlice";
import useInit from "@/hooks/useInit";


const DesktopMenu = dynamic(import("@/components/Menu/Desktop"), { ssr: false })

const MobileMenu = dynamic(import("@/components/Menu/Mobile"), { ssr: false })

const Wrapper = styled.div`
	display: flex;
	--paddingY: ${getCssSizeValue(88, BREAKPOINTS.MOBILE)}; //should be 70, waiting for top row button update
	background-color: ${({theme}) => theme.colors.white};
    transition: transform var(--rgr-duration) var(--ease);
	transform: translateY(-100%);
	transform-origin: top;
	position: absolute;
	flex-direction: column;
	//inset: 0;
	//overflow: hidden;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100svh;
	padding-top: var(--paddingY);
	z-index: var(--layer-2);
	&.menu-open {
        transition: transform var(--rgr-duration) var(--ease);
		transform: translateY(0);
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --paddingY: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
    }
`
const MenuType = memo(function Menu(props){
	const isMobile = useIsMobile()
	const init = useInit()
	if (!init) return null
	return isMobile ? <MobileMenu {...props} /> : <DesktopMenu {...props}/>
})

const Menu = ({ items=[],  ...props }) => {
	const { asPath } = useRouter();
	const dispatch = useDispatch();
	const isMenuOpened = useSelector((state) => state.menu.open);
	const scroll = useScrollContext()
	const isMobile = useIsMobile()
	//this props skips branch changes and will update only on route change
	const redirect = useMemo(() =>  removeBranchFromAsPath(asPath),[asPath])

	useEffect(() => {
		if(isMenuOpened) {
			dispatch((setIsMenuOpen(false)))
		}
		// },[asPath]) changed to redirect so branch updates won't trigger Menu closing
	},[redirect])

	useEffect(() => {
		if(isMenuOpened){
			scroll.lock()
		}

		return () => {
			if(isMenuOpened){
				scroll.unlock()
			}
		}
	}, [isMenuOpened]);

	return (
		<Wrapper
			data-scroll-ignore-lock
			className={`${isMenuOpened ? 'menu-open' : ''} ${!isMobile ? 'desktop' : 'mobile'} no-margin padding `}
		>
			<MenuType items={items} {...props}/>
		</Wrapper>
	)
}

export default Menu;
