import {createContext, useEffect, useMemo, useState} from 'react';
import {useRouter} from 'next/router';

import useCustomContext from "@/hooks/useCustomContext";
import useGlobals from "@/hooks/useGlobals";
import {GLOBAL_STATE} from "@/constants";

function formatIntoSelectItem(branch){
	const isGlobal = branch?.handle === GLOBAL_STATE
	const handle = branch?.handle || ''
	const name = branch?.name || ''
	return {
		disabled: false,
		id: handle,
		value: handle,
		label: isGlobal ? name : name.toLowerCase(),
	}
}

const BranchContext = createContext({
	selectedOption: GLOBAL_STATE,
	setSelectedOption: () => {},
	options: []
});

export const BranchProvider = ({ children }) => {
	const router = useRouter();
	const branches = useGlobals('states')
	const formattedBranches = useMemo(() => {
		const src = Array.isArray(branches) ? branches : []
		return src
			.map((branch) => formatIntoSelectItem(branch))
			.sort((branch) => branch?.id === GLOBAL_STATE ? -1 : 1)
	},
	[branches])
	const [selectedOption, setSelectedOption] = useState('global');

	useEffect(() => {
		const updateSelectedOption = () => {
			const params = new URLSearchParams(window.location.search);
			const option = params.get('branch');
			if(formattedBranches.map((item) => item.value).includes(option)) {
				setSelectedOption(option);
			}
		};

		updateSelectedOption();
		router.events.on('routeChangeComplete', updateSelectedOption);
		return () => {
			router.events.off('routeChangeComplete', updateSelectedOption);
		};
	}, [router.events]);

	const handleChange = (newOption) => {
		setSelectedOption(newOption);
		const params = new URLSearchParams(window.location.search);
		params.set('branch', newOption);

		const newQuery = router.query

		if (newQuery.page) {
			newQuery.page = 1
		}

		router.replace(
			{
				pathname: router.pathname,
				query: { ...newQuery, branch: newOption },
			},
			undefined,
			{ shallow: true }
		);
	};


	return (
		<BranchContext.Provider value={{ selectedOption, setSelectedOption: handleChange, options: formattedBranches }}>
			{children}
		</BranchContext.Provider>
	);
};
export const useBranch = (selector) => useCustomContext(BranchContext,selector);
