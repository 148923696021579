import React, {forwardRef} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin: 0 auto;
    max-width: 100%;

    &.full {
        margin: 0;
        max-width: 100%;
        width: 100%;
    }

    &.main {
        padding-left: var(--paddingX);
        padding-right: var(--paddingX);
    }

    &.narrow {
        padding-left: calc(var(--inner-paddingX) + var(--paddingX));
        padding-right: calc(var(--inner-paddingX) + var(--paddingX));
    }

    &.h-full {
        height: 100%;
    }
`;

const Container = forwardRef(function Container({id = '', className = '', type = 'main', children, ...props}, ref) {
	return (
		<Wrapper ref={ref} id={id} className={`${className} ${type}`} {...props}>
			{children}
		</Wrapper>
	);
});

export default Container;
