import {createGlobalStyle} from 'styled-components';

import localFont from 'next/font/local';
import Typo0 from "@/styles/Typography/Typo0";
import Typo1 from "@/styles/Typography/Typo1";
import Typo2 from "@/styles/Typography/Typo2";
import Typo3 from "@/styles/Typography/Typo3";
import Typo4 from "@/styles/Typography/Typo4";
import Typo5 from "@/styles/Typography/Typo5";
import Typo6 from "@/styles/Typography/Typo6";
import Typo7 from "@/styles/Typography/Typo7";
import Typo8 from "@/styles/Typography/Typo8";
import Typo9 from "@/styles/Typography/Typo9";
import Typo10 from "@/styles/Typography/Typo10";
import Typo11 from "@/styles/Typography/Typo11";
import Typo12 from "@/styles/Typography/Typo12";
import Typo13 from "@/styles/Typography/Typo13";

export const Borna = localFont({
	src: [
		{
			path: '../../public/fonts/Borna/Borna-Regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-RegularItalic.woff2',
			weight: '400',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-Medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-MediumItalic.woff2',
			weight: '500',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-SemiBold.woff2',
			weight: '600',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-SemiboldItalic.woff2',
			weight: '600',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-Bold.woff2',
			weight: '700',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Borna/Borna-BoldItalic.woff2',
			weight: '700',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
	]
})

export const Strawford = localFont({
	src: [
		{
			path: '../../public/fonts/Strawford/strawford-thin.woff2',
			weight: '100',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-thinitalic.woff2',
			weight: '100',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-extralight.woff2',
			weight: '200',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-extralightitalic.woff2',
			weight: '200',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-light.woff2',
			weight: '300',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-lightitalic.woff2',
			weight: '300',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-regular.woff2',
			weight: '400',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-regularitalic.woff2',
			weight: '400',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-medium.woff2',
			weight: '500',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-mediumitalic.woff2',
			weight: '500',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-bold.woff2',
			weight: '700',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-bolditalic.woff2',
			weight: '700',
			display: 'swap',
			style: 'italic',
			fallback: ['system-ui', 'arial'],
		},
		{
			path: '../../public/fonts/Strawford/strawford-black.woff2',
			weight: '900',
			display: 'swap',
			style: 'normal',
			fallback: ['system-ui', 'arial'],
		},
	]
})

const TYPOS = [
	Typo0,
	Typo1,
	Typo2,
	Typo3,
	Typo4,
	Typo5,
	Typo6,
	Typo7,
	Typo8,
	Typo9,
	Typo10,
	Typo11,
	Typo12,
	Typo13
]
const italicsClassesCssString = TYPOS.reduce((prevOne, currOne, indexOne) => {
	return prevOne + [...Array(10)].reduce((prevTenth, currTenth, indexTenth) => {
		return prevTenth + `&-${indexOne}${indexTenth}i` + ','
	},'')
},``).slice(0,-1)



export const TypographyStyles = createGlobalStyle`
    :root {
        --font-borna: ${Borna.style.fontFamily};
        --font-strawford: ${Strawford.style.fontFamily};
    }

    //* {
    //    font-family: var(--font-borna);
    //}

    a {
        text-decoration: none;
    }
	
    .typo-borna {
		font-family: var(--font-borna);
		${italicsClassesCssString}, em {
			font-style: italic;
    	}
		${TYPOS}
    }
	.typo-strawford {
		 font-family: var(--font-strawford) !important;
        ${italicsClassesCssString}, em {
            font-style: italic;
        }
		${TYPOS}
    }
`
