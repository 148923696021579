import { useState } from 'react';

export const RESPONSE_MESSAGES = {
	EMAIL_ALREADY_REGISTERED: "This email is already in the newsletter.",
	SOMETHING_WENT_WRONG: 'Something went wrong',
	SUCCESS: "Successfully subscribed for newsletter",
}

const useSubscribeToNewsletter = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const subscribe = async (payload) => {
		setLoading(true);
		setError(null);
		setSuccess(null);

		try {
			const response = await fetch('/api/signUpForNewsletter', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			});

			const result = await response.json();

			if (result.status === 200 && result.message === RESPONSE_MESSAGES.EMAIL_ALREADY_REGISTERED) {
				setError(RESPONSE_MESSAGES.EMAIL_ALREADY_REGISTERED)
				setLoading(false)
				setSuccess(false)
				return
			}

			if (result.status === 200 && result.message === RESPONSE_MESSAGES.SUCCESS) {
				setError(null)
				setLoading(false)
				setSuccess(true)
				return
			}

			if (result.status !== 200 ) {
				setError(RESPONSE_MESSAGES.SOMETHING_WENT_WRONG)
				setLoading(false)
				setSuccess(false)
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	};

	return { subscribe, loading, error, success };
};
export default useSubscribeToNewsletter;