import React from 'react';
import LogoImage from "@/assets/images/logo-pck.svg";
import BranchSelect from "@/components/BranchSelect";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import useTranslateElementOnScroll from "@/hooks/useTranslateElementOnScroll";
import {setIsMenuOpen} from "@/state/menuSlice";
import {useRouter} from "next/router";

const Wrapper = styled.div`
	position: absolute;
    display: flex;
    column-gap: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    max-height: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        max-height: ${getCssSizeValue(127)};
    }

    &.sticky {
        max-height: ${getCssSizeValue(54, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            max-height: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
        }
    }
`

const Logo = styled(PreserveSearchParamsLink)`
    width: var(--size);
    height: var(--size);
    aspect-ratio: 1;
    transition: width var(--rgr-duration) var(--ease), height var(--rgr-duration) var(--ease), padding var(--rgr-duration) var(--ease);
    z-index: var(--layer-3);
    --size: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};

    .image {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-right: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(127, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(31, BREAKPOINTS.DESKTOP)};;
    }

    &.sticky {
        --size: ${getCssSizeValue(54, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --size: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
        }
    }
`;

const StyledBranchSelect = styled(BranchSelect)`
    z-index: var(--layer-3);
    pointer-events: auto;

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        z-index: var(--layer-1);
    }
`

const Left = () => {
	const router = useRouter()
	const dispatch = useDispatch();
	const isMenuOpen = useSelector((state) => state.menu.open);
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	const isTopBarSticky = useSelector((state) => state.topBar.sticky);
	const { translateElementRef, translateElementContainerRef } = useTranslateElementOnScroll()
	const stickyClassName = isTopBarSticky ? 'sticky' : ''

	const handleLogoClick = () => {
		if (isMenuOpen && router.pathname === '/') {
			dispatch(setIsMenuOpen(false))
		}
	}

	return (
		<Wrapper ref={translateElementContainerRef} className={stickyClassName}>
			<Logo href={`/`} onClick={handleLogoClick} className={stickyClassName}>
				<LogoImage/>
			</Logo>
			<div ref={translateElementRef}>
				{!isSearchBarVisible && <StyledBranchSelect className='border' closeOnScroll="down" />}
			</div>
		</Wrapper>
	);
};

export default Left;
