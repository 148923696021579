import React, {forwardRef} from 'react';
import styled from 'styled-components';
import useColor from "../../hooks/useColor";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

const DEFAULT_COLOR = 'richBlack'

const Paragraph = styled.p`
	// --color: ${({ theme, $color }) => $color || theme.colors[DEFAULT_COLOR]};
	--color: ${({ theme, $color }) => $color || 'currentColor'};
    font-family: var(--font-borna);
	display: block;
	position: relative;
	color: var(--color);
	width: fit-content;
    height: fit-content;
    transition: translate var(--rgr-duration) var(--ease), color var(--rgr-duration) var(--ease);
    //justify-content: center;
    //align-items: center;
	&.italic {
		font-style: italic;
	}

	&.uppercase{
		text-transform: uppercase;
	}
	
	mark {
		background-color: ${({theme}) => theme.colors.periwinkle};
	}
	
	span[style= "text-decoration: line-through;"] {
		display: block;
		text-decoration: none!important;
		background-color: ${({theme}) => theme.colors.periwinkle};
	}
	
    a[href] {
		color: ${({theme}) => theme.colors.chilliRed};
		text-decoration: underline;
		font-weight: ${({theme}) => theme.fontWeight.bold}; !important;
		user-select: all !important;
		pointer-events: all;
    }

    &.square {
        --square-scale: 0;
        --square-size: ${getFontSize(8,BREAKPOINTS.MOBILE)};
        --shift-x: calc(var(--square-size) * var(--square-scale) * 1.75);
        display: flex;
        translate: calc(var(--shift-x)) 0;
		
        &:before{
            content: '';
			top: 45%;
            translate: calc(-1 * var(--shift-x, 0)) 0;
            transform-origin: left center;
            //transform: translateY(-20%) scale(var(--square-scale));
            background-color: ${({ theme }) => theme.colors.chilliRed };
            transform: translateY(-50%) scale(var(--square-scale));
            transition: all var(--rgr-duration) var(--ease);
            position: absolute;
            width: var(--square-size);
            height: var(--square-size);
            display: block;
			pointer-events: none;
        }

        &:not(&.disabled), &:not(&.static) {
            &:hover{
                --square-scale: 1;
            }
        }
		

        &.static{
            --square-scale: 1;
            translate: 0;
            column-gap: var(--square-size);
			align-items: center;
            &:before{
				top: 0;
                position: relative;
                transform: translateY(-10%)  scale(var(--square-scale));
                background-color: ${({ theme }) => theme.colors.black };
                translate: 0;
            }
        }
    }
	
	&.card-header {
		margin-bottom: ${getCssSizeValue(4, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin-bottom: ${getCssSizeValue(4, BREAKPOINTS.DESKTOP)};
		}
	}
	
	&.asset {
		color: ${({theme}) => theme.colors.chilliRed} !important;
        text-decoration: underline;
        font-weight: ${({theme}) => theme.fontWeight.bold}; !important;

        ::marker {
            color: ${({theme}) => theme.colors.richBlack};
        }
	}
	
	ul, ol {
        --margin: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
        --size: ${getCssSizeValue(6, BREAKPOINTS.MOBILE)};
        --top: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};
		
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            --margin: ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
            --size: ${getCssSizeValue(8, BREAKPOINTS.TABLET)};
            --top: ${getCssSizeValue(12, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --margin: ${getCssSizeValue(22)};
            --size: ${getCssSizeValue(8)};
        	--top: ${getCssSizeValue(13)};
		}
	}
	
	ul {
		li {
			list-style: none;
			margin: var(--margin);
			position: relative;
			&::before {
				content: '';
				position: absolute;
                background: ${({theme}) => `var(--color, ${theme.colors.richBlack})`};
				top: var(--top);
				left: calc(var(--margin) * -1);
				min-height: var(--size);
				min-width: var(--size);
			}
		}
	}
	
	ol {
        margin: var(--margin);
		padding: 0;
		li {
			padding-left: var(--size);
		}
	}
	

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        span[style= "text-decoration: line-through;"] {
			display: inline;
        }
    }
   
`;

const Text = forwardRef(function Text({ className='', children, color=undefined, tag='p', ...props }, ref) {
	const cl = useColor(color)
	return (
		<Paragraph as={tag} ref={ref} className={`${className} text`} $color={cl} {...props}>
			{children}
		</Paragraph>
	);
});

export default Text;
