import styled from "styled-components";
import useGlobals from "@/hooks/useGlobals";
import {getCssSizeValue, getHtmlFromRedactorField} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import React, {useRef, useState} from "react";
import Icon from "@/components/common/Icon/Icon";
import {ICONS} from "@/constants";
import {motion} from 'framer-motion'
import {useIsDesktop, useIsMobile} from "@/hooks/useDevice";
import CopyToolTip from "@/components/ToolTip/Variants/CopyToolTip";
import {TOOLTIP_POSITION} from "@/components/ToolTip/BaseToolTip";

const StyledWrapper = styled.div`
	position: relative;
    padding-top: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
        padding-bottom: ${getCssSizeValue(80, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {  
		padding-top: ${getCssSizeValue(58)};
    }
	
	&.payment {
		padding-top: 0;
		padding-bottom: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
		@media(min-width: 1920px) {
			padding-bottom: 0;
		}
	}
`;

const StyledTitleAndCopyModalWrapper = styled.div`
    position: relative;

	div.instructions-text {
		font-weight: ${({theme}) => theme.fontWeight.medium}!important;
		max-width: 100%;
		margin-top: ${getCssSizeValue(16)};
	}
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
    
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        div.instructions-text {
            max-width: ${getCssSizeValue(450, BREAKPOINTS.DESKTOP)};
        }
	}
	
	
	.title {
		text-transform: uppercase;
	}
`

const StyledCategoryTitle = styled.div`
    margin-top: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(35)};
		margin-bottom: ${getCssSizeValue(8)};
    }
`;

const StyledItem = styled.div`
	cursor: pointer;
	position: relative;
    border-top: 1px solid ${({theme}) => theme.colors.richBlack};
    padding: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)} 0;
	&.payment-block{
		border-top: 1px solid ${({theme}) => theme.colors.white};
		&:first-child {
			border: none;
		}
		&:last-child {
			border-bottom: 1px solid ${({theme}) => theme.colors.white};
		}
	}
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding: ${getCssSizeValue(11, BREAKPOINTS.TABLET)} 0;
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		padding: ${getCssSizeValue(11)} 0;
    }
	
    &:last-child {
        border-bottom: 1px solid ${({theme}) => theme.colors.richBlack};
    }
	
	.value {
		color: ${({theme}) => theme.colors.chilliRed};
	}

    .indicator-wrapper {
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			width: ${getCssSizeValue(38)};
			position: absolute;
			top: 50%;
			right: 98%;
			transform: translateY(-50%);
			display: flex;
			align-items: center;
					&.payment {
						z-index: 1;
						.icon {
							path, circle, polygon, rect {
								fill: white;
							}
						}
					}
			.icon {
				width: ${getCssSizeValue(20)};
				height: ${getCssSizeValue(20)};
			}
        }
	}
`;

const fadeIn = {
	hidden: { opacity: 0 },
	show: { opacity: 1, transition: { duration: 0.3 } },
};

function TransferItem({ item, isPaymentBlockVariant }) {
	const title = item?.title || '';
	const value = item?.value || '';
	const isDesktop = useIsDesktop()
	const isMobile = useIsMobile()

	const [isHovered, setIsHovered] = useState(false)
	const container = useRef();
	return (
		<StyledItem
			ref={container}
			className={isPaymentBlockVariant ? 'payment-block typo-strawford typo-strawford-50' : 'typo-strawford typo-strawford-50'}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<CopyToolTip
				copyValue={value}
				wrapperId={item?.id || ''}
				position={isMobile ? TOOLTIP_POSITION.TOP : TOOLTIP_POSITION.RIGHT_END}
			>
				{(isHovered && isDesktop) ? (
					<motion.div
						variants={fadeIn}
						initial="hidden"
						animate="show"
						className={isPaymentBlockVariant ? 'indicator-wrapper payment' :'indicator-wrapper'}
					>
						<Icon name={ICONS.COPY} className='indicator'/>
					</motion.div>
				) : null}
				{`${title}`}<span className='value'> {value}
				</span>
			</CopyToolTip>
		</StyledItem>
	);
}

function TransferCategory({ transferCategory, isPaymentBlockVariant }) {
	const title = transferCategory?.title || '';
	const items = transferCategory?.items || [];

	return (
		<div>
			{(!isPaymentBlockVariant || (isPaymentBlockVariant && title) ) && <StyledCategoryTitle className='category-title typo-strawford typo-strawford-52'>{title}</StyledCategoryTitle>}
			{items.map((item, index) => (
				<TransferItem
					key={item?.id || index}
					item={item}
					isPaymentBlockVariant={isPaymentBlockVariant}
				/>
			))}
		</div>
	);
}

export default function Transfers({ isPaymentBlockVariant }) {
	const { footer, paymentsTransferData } = useGlobals();
	const title = footer?.transfers?.title || '';
	const copiedText = footer?.transfers?.itemCopiedText || '';
	const transfersCategoriesForFooter = footer?.transfers?.transferCategories || [];
	const transfersCategoriesForPayment = paymentsTransferData?.transfers?.transferCategories || [];
	const finalTransfers = isPaymentBlockVariant ? transfersCategoriesForPayment : transfersCategoriesForFooter;
	const instructionsText = paymentsTransferData?.instructionsText || '';

	return (
		<StyledWrapper className={isPaymentBlockVariant ? "payment col-mobile-8 col-desktop-4" : "col-desktop-4 col-tablet-4 col-mobile-8"}>
			<StyledTitleAndCopyModalWrapper>
				{!isPaymentBlockVariant && <div className='typo-strawford typo-strawford-49 title'>{title}</div>}
				{instructionsText && <div className='typo-strawford typo-strawford-29 instructions-text'
					 dangerouslySetInnerHTML={getHtmlFromRedactorField(instructionsText)}/>}
			</StyledTitleAndCopyModalWrapper>
			{finalTransfers.map((transferCategory, index) => (
				<TransferCategory
					key={transferCategory?.id || index}
					transferCategory={transferCategory}
					isPaymentBlockVariant={isPaymentBlockVariant}
				/>
			))}
		</StyledWrapper>
	);
}
