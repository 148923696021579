import styled, {keyframes} from 'styled-components';
import Button from '@/components/common/Buttons/Button';
import React, {useEffect, useState} from 'react';
import JsCookies from 'js-cookie';
import Paragraph from "@/components/Cookies/Paragraph";
import SingleCookieItem from "@/components/Cookies/SingleCookieItem";
import {BREAKPOINTS, theme} from "@/styles/themeSettings";
import {getCssSizeValue, getHtmlFromRedactorField, isUndefined, partition} from "@/utils/utils";
import Image from "@/components/common/Image";
import Icon from "@/components/common/Icon/Icon";
import {BUTTON_VARIANTS, ICONS} from "@/constants";
import {useIsMobile} from "@/hooks/useDevice";
import {useDispatch, useSelector} from "react-redux";
import {setHasConsent as setHasConsentRedux} from "@/state/cookiesSlice";
import useGlobals from "@/hooks/useGlobals";
import {hotjar} from 'react-hotjar'

const FadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    } 
`;

const StyledCollapsedWrapper = styled.div`
    position: fixed;
    left: var(--paddingX);
    right: var(--paddingX);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    background: ${({theme}) => theme.colors.richBlack};
    color: ${({theme}) => theme.colors.white};
    animation: ${FadeIn} 0.35s ease-in-out;

    bottom: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
    padding: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)} ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        bottom: ${getCssSizeValue(25)};
        width: ${getCssSizeValue(652)};
        padding: ${getCssSizeValue(40)} ${getCssSizeValue(100)} ${getCssSizeValue(20)} ${getCssSizeValue(24)};
    }
`;

const StyledModalIcon = styled(Image)`
    position: absolute;
    z-index: 2;
    height: var(--size);
    width: var(--size);

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(90)};
        right: ${getCssSizeValue(8)};
        top: ${getCssSizeValue(8)};
    }

    &.expanded {
        right: 0;
        top: -12%;
    }
`

const StyledHeader = styled.div`
    color: ${({theme}) => theme.colors.periwinkle};
    margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(32)};
    }
`;

const StyledParagraphWrapper = styled.div`
    margin-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(64)};
    }
`;

const StyledButtonsWrapper = styled.div`
    display: flex;
    gap: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(16)};
    }
`;

const StyledExpandedWrapper = styled.div`
    animation: ${FadeIn} 0.35s ease-in-out;
    color: ${theme.colors.white};
    z-index: 1000;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    bottom: 15px;
    left: var(--paddingX);
    right: var(--paddingX);
    bottom: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};

    &.config {
        height: 80dvh;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        bottom: unset;
        right: unset;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: ${getCssSizeValue(878)};
        height: ${getCssSizeValue(440)};

        transition: height var(--rgr-duration) var(--ease);
        &.config {
            height: ${getCssSizeValue(690)};
        }
    }
`;

const StyledExpandedWrapperContent = styled.div`
    position: relative;
    background: ${({theme}) => theme.colors.richBlack};
    display: flex;
    flex-direction: column;
    height: 95%;
    justify-content: space-between;
    row-gap: ${getCssSizeValue(44)};
    width: 100%;

    padding: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)} ${getCssSizeValue(34, BREAKPOINTS.MOBILE)} ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        padding: ${getCssSizeValue(80)} ${getCssSizeValue(110)} ${getCssSizeValue(32)} ${getCssSizeValue(40)};
    }
`;

const StyledTabsHeaderWrapper = styled.div`
    display: flex;
`;

const StyledSingleTab = styled.div`
    cursor: pointer;
    text-align: center;
    background: ${({theme}) => theme.colors.antiflashWhite};
    color: ${({theme}) => theme.colors.richBlack};
    padding: ${getCssSizeValue(13, BREAKPOINTS.MOBILE)} 0;
    flex: 1;

    &.active {
        background: ${({theme}) => theme.colors.richBlack};
        color: ${({theme}) => theme.colors.antiflashWhite};
        font-weight: ${({theme}) => theme.fontWeight.bold};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        flex: unset;
        padding: ${getCssSizeValue(20)} 0;
        width: ${getCssSizeValue(300)};
    }
`

const StyledBottomButtonsWrapper = styled.div`
    display: flex;
    gap: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
    padding-top: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        flex-direction: row;
        padding-top: unset;
        gap: ${getCssSizeValue(16)};
    }
`;

const StyledContent = styled.div`
    animation: ${FadeIn} 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    overflow: auto;
    position: relative;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        display: none;
    }
`;

const CloseBtn = styled.div`
    --size: ${getCssSizeValue(42, BREAKPOINTS.MOBILE)};
    --svg-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
    min-width: var(--size);
    min-height: var(--size);
    background: ${({theme}) => theme.colors.chilliRed};

    cursor: pointer;
    display: grid;
    place-items: center;

    svg {
        width: var(--svg-size);
        height: var(--svg-size);

        path, circle {
            fill: #fff;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(40)};
        --svg-size: ${getCssSizeValue(16)};
    }
`

const expires = 100;

function gtag() {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(arguments);
}

function setGTMConsent(consent) {
	gtag('consent', 'update', consent);
}

function setGTMState(state=false) {
	return Boolean(state) ? 'granted' : 'denied';
}

const HAS_CONSENT_KEY = 'hasConsent'
const COOKIES_KEY = 'cookies'
const GTM_KEY = 'gtm'

export const Cookies = ({}) => {
	const cookies = useGlobals('cookies')
	const dispatch = useDispatch()
	const hasConsent = useSelector(state => state.cookies.hasConsent)
	function setHasConsent(value) {
		dispatch(setHasConsentRedux(value))
	}

	const [isCookiesModalExpanded, setIsCookiesModalExpanded] = useState(false);
	const [config, setConfig] = useState(false);
	const isMobile = useIsMobile()

	const header = cookies?.header || '';
	const description = cookies?.description || '';
	const acceptAllButtonText = cookies?.acceptAllButton || '';
	const configButtonText = cookies?.configButton || '';
	const saveChosenButtonText = cookies?.saveChosenButton || ''
	const iconUrl = cookies?.cookiesIcon?.url || ''
	const iconAlt = cookies?.cookiesIcon?.alt || ''

	const generalButtonText = cookies?.generalButton || '';
	const generalDescription = cookies?.generalDescription || '';
	const backButtonText = cookies?.backButton || '';

	const additionalCookies = cookies?.additionalCookies || [];
	const [[nec], rest] = partition(additionalCookies, (cookie) => cookie.cookieType === 'necessary')
	const necessaryCookie = nec || {}
	const restCookies = rest || []
	const [options, setOptions] = useState(
		additionalCookies.reduce((acc, cookie) => ({[cookie.cookieType]: false, ...acc}), { necessary: true }),
	);

	const setCookie = (name, value) => setOptions((prevState) => ({
		...prevState,
		[name]: value,
	}))

	const handleCookies = (cookies, all=false) => {
		let updated = { ...cookies }
		const entries = Object.entries(updated)
		if(entries?.length <= 0) return
		entries.forEach(([cookie, value]) => {
			updated[cookie] = all ? true : value
		})
		setOptions(updated)
		return updated
	}



	const handleAccept = (all = false) => {
		const updated = handleCookies(options, all)
		const gtm_consent = {
			ad_storage: setGTMState(Boolean(updated?.marketing)),
			analytics_storage: setGTMState(Boolean(updated?.analytics)),
			ad_user_data: setGTMState(Boolean(updated?.marketing)),
			ad_personalization: setGTMState(Boolean(updated?.marketing)),
			functionality_storage: setGTMState(false),
		};
		JsCookies.set(COOKIES_KEY, JSON.stringify(updated), {expires});
		JsCookies.set(GTM_KEY, JSON.stringify(gtm_consent), {expires});
		setGTMConsent(gtm_consent)
		JsCookies.set(HAS_CONSENT_KEY, true, {expires});
		if (updated.analytics) {
			// console.log('hotjar init');
			hotjar.initialize({
				id: process.env.HJ_ID,
				sv: process.env.HJ_SV
			})
		}
		setHasConsent(true)
	};

	useEffect(() => {
		const cookieConsent = JsCookies.get(HAS_CONSENT_KEY) || false;
		const prev = JsCookies.get(COOKIES_KEY)
		const formattedPrev = isUndefined(prev) ? {} : JSON.parse(prev);
		// console.log(formattedPrev);
		handleCookies(formattedPrev)
		setHasConsent(cookieConsent);
	}, []);

	// useEffect(() => {
	// 	// console.log(options);
	// },[options])


	useEffect(() => {
		const handleClick = () => {
			setHasConsent(false)
		};

		const attachEventListeners = () => {
			const elements = document.querySelectorAll('[data-action="openCookiesModal"]');
			elements.forEach((element) => {
				if (!element.hasEventListener) {
					element.addEventListener("click", handleClick);
					element.hasEventListener = true;
				}
			});
		};

		attachEventListeners();

		const observer = new MutationObserver(() => {
			attachEventListeners();
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});

		return () => {
			const elements = document.querySelectorAll('[data-action="openCookiesModal"]');
			elements.forEach((element) => {
				element.removeEventListener("click", handleClick);
				delete element.hasEventListener;
			});
			observer.disconnect();
		};
	}, []);

	return (
		<>
			{!hasConsent ? (
				<>
					{!isCookiesModalExpanded ? (
						<StyledCollapsedWrapper>
							<StyledModalIcon
								src={iconUrl}
								alt={iconAlt}
								sizes={'100vw'}
							/>
							<StyledHeader className="typo-borna typo-borna-71">{header}</StyledHeader>
							<StyledParagraphWrapper>
								<div className='typo-strawford typo-strawford-72'
									 dangerouslySetInnerHTML={getHtmlFromRedactorField(description)}/>
							</StyledParagraphWrapper>
							<StyledButtonsWrapper className="">
								<Button
									variant={BUTTON_VARIANTS.TERTIARY}
									onClick={() => setIsCookiesModalExpanded(true)}
								>
									{configButtonText}
								</Button>
								<Button onClick={() => handleAccept(true)} variant={BUTTON_VARIANTS.TERTIARY}>
									{acceptAllButtonText}
								</Button>
							</StyledButtonsWrapper>
						</StyledCollapsedWrapper>
					) : (
						<StyledExpandedWrapper className={`${config ? 'config' : ''}`}>
							<StyledTabsHeaderWrapper className='typo-strawford'>
								<StyledSingleTab
									className={`${config ? '' : 'active'}`}
									onClick={() => setConfig(false)}
								>
									{generalButtonText || ''}
								</StyledSingleTab>
								<StyledSingleTab className={`${config ? 'active' : ''}`}
									onClick={() => setConfig(true)}>
									{configButtonText || ''}
								</StyledSingleTab>
								{isMobile && (
									<CloseBtn
										onClick={() => setIsCookiesModalExpanded(false)}
									>
										<Icon name={ICONS.CLOSE}/>
									</CloseBtn>
								)}
							</StyledTabsHeaderWrapper>
							<StyledExpandedWrapperContent>
								<StyledModalIcon
									className='expanded'
									src={iconUrl}
									alt={iconAlt}
									sizes={'100vw'}
								/>
								<StyledContent>
									{!config ? (
										<Paragraph text={generalDescription}/>
									) : (
										<>
											<SingleCookieItem
												title={necessaryCookie?.name || ''}
												description={necessaryCookie?.description || ''}
												id="essential"
												isDefault
											/>

											{restCookies.map((cookie) => (
												<SingleCookieItem
													key={cookie.id}
													id={cookie.cookieType}
													title={cookie.name || ''}
													description={cookie.description || ''}
													options={options}
													handleSwitch={setCookie}
												/>
											))}
										</>
									)}
								</StyledContent>
								<StyledBottomButtonsWrapper>
									<Button
										variant={BUTTON_VARIANTS.TERTIARY}
										onClick={() => setIsCookiesModalExpanded(false)}
									>
										{backButtonText}
									</Button>
									{!config ? null : (
										<Button onClick={() => handleAccept()} >
											{saveChosenButtonText}
										</Button>
									)}
									<Button onClick={() => handleAccept(true)} variant={BUTTON_VARIANTS.TERTIARY}>
										{acceptAllButtonText}
									</Button>
								</StyledBottomButtonsWrapper>
							</StyledExpandedWrapperContent>
						</StyledExpandedWrapper>
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
};

export default Cookies;
