import {useRef} from "react";
import {useScrollDirection} from "@/hooks/useScrollContext";
import {useGSAP} from "@gsap/react";
import {gsap} from "@/utils/gsap";
import useResponsiveValue from "@/hooks/useResponsiveValue";
import useRecap from "@/components/Recap/RecapProvider";
import {useRouter} from "next/router";

export default function useTranslateElementOnScroll(disabled = false, shouldForceHide = false, forceDisabled = false) {
	const router = useRouter()
	const translateElementContainerRef = useRef(null);
	const translateElementRef = useRef(null);

	const recapData = useRecap();
	const scrollDirection = useScrollDirection();

	const translateValue = useResponsiveValue(100);
	const hideValue = `-${translateValue}px`;

	const getTranslationValue = () => {
		if (shouldForceHide) return hideValue;
		if (disabled) return '0px';
		return scrollDirection === 'up' ? '0px' : hideValue;
	};

	useGSAP(() => {
		if (!translateElementRef.current || !router.isReady) return

		gsap.set(translateElementRef.current, {
			y: 0,
			overwrite: true
		})
	}, {
		scope: translateElementContainerRef.current,
		dependencies: [router.asPath, router.isReady, translateElementRef.current]
	})

	useGSAP(() => {
		if (forceDisabled) return
		if (!translateElementRef.current) return;

		const translationValue = getTranslationValue();

		const animationParams = {
			y: translationValue,
			duration: 0.25,
			ease: 'power1.out'
		};

		gsap.to(translateElementRef.current, animationParams);

	}, {
		scope: translateElementContainerRef.current,
		dependencies: [
			scrollDirection,
			translateElementRef.current,
			translateElementContainerRef.current,
			disabled,
			shouldForceHide,
			recapData,
			forceDisabled
		],
	});

	return { translateElementRef, translateElementContainerRef };
}
