import Icon from "@/components/common/Icon/Icon";
import styled from "styled-components";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import {ICONS} from "@/constants";
import useColor from "@/hooks/useColor";
import {getCssSizeValue, handleSetPositionOnMouseMove, isFunction} from "@/utils/utils";
import useHover from "@/hooks/useHover";
import {useEffect} from "react";
import useExpandBackgroundAnimation from "@/hooks/useExpandBackgroundAnimation";

export const SIMPLE_ICON_BUTTON_VARIANTS = {
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
	BIG: 'big'
}

export const SIMPLE_ICON_BUTTON_ANIMATIONS = {
	ROTATE_45: 'rotate-45'
}

const Wrapper = styled.div`
	position: relative;
	overflow: hidden;
    display: grid;
    place-items: center;

    --color: ${({$color}) => $color || colors.white};
    --border-color: ${({$borderColor}) => $borderColor || 'var(--color)'};
    --bgColor: ${({$bgColor}) => $bgColor || 'inherit'};
    --size: ${getCssSizeValue(30)};
    --icon-size: ${getCssSizeValue(14)};

    border: 1px solid var(--border-color);
    background: var(--bgColor);
    width: var(--size);
    height: var(--size);
    transition: all .3s;

    &.pointer {
        cursor: pointer;
    }

    .icon {
        transition: transform var(--rgr-duration) var(--ease);;
        width: unset;
        height: unset;
        transform: ${({$rotation}) => `rotate(${$rotation}deg)`};

        svg {
            width: var(--icon-size);
            height: var(--icon-size);;
        }
    }

    &.variant-${SIMPLE_ICON_BUTTON_VARIANTS.SMALL} {
        --size: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        --icon-size: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};
        
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            --size: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
            --icon-size: ${getCssSizeValue(14, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --size: ${getCssSizeValue(30)};
            --icon-size: ${getCssSizeValue(14)};
        }
    }

    &.variant-${SIMPLE_ICON_BUTTON_VARIANTS.MEDIUM} {
        --size: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        --icon-size: ${getCssSizeValue(14, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            --size: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
            --icon-size: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --size: ${getCssSizeValue(40)};
            --icon-size: ${getCssSizeValue(24)};
        }
    }
	
	&.variant-${SIMPLE_ICON_BUTTON_VARIANTS.LARGE} {
        --size: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        --icon-size: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            --size: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
            --icon-size: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --size: ${getCssSizeValue(60)};
            --icon-size: ${getCssSizeValue(24)};
        }
    }

    &.variant-${SIMPLE_ICON_BUTTON_VARIANTS.BIG} {
        --size: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
        --icon-size: ${getCssSizeValue(18, BREAKPOINTS.MOBILE)};
  
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            --size: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
            --icon-size: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            --size: ${getCssSizeValue(80)};
            --icon-size: ${getCssSizeValue(30)};
        }
    }
	
    &.animation-${SIMPLE_ICON_BUTTON_ANIMATIONS.ROTATE_45} {
        svg {
            transform: rotate(45deg);
            transform-origin: center;
        }
    }
	
	&.wide-border {
		border-width: 2px
	}
`

const BackgroundColor = styled.div`
    position: absolute;
    top: var(--y, 50%);
    left: var(--x, 50%);
    background: ${({$bgColor}) => $bgColor || 'inherit'};
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    width: var(--width);
    height: var(--height);
`

const DEFAULT_FULL_COLOR_ANIMATION_PROPS = {
	enabled: false,
	color: '',
	bgColor: '',
	borderColor: ''
}

export const SIMPLE_ICON_BUTTON_CLASS_NAME = 'simple-icon-button'

export default function SimpleIconButton({
	hoverAnimation = true,
	color = colors.white,
	bgColor = '',
	borderColor = '',
	name = ICONS.ARROW_LEFT,
	variant = SIMPLE_ICON_BUTTON_VARIANTS.MEDIUM,
	onClick = () => {},
	rotation = 0,
	className = '',
	animation = null,
	pointer = false,
	wideBorder = false,
	fillColorAnimationParams = DEFAULT_FULL_COLOR_ANIMATION_PROPS,
	children,
	...props
}) {
	const [hoverRef, hovering] = useHover()
	const hoverTl = useExpandBackgroundAnimation(hoverRef)

	const isFillAnimationEnabled = fillColorAnimationParams.enabled
	const $color = useColor(color)
	const $bgColor = useColor(bgColor)
	const $borderColor = useColor(borderColor)

	const $fillColor = useColor(fillColorAnimationParams.color)
	const $fillBgColor = useColor(fillColorAnimationParams.bgColor)
	const $fillBorderColor = useColor(fillColorAnimationParams.borderColor)

	const finalColor = (hovering && isFillAnimationEnabled) ? $fillColor : $color;
	const finalBorderColor = (hovering && isFillAnimationEnabled) ? $fillBorderColor : $borderColor

	const handleClick = () => {
		if (!isFunction(onClick)) {
			return
		}

		onClick()
	}

	useEffect(() => {
		hovering ? hoverTl.current.play() : hoverTl.current.reverse()
	}, [hovering]);

	return (
		<Wrapper
			ref={hoverRef}
			$color={finalColor}
			$bgColor={$bgColor}
			$borderColor={finalBorderColor}
			className={`${SIMPLE_ICON_BUTTON_CLASS_NAME} variant-${variant} ${(hovering && hoverAnimation) ? 'hover' : ''} animation-${animation} ${className} ${pointer ? 'pointer' : ''} ${wideBorder ? 'wide-border' : ''}`}
			onClick={handleClick}
			$rotation={rotation}
			onMouseMove={handleSetPositionOnMouseMove}
			{...props}
		>
			{fillColorAnimationParams.enabled && (
				<BackgroundColor $bgColor={$fillBgColor} />
			)}
			{children}
			<Icon color={finalColor} name={name}/>
		</Wrapper>
	)
}