import React, {createContext, useEffect, useState} from 'react';
import useCustomContext from "@/hooks/useCustomContext";

//TODO: consider whether initial width/height should be 0 or undefined
//logically undefined is more correct
//but we almost always are doing the type check
//and in case of it being undefined
//fill it out with 0
const INIT_CONTEXT = {
	width: 0,
	height: 0,
}

const ViewportContext = createContext(INIT_CONTEXT);

export const ViewportProvider = ({ children }) => {
	const [viewportSize, setViewportSize] = useState({ width: INIT_CONTEXT.width, height: INIT_CONTEXT.height});

	useEffect(() => {
		const handleResize = () => {
			setViewportSize({
				width: window.innerWidth,
				height: window.innerHeight
			});
		};

		// Check initially and on resize/orientation change
		handleResize()
		window.addEventListener('resize', handleResize);

		// Cleanup listeners on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};

	}, []);

	return (
		<ViewportContext.Provider value={viewportSize}>
			{children}
		</ViewportContext.Provider>
	)
}

export default function useViewport(selector) {
	return useCustomContext(ViewportContext, selector)
}


export function useViewportHeight(){
	return useViewport('height')
}
export function useViewportWidth(){
	return useViewport('width')
}
