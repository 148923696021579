import React, {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import styled, {css} from 'styled-components';
import {getCssSizeValue, isFunction} from '@/utils/utils';
import {BREAKPOINTS} from "@/styles/themeSettings";
import UniversalLink from "@/components/common/Links/UniversalLink";
import {getLinkData} from "@/components/common/Links/utils";
import {BUTTON_TYPE, BUTTON_VARIANTS} from "@/constants";
import {duration, gsap, useGSAP} from '@/utils/gsap'


export function extractButtonParams(props){
	const { variant, type, font, typo, ...rest } = props

	return rest
}
export function extractButtonParamsFromClassName(str) {
	const parts = str.split(' ');

	const result = {
		variant: undefined,
		type: undefined,
		font: undefined,
		typo: undefined,
	};

	parts.forEach(part => {
		if (part.startsWith('variant-')) {
			result.variant = part.replace('variant-', '');
		} else if (part.startsWith('type-')) {
			result.type = part.replace('type-', '');
		} else if (part.startsWith('typo-')) {
			const typoParts = part.replace('typo-', '').split('-');
			result.font = typoParts[0];

			// Assuming that the typo number is always present
			result.typo = Number(typoParts[1]);
		}
	});

	return result;
}

export function getButtonLinkData(data){
	return getLinkData(data?.link)
}

export const styles = css`
	min-width: max-content;
	--spacing-x: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
	--spacing-y: ${getCssSizeValue(13, BREAKPOINTS.MOBILE)};
    max-height: ${getCssSizeValue(42, BREAKPOINTS.MOBILE)};
	display: flex;
	align-items: center;
	column-gap: 14px;
	transition: all var(--color-transition-duration) var(--ease);
	text-decoration: none;
	position: relative;
	cursor: pointer;
	width: fit-content;
	justify-content: center;
	white-space: nowrap;
	&.back-to-top {
		border: 1px solid ${({theme}) => theme.colors.richBlack}!important;
		height: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}!important;
		width: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}!important;
		max-height: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}!important;
		max-width: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)}!important;
		min-width: unset!important;
		padding: 0!important;
		border-radius: 0!important;
		--supp-color: #F3F3F3!important;
		.icon {
			width: 14px;
		}
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			height: ${getCssSizeValue(40, BREAKPOINTS.TABLET)}!important;
			width: ${getCssSizeValue(40, BREAKPOINTS.TABLET)}!important;			
			max-height: ${getCssSizeValue(40, BREAKPOINTS.TABLET)}!important;
			max-width: ${getCssSizeValue(40, BREAKPOINTS.TABLET)}!important;
			.icon {
				width: 18px;
			}
		}

		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			height: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)}!important;
			width: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)}!important;			
			max-height: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)}!important;
			max-width: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)}!important;
			.icon {
				width: 24px;
			}
		}
	}
	&:not(&.no-hover):not(&:disabled)  {
		&::before {
			content: "";
			position: absolute;
			will-change: transform;
			top: var(--circle-y, 50%);
			left: var(--circle-x, 50%);
			width: 30%;
			//width: 220%;
			aspect-ratio: 1/1;
			background-color: var(--before-supp-color);
			border-radius: 50%;
			transform: scale(0);
			transition: transform 0.5s ease, top 0s, left 0s, color 0.1s;
			pointer-events: none;
			z-index: 0;
		}
		svg {
			path, g, polygon, rect, circle {
				transition: fill 0.2s;
				fill: var(--color);
			}
		}
		&:hover {
			color: var(--supp-color);
			background-color: transparent;
			transition: background-color 0s linear 0.2s, color 0.1s;
			svg {
				path, g, polygon, rect, circle {
					transition: fill 0.2s;
					fill: var(--supp-color);
				}
			}
			&::before {
				top: var(--circle-y, 50%);
				left: var(--circle-x, 50%);
				transition: transform 0.5s ease, top 0s, left 0s, color 0.1s;
				transform: scale(8);
			}
		}
		&.reset::before {
			transform: scale(0);
		}
		&.variant-${BUTTON_VARIANTS.PRIMARY} {
			&:hover {
				--before-supp-color: ${({ theme }) => theme.colors.whiteSmoke};
				color: ${({ theme }) => theme.colors.richBlue};
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.richBlue};
					}
				}
			}
        }
		&.variant-${BUTTON_VARIANTS.SECONDARY}{
			&:hover {
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.antiflashWhite};
					}
				}
			}
		}
		&.variant-${BUTTON_VARIANTS.TERTIARY}{
			&:hover {
				color: ${({ theme }) => theme.colors.white};
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.white};
					}
				}
			}
		}
		&.variant-${BUTTON_VARIANTS.QUATERNARY} {
			&:hover {
				color: ${({ theme }) => theme.colors.richBlue};
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.richBlue};
					}
				}
			}
		}
		&.variant-${BUTTON_VARIANTS.QUINARY} {
			&:hover {
				--before-supp-color: ${({ theme }) => theme.colors.white};
				color: ${({ theme }) => theme.colors.richBlue};
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.richBlue};
					}
				}
			}
        }
		&.variant-${BUTTON_VARIANTS.SENARY} {
			&:hover {
				--before-supp-color: ${({ theme }) => theme.colors.platinum};
				color: ${({ theme }) => theme.colors.richBlue};
				svg {
					path, g, polygon, rect, circle  {
						fill: ${({ theme }) => theme.colors.richBlue};
					}
				}
			}
        }
		&.variant-${BUTTON_VARIANTS.SEPTENARY} {
			&:hover {
				--before-supp-color: ${({ theme }) => theme.colors.richBlue};
				color: ${({ theme }) => theme.colors.white};
				svg {
					path, g, polygon, rect, circle {
						fill: ${({ theme }) => theme.colors.white};
					}
				}
			}
        }
		
	}
	
	&:not(&.type-${BUTTON_TYPE.UNSTYLED}){
		--scale-duration: calc(var(--rgr-duration));
		line-height: normal! important;
		padding: var(--spacing-y) var(--spacing-x);
		outline: solid 1px transparent;
		background-color: var(--supp-color, transparent);
		color: var(--color, white);
        overflow: hidden;
		transition: scale var(--scale-duration) var(--ease), background-color 0.2s linear;
		span, div.children-wrap {
			position: relative;
			display: flex;
			align-items: center;
			column-gap: 14px;
			z-index: 1;
		}
		&.variant-${BUTTON_VARIANTS.PRIMARY} {
            --color: ${({ theme }) => theme.colors.white};
            --supp-color: ${({ theme }) => theme.colors.chilliRed};
            --before-supp-color: ${({ theme }) => theme.colors.white};
			border: none;
			svg {
				path, g, polygon, rect, circle {
					fill: ${({ theme }) => theme.colors.white};
				}
			}
        }
		&.variant-${BUTTON_VARIANTS.SECONDARY}{
            --color: ${({ theme }) => theme.colors.richBlack};
            --supp-color: ${({ theme }) => theme.colors.antiflashWhite};
			--before-supp-color: ${({ theme }) => theme.colors.richBlack};
			border: none;
		}
		&.variant-${BUTTON_VARIANTS.TERTIARY}{
            --color: ${({ theme }) => theme.colors.richBlack};
            --supp-color: ${({ theme }) => theme.colors.white};
			--before-supp-color: ${({ theme }) => theme.colors.richBlack};
			border: solid 1px var(--color, white);
		}
		&.variant-${BUTTON_VARIANTS.QUATERNARY} {
            --color: ${({ theme }) => theme.colors.white};
            --supp-color: ${({ theme }) => theme.colors.richBlue};
			--before-supp-color: ${({ theme }) => theme.colors.white};
            border: none;
		}
		&.variant-${BUTTON_VARIANTS.QUINARY} {
            --color: ${({ theme }) => theme.colors.white};
            --supp-color: ${({ theme }) => theme.colors.chilliRed};
            --before-supp-color: ${({ theme }) => theme.colors.white};
			border: none;
        }
		&.variant-${BUTTON_VARIANTS.SENARY} {
            --color: ${({ theme }) => theme.colors.white};
            --supp-color: ${({ theme }) => theme.colors.chilliRed};
            --before-supp-color: ${({ theme }) => theme.colors.white};
			border: none;
        }
		&.variant-${BUTTON_VARIANTS.SEPTENARY} {
            --color: ${({ theme }) => theme.colors.white};
            --supp-color: ${({ theme }) => theme.colors.chilliRed};
            --before-supp-color: ${({ theme }) => theme.colors.white};
			border: none;
			svg {
				path, g, polygon, rect, circle {
					fill: ${({ theme }) => theme.colors.white};
				}
			}
        }
		&.type-${BUTTON_TYPE.CLASSIC} {
            border-radius: 6px;
		}		
		 &.type-${BUTTON_TYPE.ANIMATED} {
             border-radius: 6px;
		 }
		&.type-${BUTTON_TYPE.SUBMIT} {
			border-radius: 6px;
		}
		
		&.type-${BUTTON_TYPE.CHIP}{
            border-radius: 56px;

            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
                min-width: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
			}

            @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
                min-width: ${getCssSizeValue(148)};
            }
		}
		
		//&:after{
		//	content:' ';
		//	position: absolute;
		//	inset: 0;
        //    box-shadow: 0 0 5px 1px darkgrey;
		//	transition: opacity var(--scale-duration) var(--ease);
        //    opacity: 0;
		//	border-radius: inherit;
		//}
		//
        &:not(&.no-click-animation){
			&:active {
				//scale: 0.95;
				//&:after{
				//	//opacity: 1;   
				//}
			}
		}

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            max-height: ${getCssSizeValue(50, BREAKPOINTS.TABLET)};
            --spacing-x: ${getCssSizeValue(14, BREAKPOINTS.TABLET)};
            --spacing-y: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
			
            &.type-${BUTTON_TYPE.CHIP}{
            }
			&.search-visible-tablet {
				background-color: ${({theme}) => theme.colors.chilliRed};
				border-radius: 0;
				border: none;
				height: 50px;
				min-height: 50px;
				width: 50px;
				margin-top: 5px;
				svg{
					min-width: 20px;
					path, g, polygon, rect, circle {
						fill: ${({theme}) => theme.colors.white};
					}
				}
			}
		}
		
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			max-height: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
            //--font-size: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
            --spacing-x: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
            --spacing-y: ${getCssSizeValue(14, BREAKPOINTS.DESKTOP)};

            &.type-${BUTTON_TYPE.CHIP}{
                //--spacing-y: ${getCssSizeValue(19, BREAKPOINTS.DESKTOP)};
                //--font-size: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
            }
			pointer-events: auto;
			opacity: 1;
			transition: opacity 0.3s ease-in-out 0.4s, scale var(--scale-duration) var(--ease);
			&.search-visible {
				opacity: 0;
				pointer-events: none;
				transition: opacity var(--color-transition-duration) var(--ease), scale var(--scale-duration) var(--ease);
			}
        }
		
		
	}

	&.type-${BUTTON_TYPE.UNSTYLED}{
		border: none;
		background-color: transparent;
	}
	a {
		text-decoration: none;
	}
	
	&.i-pay-medium, &.be-with-us {
		font-weight: ${({theme}) => theme.fontWeight.medium}!important;
	}

	
	&:disabled {
		cursor: not-allowed;
		opacity: 0.5 !important;
	}
	
`;

const BTN = styled.button`
	${styles}
`;
const Anchor = styled.a`
	${styles}
`;
const Link = styled(UniversalLink)`
	${styles}
`;

function getTag(tag = '') {
	switch (tag) {
		case 'a':
			return Anchor;
		case 'button':
			return BTN;
		case 'link':
			return Link;
		default:
			return BTN;
	}
}

const DEFAULT_BUTTON = {
	TYPE: BUTTON_TYPE.CLASSIC,
	VARIANT: BUTTON_VARIANTS.PRIMARY,
	FONT: 'strawford',
	TAG: 'button',
	TYPO: 52
}

const Button = forwardRef(function Button(
	{
		as = DEFAULT_BUTTON.TAG,
		variant: vt=DEFAULT_BUTTON.VARIANT,
		type: tp=DEFAULT_BUTTON.TYPE,
		onClick,
		className='',
		font: ft=DEFAULT_BUTTON.FONT,
		typo: ty= DEFAULT_BUTTON.TYPO,
		animation = true,
		noHoverEffect = false,
		children,
		...props
	}, fref) {
	const ButtonRef = useRef()
	const Tag = useMemo(() => getTag(as), [as]);
	const variant = vt || DEFAULT_BUTTON.VARIANT
	const type = tp || DEFAULT_BUTTON.TYPE
	const font = ft || DEFAULT_BUTTON.FONT
	const typo = ty || DEFAULT_BUTTON.TYPO
	const { contextSafe } = useGSAP({ scope: ButtonRef });

	const animateOnClick = contextSafe(() => {
		if(!ButtonRef?.current || !animation) return
		const tl = gsap.timeline()
		const dur = duration/3
		tl.to(ButtonRef.current, {
			scale: 0.95,
			duration: dur,
			ease: 'sine.out'
		}).to(ButtonRef.current,{
			scale: 1,
			duration: dur,
			ease: 'sine.in'
		})
	})
	const handleMouseEnter = (e) => {
		const button = e.currentTarget;
		const rect = button.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;
		button.style.setProperty("--circle-x", `${x}px`);
		button.style.setProperty("--circle-y", `${y}px`);
		button.classList.remove("reset");
	};

	const handleMouseMove = (e) => {
		const button = e.currentTarget;
		const rect = button.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const y = e.clientY - rect.top;
		button.style.setProperty("--circle-x", `${x}px`);
		button.style.setProperty("--circle-y", `${y}px`);
		// button.classList.remove("reset");
	};
	const handleMouseLeave = (e) => {
		const button = e.currentTarget;
		const rect = button.getBoundingClientRect();
		let x = e.clientX - rect.left;
		let y = e.clientY - rect.top;

		if (x < 0) x = -15;
		if (x > rect.width) x = rect.width;
		if (y < 0) y = -15;
		if (y > rect.height) y = rect.height;

		button.style.setProperty("--circle-x", `${x}px`);
		button.style.setProperty("--circle-y", `${y}px`);
		button.classList.add("reset");
	};

	useImperativeHandle(fref, () => ButtonRef.current, [])

	return (
		<Tag
			onClick={(e) => {
				if(isFunction(onClick)) onClick(e)
				animateOnClick()
			}}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onMouseMove={handleMouseMove}
			ref={ButtonRef}
			className={`button typo-${font} typo-${font}-${typo} variant-${variant} ${noHoverEffect ? 'no-hover' : ''} type-${type} ${className}`}
			{...props}
		>
			<span>{children}</span>
		</Tag>
	);
});
export default Button;
