import styled from "styled-components";
import {getCssSizeValue, getHtmlFromRedactorField} from "@/utils/utils";

const CookieWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-right: 10px;
	position: relative;
	row-gap: ${getCssSizeValue(24)};
	width: 100%;

	label {
		display: flex;
		flex-direction: row;
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		justify-content: space-between;
		pointer-events: none;
		align-items: center;
		position: relative;
		width: 100%;

		input {
			height: 25px;
			opacity: 0;
			pointer-events: auto;
			position: absolute;
			right: 0;
		}

		span {
			-webkit-transition: 0.4s;
			background-color: ${({ theme }) => theme.colors.gray};
			border-radius: 34px;
			bottom: 0;
			cursor: ${({ isDefault }) => (isDefault ? 'not-allowed' : 'pointer')};
			height: 26px;
			left: 0;
			pointer-events: auto;
			position: relative;
			right: 0;
			top: 0;
			transition: 0.4s;
			width: 54px;

			&:before {
				-webkit-transition: 0.4s;
                background: ${({theme}) => theme.colors.richBlack};
				border-radius: 50%;
				bottom: 4px;
				content: '';
				height: 18px;
				left: 4px;
				position: absolute;
				transition: 0.4s;
				width: 18px;
			}
		}

		input:checked + span {
            background: ${({theme}) => theme.colors.chilliRed};
		}

        input:not(checked) + span {
            background: ${({theme}) => theme.colors.antiflashWhite};
        }

		input:checked + span:before {
			-ms-transform: translateX(26px);
			-webkit-transform: translateX(26px);
            background: ${({theme}) => theme.colors.white};
			transform: translateX(26px);
		}
	}
`;

export default function SingleCookieItem({ title, description, id, isDefault = false, options, handleSwitch }) {
	return (
		<CookieWrapper isDefault={isDefault}>
			<label className='typo-strawford typo-strawford-73'>
				{title}
				{isDefault ? (
					<input type="checkbox" defaultChecked disabled id={id} />
				) : (
					<input type="checkbox" id={id} checked={options[id]} onChange={(e) => handleSwitch(id, e.target.checked)} />
				)}
				<span className="slider round" />
			</label>
			<div className='typo-strawford typo-strawford-74' dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
		</CookieWrapper>
	);
}