import React from 'react';
import styled from 'styled-components';
import {getHtmlFromRedactorField} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export const CHECKBOX_VARIANT = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary'
}

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
	padding: 0 16px;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		padding: 0;
	}
`;

const CheckboxWrapper = styled.label`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
`;

const HiddenCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const CustomBox = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border 0.3s ease;
	
	&.${CHECKBOX_VARIANT.PRIMARY} {
        border: ${({ theme }) =>  `1px solid ${theme.colors.richBlack}`};
        background-color: ${({ checked, theme }) => checked ? `${theme.colors.richBlack}` : `${theme.colors.white}`};
	}

    &.${CHECKBOX_VARIANT.SECONDARY} {
        border: ${({ theme }) =>  `1px solid ${theme.colors.platinum}`};
        background-color: ${({ checked, theme }) => checked ? `${theme.colors.platinum}` : `${theme.colors.richBlack}`};
    }
`;

const TickIcon = styled.svg`
    width: 12px;
    height: 12px;
`;

const LabelText = styled.span`
    cursor: pointer;
    user-select: none;
    font-weight: ${({theme}) => theme.fontWeight.regular};
    a {
        font-weight: ${({theme}) => theme.fontWeight.bold};
        text-decoration: none;
        position: relative;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            bottom: 3px;
            width: 100%;
            height: 1px;
            background-color: ${({theme}) => theme.colors.chilliRed};
        }
    }

    &.${CHECKBOX_VARIANT.PRIMARY} {
		a {
            color: ${({theme}) => theme.colors.chilliRed}!important;
		}
        &:before {
            background-color: ${({theme}) => theme.colors.chilliRed};
		}
    }

    &.${CHECKBOX_VARIANT.SECONDARY} {
        a {
            color: ${({theme}) => theme.colors.chilliRed}!important;
        }
        &:before {
            background-color: ${({theme}) => theme.colors.chilliRed};
        }
	}
`;

const CustomCheckbox = ({
	name,
	checked,
	onChange,
	required,
	agreement,
	variant = CHECKBOX_VARIANT.PRIMARY
}) => {
	const handleCheckboxToggle = () => {
		onChange(!checked);
	};
	return (
		<CheckboxContainer className='checkbox-container'>
			<CheckboxWrapper>
				<HiddenCheckbox
					type="checkbox"
					name={name}
					checked={checked}
					onChange={handleCheckboxToggle}
					required={required}
				/>
				<CustomBox
					checked={checked}
					className={`${variant} checkbox-box`}
				>
					{checked && (
						<TickIcon
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="none"
							stroke={variant === CHECKBOX_VARIANT.PRIMARY ? 'white' : '#051016' }
							strokeWidth="3"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polyline points="20 6 9 17 4 12" />
						</TickIcon>
					)}
				</CustomBox>
			</CheckboxWrapper>
			<LabelText
				className={`typo-strawford typo-strawford-23 ${variant}`}
				onClick={handleCheckboxToggle}
				dangerouslySetInnerHTML={getHtmlFromRedactorField(agreement)}
			/>
		</CheckboxContainer>
	);
};

export default CustomCheckbox;
