import React, {useEffect, useMemo} from 'react';
import styled from "styled-components";
import GridContainer from "@/components/common/GridContainer";
import {getCssSizeValue} from "@/utils/utils";
import List from "@/components/Recap/List";
import HeaderDescription from "@/components/common/Header&Description";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {useIsDesktop} from "@/hooks/useDevice";
import useRecap from "@/components/Recap/RecapProvider";
import {useRouter} from "next/router";
import useGlobals from "@/hooks/useGlobals";

const Wrapper = styled(GridContainer)`
    //.text{
    //	word-break: break-word;
    //}
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        .header-and-description {
            margin-top: ${getCssSizeValue(136)};
            height: fit-content;
        }
    }

`

const MAX_ITEMS = 6

export function getRecapDataFromData(data, content = [], aRecap) {
	const actions = data?.actions || []
	const id = data?.recapInfo?.id || ''
	const enabled = data?.recapInfo?.showRecap || false
	const topBarRecapEnabled = data?.recapInfo?.showTopBarRecap || false
	const header = data?.recapInfo?.headerDescription?.header || ''
	const description = data?.recapInfo?.headerDescription?.description || ''
	const topBarRecapHeader = data?.recapInfo?.topBarRecapHeader || ''
	const listHeader = data?.recapInfo?.listHeader || ''
	const listBoxColumnStart = data?.recapInfo?.listBoxColumnStart || 12
	const subHeader = data?.recapInfo?.subHeader || ''
	const contentSrc = content || data?.content || []
	const recapActionsElement = actions.length ? {
		id: aRecap?.id,
		recap: {
			...aRecap?.recap,
			topBarRecapHeader: aRecap?.recap?.header || '',
		}
	} : null


	const list = Array.isArray(contentSrc)
		? [
			{
				id: id,
				recap: {
					header,
					subHeader,
					topBarRecapHeader,
					inRecap: false
				}
			},
			...contentSrc
				.filter((item) => item?.recap?.inRecap)
				.slice(0, MAX_ITEMS - 1),
			recapActionsElement
		]
		: []

	const paddings = data?.recapInfo?.paddings || {}
	return {
		id,
		enabled,
		topBarRecapEnabled,
		header,
		subHeader,
		description,
		listBoxColumnStart,
		listHeader,
		list,
		paddings
	}
}

const StyledList = styled(List)`
    display: none;
    grid-column-start: ${({$gridColumnStart}) => $gridColumnStart ? $gridColumnStart : 12};
    grid-column-end: ${({$gridColumnEnd}) => $gridColumnEnd ? $gridColumnEnd : 16};

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        display: flex;
        margin-top: ${getCssSizeValue(40)};
    }
`

const Recap = () => {
	const isDesktop = useIsDesktop()
	const {recapData: data} = useRecap()
	const actions = data?.actions || []

	const {
		enabled = false,
		id = '',
		header = '',
		subHeader = '',
		description = '',
		listHeader = '',
		listBoxColumnStart = 12,
		list: restList = []
	} = data

	if (!enabled) return null

	return (
		<Wrapper id={id} key={id} className={`recap pb-medium`}>
			<HeaderDescription
				data={{header, description}}
				className="col-mobile-8 col-tablet-8 col-desktop-8 border-top"
				indent
			/>
			{isDesktop && (
				<StyledList
					header={listHeader}
					$gridColumnStart={Number(listBoxColumnStart)}
					$gridColumnEnd={Number(listBoxColumnStart) + 4}
				/>
			)}
		</Wrapper>
	);
};

export default Recap;