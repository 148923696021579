import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-00, &-00i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.thin};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-01, &-01i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.extraLight};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-02, &-02i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.light};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-03, &-03i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-04, &-04i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-05, &-05i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
    
    &-06, &-06i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.black};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
            line-height: normal;
        }
    }
	
    &-07, &-07i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: normal;
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-08, &-08i {
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    	line-height: ${getFontSize(28.6, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(46, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(55.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-09, &-09i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(30, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
        }
    }
`