import React, {forwardRef, memo, useImperativeHandle, useRef} from 'react';
import NextImage from 'next/image';
import styled from 'styled-components';
import {IMAGE_FALLBACK} from "@/constants";
import {getFontSize, precise} from "@/utils/utils";
import Text from "@/components/common/Text";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";

const DEFAULT_SIZES = '100%';

const Container = styled.figure`
    display: flex;
    width: 100%;
	flex-direction: column;
    position: relative; //When using next js image with fill prop, the parent element must have position: relative
    overflow: hidden;
    z-index: 1;
    margin: 0;
    container-type: size;
    justify-content: end;
	
	img{
        //handling focal point https://johannesodland.github.io/2023/02/26/cropping-images-with-css-while-keeping-a-focal-point-in-the-center.html
        --container-width: 100cqw; //https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_containment/Container_queries
        --container-height: 100cqh;
        --image-width: calc(var(--container-width) - 100%);
        --image-height: calc(var(--container-height) - 100%);
		
        object-position: 
			clamp(100%, 0.5 * var(--container-width) - var(--focus-x, 0.5) * var(--image-width), 0%)
			clamp(100%, 0.5 * var(--container-height) - var(--focus-y, 0.5) * var(--image-height), 0%)
		;
	}
	
	&.show-meta-data{
		//aspect-ratio: unset !important;
        img {
            position: relative !important;
        }
	}
`;

const Meta = styled.div`
	display: flex;
	flex-direction: column;
    align-items: end;
	row-gap: 2px;
	padding-top: 8px;
	
	.text{
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
	}
`


export function formatImageCMSData(data,showMeta=false){
	const width = data?.width || IMAGE_FALLBACK.width;
	const height = data?.height || IMAGE_FALLBACK.height;
	return {
		url: data?.url || IMAGE_FALLBACK.url,
		alt: data?.alt || IMAGE_FALLBACK.alt,
		width,
		height,
		placeholder: data?.blurUpThumb || IMAGE_FALLBACK.placeholder,
		blurUpThumb: data?.blurUpThumb || IMAGE_FALLBACK.blurUpThumb,
		focalPoint: data?.focalPoint || IMAGE_FALLBACK.focalPoint,
		aspectRatio: data?.aspectRatio || precise((width/height) || 16/9, 2),
		metaData: {
			author: data?.author || null,
			copyright: data?.copyright || null,
			title: data?.title || null,
		},
		showMetaData: showMeta
	};
}


const Image = forwardRef(function Image(
	{
		src = '',
		alt: passedAlt = IMAGE_FALLBACK.alt,
		sizes = DEFAULT_SIZES,
		priority = false,
		placeholder: holder = 'empty',
		blurDataURL = '',
		fit = 'cover',
		quality =75, //default from next.js
		focalPoint = IMAGE_FALLBACK.focalPoint,
		fill = true,
		children,
		visible = true,
		className = '',
		showMetaData=false,
		metaData = {
			author: '',
			title: '',
			copyright: ''
		},
		metaDataColor = colors.white,
		...props
	},
	fref,
) {
	const ContainerRef = useRef()

	useImperativeHandle(fref, () => ContainerRef.current, [])

	const alt = passedAlt || IMAGE_FALLBACK.alt
	const placeholder = src.length <= 0 ? IMAGE_FALLBACK.placeholder : holder //prevent errors
	const secondRowMeta = (metaData?.author?.length > 0  && metaData?.copyright?.length > 0) ?
		`${metaData?.author} | ${metaData?.copyright}`.trim() :
		`${metaData?.author || ''}${metaData?.copyright || ''}`.trim()
	if(!visible) return null
	return (
		<Container ref={ContainerRef} className={`image ${className} ${showMetaData ? 'show-meta-data' : ''}`} {...props}>
			<NextImage
				src={src}
				fill={fill}
				priority={priority}
				placeholder={placeholder}
				blurDataURL={blurDataURL}
				quality={quality}
				alt={alt}
				sizes={sizes}
				style={{
					objectFit: fit,
					'--focus-x': focalPoint?.x,
					'--focus-y': focalPoint?.y
				}}
			/>
			{children}
			{showMetaData && (
				<Meta>
					{metaData?.title?.length > 0 && (
						<Text color={metaDataColor} className="typo-strawford ">
							{metaData?.title}
						</Text>
					)}
					{secondRowMeta.length > 0 && (
						<Text color={metaDataColor} className="typo-strawford ">
							{secondRowMeta}
						</Text>
					)}
				</Meta>
			)}
		</Container>
	);
});

export default memo(Image);
