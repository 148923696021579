import React from 'react';

const Loupe = (props) => {
	return (
		<svg
			id="ikony"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 22 22"
			{...props}
		>
			<g>
				<path
					d="m15.63,13.52c.87-1.28,1.37-2.82,1.37-4.48,0-4.43-3.6-8.03-8.02-8.03S.95,4.61.95,9.03s3.6,8.02,8.03,8.02c1.68,0,3.25-.52,4.54-1.41l5.37,5.37,2.11-.02v-2.11s-5.37-5.38-5.37-5.38ZM3.95,9.03c0-2.77,2.25-5.03,5.03-5.03s5.02,2.25,5.02,5.03-2.25,5.02-5.02,5.02-5.03-2.25-5.03-5.02Z"
				/>
			</g>
		</svg>
	);
}

export default Loupe;
