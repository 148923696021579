import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useSearchBox} from 'react-instantsearch';
import Loupe from '../common/Icon/SVGR/Loupe';
import {BREAKPOINTS} from "@/styles/themeSettings";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {useSelector} from "react-redux";
import useGlobals from "@/hooks/useGlobals";
import useTranslateElementOnScroll from "@/hooks/useTranslateElementOnScroll";
import useRecap from "@/components/Recap/RecapProvider";
import { useDebouncedCallback } from 'use-debounce';

const StyledSearchForm = styled.form`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	// margin-right: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
	padding: 0 ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	//height: 88px;
	height: 0;
	overflow: hidden;
	svg {
		width: 19px;
		height: 19px;
		path {
			fill: ${({theme, $isDarkSearch}) => $isDarkSearch ? theme.colors.richBlack :theme.colors.slateGray}!important;
		}
	}
	svg.loupe {
		margin-right: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
	}
	p {
		color: ${({theme, $isDarkSearch}) => $isDarkSearch ? theme.colors.richBlack :theme.colors.white};
		font-weight: ${({theme}) => theme.fontWeight.medium}!important;
	}
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		transform: scaleX(0);
		transform-origin: calc(100% - 104px) center;
		transition: transform 0.3s ease-in-out;
		height: 100%;
		background-color: ${({theme}) => theme.colors.white};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		//height: 140px;
		height: 0;
		padding: 0 ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
		background-color:  ${({theme}) => theme.colors.antiflashWhite};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		// width: ${getCssSizeValue(605, BREAKPOINTS.DESKTOP)};
		width: ${getCssSizeValue(635, BREAKPOINTS.DESKTOP)};
		background-color: transparent;
		position: fixed;
		align-items: center;
		top: 24px;
		margin-right: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
		//left: calc(100vw - ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)} - ${getCssSizeValue(605, BREAKPOINTS.DESKTOP)});
		right: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
    	height: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
		padding: 0 ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			transform: scaleX(0);
			transform-origin: left;
			transition: transform 0.3s ease-in-out;
			height: 100%;
			background-color: ${({theme}) => theme.colors.white};
		}
	}
	@media (min-width: 1440px) {
		width: ${getCssSizeValue(605, BREAKPOINTS.DESKTOP)};
	}
	&.search-open {
		transition: all 0.3s ease-in-out;
		//width: calc(100% - 42px - 24px);
		height: 88px;
		width: 100%;
		transform-origin: left;
		@media(min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			//width: calc(100% - 42px - 24px);
			height: 140px;
			width: 100%;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			height: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
			width: ${getCssSizeValue(635, BREAKPOINTS.DESKTOP)};
		}
		@media (min-width: 1440px) {
			width: ${getCssSizeValue(605, BREAKPOINTS.DESKTOP)};
		}
		svg {
			path {
				fill: ${({theme}) => theme.colors.richBlack}!important;
			}
		} 
		p {
			color: ${({theme}) => theme.colors.richBlack}!important;
		}

		&:before {
			content: '';
			position: absolute;
			width: 100%;
			left:0;
			transform: scaleX(1);
			//transform-origin: left;
			//transform-origin: calc(100% - 104px) center;
			transform-origin: calc(100% - 104px) center;
			transition: transform 0.3s ease-in-out 0.21s;
			height: 100%;
			z-index: -1;
			//background-color: deeppink;
			background-color: ${({theme}) => theme.colors.white};
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
				transform-origin: calc(100% - 104px) center;
				background-color: ${({theme}) => theme.colors.antiflashWhite};
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				transform-origin: left;
				width: calc(100% - ${getCssSizeValue(56, BREAKPOINTS.DESKTOP)});
				background-color: ${({theme}) => theme.colors.white};
			}
			
		}
		&:hover input::placeholder {
			transition: font-weight 0.2s ease-in-out;
			//font-weight: ${({theme}) => theme.fontWeight.bold};
		}
		
	}
`;

const FixedLine = styled.div`
	position: fixed;
	width: 2px;
	height: 100vh;
	top: 0;
	left: 32px;
	background-color: red;
	z-index:100;
`

const StyledSearchInput = styled.input`
	background-color: transparent;
	color: ${({ theme, $isDarkSearch }) => $isDarkSearch ? theme.colors.richBlack : theme.colors.white};
	outline: none;
	caret-color: ${({ theme }) => theme.colors.richBlack};
	height: 42px;
	//width: 100%;
	width: calc(100% - 42px - 24px);
	padding-right: 40px;
	margin-top: 24px;
	padding-left: 16px;
	border: 1px solid ${({ theme }) => theme.colors.richBlack};
	font-family: ${({theme}) => theme.fontFamily.StrawfordFont};
	font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	line-height:${getFontSize(21.6, BREAKPOINTS.MOBILE)};
	//font-weight: ${({theme}) => theme.fontWeight.medium};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	border-radius: 0;
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
		padding-left: 72px;
		height: 60px;
		border: none;
		background-color: ${({theme}) => theme.colors.white};
	}
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape) {
		font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
		padding-right: 80px;
		padding-left: 0;
		background-color: transparent;
		border: none;
		height: unset;
		margin-top: 0;
	}
	&::placeholder {
		color: ${({ theme, $isDarkSearch }) => $isDarkSearch ? theme.colors.richBlack : theme.colors.slateGray};
		font-family: ${({theme}) => theme.fontFamily.StrawfordFont};
		font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(21.6, BREAKPOINTS.MOBILE)};
		transition: font-weight 0.2s ease-in-out;
		// font-weight: ${({theme}) => theme.fontWeight.regular};
		//font-weight: ${({theme}) => theme.fontWeight.bold};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
        }
	}
	&.search-open {
		color: ${({ theme }) => theme.colors.richBlack};
		&::placeholder {
			color: ${({ theme }) => theme.colors.richBlack};
		}
	}
`;

const ClearButton = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-right: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
	}

`

const StyledCloseButton = styled.div`
	position: absolute;
	//display: flex;
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: background-color 0.2s ease-in-out;
	background-color: ${({ theme }) => theme.colors.chilliRed};
	color: ${({ theme }) => theme.colors.white};
	cursor: pointer;
	width: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	height: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	top: ${getCssSizeValue(5, BREAKPOINTS.MOBILE)};
	right: ${getCssSizeValue(-58, BREAKPOINTS.MOBILE)};
	@media(min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px)) {
		top: ${getCssSizeValue(5, BREAKPOINTS.TABLET)};
		right: ${getCssSizeValue(-58, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
		height: ${getCssSizeValue(40, BREAKPOINTS.DESKTOP)};
		top: ${getCssSizeValue(5, BREAKPOINTS.DESKTOP)};
		//right: ${getCssSizeValue(-58, BREAKPOINTS.DESKTOP)};
		right: 0;
		svg {
			width: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
			height: ${getCssSizeValue(22, BREAKPOINTS.DESKTOP)};
			path {
				fill: ${({ theme }) => theme.colors.antiflashWhite}!important;
			}
		}
	}
	&:hover {
		background-color: ${({theme}) => theme.colors.barnRed};
	}
	&.visible {
		opacity: 1;
		transition: opacity 0.3s ease-in-out 0.2s, background-color .2s ease-in-out;
		svg {
			path {
				fill: ${({ theme }) => theme.colors.antiflashWhite}!important;
			}
		}
	}
`;


const SearchBox = ({ className, onClick, onFocus, onChange, handleCloseSearch, placeholder, hasFocus, isDesktop, isTablet, isDarkSearch, recapEnabled, query }) => {
	const { recapExpanded, searchWrapper } = useRecap()
	const { query: searchQuery, refine } = useSearchBox();
	const inputRef = useRef();
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	const globals = useGlobals();
	const inputPlaceholder = globals?.inputPlaceholder || '';
	useEffect(() => {
		if (hasFocus) {
			inputRef.current.focus();
		}
	}, [hasFocus]);
	useEffect(() => {
		if(!isSearchBarVisible) {
			refine('')
			onChange('')
		}
	}, [isSearchBarVisible]);

	const { translateElementRef } = useTranslateElementOnScroll(recapEnabled, recapExpanded, recapEnabled)

	useEffect(() => {
		if (!translateElementRef.current) return
		searchWrapper.current = translateElementRef.current
	}, [translateElementRef.current])


	const debounced = useDebouncedCallback(
		(value) => {
			refine(value)
		},
		700
	);

	return (
		<StyledSearchForm
			ref={translateElementRef}
			onClick={onClick}
			$isDarkSearch={isDarkSearch}
			className={isSearchBarVisible ? 'search-open' : ''}
		>
			{isDesktop&& <Loupe className='loupe' />}
			<StyledSearchInput
				ref={inputRef}
				className={isSearchBarVisible ? 'search-open' : ''}
				type="text"
				$isDarkSearch={isDarkSearch}
				placeholder={inputPlaceholder}
				aria-label={placeholder}
				onChange={(e) => {
					debounced(e.target.value)
					onChange(e.target.value);
				}}
				value={query}
				onFocus={onFocus}
			/>
			{query !== '' && isSearchBarVisible && isDesktop && <ClearButton className='typo-strawford typo-strawford-41' onClick={() => {
				refine('')
				onChange('')
				inputRef.current.focus();
			}
			}>clear</ClearButton>}
			<StyledCloseButton className={isSearchBarVisible ? 'visible' :''} onClick={(e) => {
				handleCloseSearch(e)
				// refine('')
				onChange('')
			}}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" fill="none">
					<path
						d="M19.0043 4.58534C18.5707 4.15174 17.8703 4.15174 17.4367 4.58534L12 10.0109L6.56336 4.57422C6.12976 4.14062 5.42933 4.14062 4.99573 4.57422C4.56213 5.00782 4.56213 5.70825 4.99573 6.14185L10.4324 11.5785L4.99573 17.0152C4.56213 17.4488 4.56213 18.1492 4.99573 18.5828C5.42933 19.0164 6.12976 19.0164 6.56336 18.5828L12 13.1461L17.4367 18.5828C17.8703 19.0164 18.5707 19.0164 19.0043 18.5828C19.4379 18.1492 19.4379 17.4488 19.0043 17.0152L13.5676 11.5785L19.0043 6.14185C19.4268 5.71937 19.4268 5.00782 19.0043 4.58534Z"
						fill="#EBEBEB"/>
				</svg>
			</StyledCloseButton>
		</StyledSearchForm>
	);
};

export default SearchBox;
