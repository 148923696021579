import {useEffect, useState} from 'react';

export default function useInit(){
	const [init, setInit] = useState(false)

	useEffect(() => {
		setInit(true)
	}, []);

	return init
}
