import {useMemo} from 'react';
import {useTheme} from 'styled-components';

//returns appropriate color
//example: const color = useColor('blue'); returns 'rgba(178, 225, 253, 1)'
export default function useColor(color = '') {
	const theme = useTheme();
	return useMemo(() => {
		const cl = color || undefined
		return theme?.colors?.[cl] || cl;
	}, [theme, color]);
}
