import {createContext, memo, useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {ScrollTrigger} from "@/utils/gsap";
import {useViewportHeight, useViewportWidth} from "@/hooks/useViewport";
import {useBranch} from "@/hooks/useBranch";
import useCustomContext from "@/hooks/useCustomContext";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import {isFunction, isNumber, isObject, isReactRef, isUndefined, logTable, precise, throttle} from "@/utils/utils";


const DEFAULT_SCROLL_PROPS = {
	scroll: 0,
	direction: 'up',
	velocity: 0,
	event: null,
	diff: 0
}

const DEFAULT_SCROLL_TO_OPTIONS = {
	top: 0,
	left: 0,
	behavior: "instant",
	offset: 0,
	onComplete: (DEFAULT_SCROLL_PROPS) => {}
}

const INIT_CONTEXT = {
	refresh: (delay=0) => {},
	scrollTo: (options = DEFAULT_SCROLL_TO_OPTIONS) => {},
	lock: () => {},
	unlock: (force=false) => {},
	registerScrollHandler: (fn) => {},
	unregisterScrollHandler: (fn) => {},
}
const ATTRIBUTE = 'data-scroll-ignore-lock'

const keys = {37: 1, 38: 1, 39: 1, 40: 1};
function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}



const ScrollContext = createContext({ ...INIT_CONTEXT });
const THROTTLE_LIMIT = 50 //ms

//TODO: refresh should also re-init locked scroll listeners
export const ScrollProvider = memo(function ScrollProvider({ children, throttle:throttleScroll = false }) {
	const viewportWidth = useViewportWidth()
	const viewportHeight = useViewportHeight()
	const { selectedOption } = useBranch();
	const [scrollLock, setScrollLock] = useState(true)
	const handlersRef = useRef(new Map());
	const currentScroll = useRef(0);
	const prevScroll = useRef(0);
	const lockCount = useRef(1); //keep count of lock requests, prevent bugs with overlying locking
	const prevTimestamp = useRef(Date.now()); // Store the timestamp of the last scroll event
	const refreshTimeout = useRef(0); // Store the timestamp of the last scroll event
	const scrollProps = useRef(DEFAULT_SCROLL_PROPS)
	const onCompleteDebouncedRef = useRef(null)

	const calculateScrollProps = useCallback((scroll) => {
		currentScroll.current = scroll
		const now = Date.now();
		const timeElapsed = now - prevTimestamp.current; // Time since the last scroll event

		const diff = currentScroll.current - prevScroll.current; // Displacement (scroll difference)
		const direction = diff > 0 ? 'down' : 'up';

		let velocity = timeElapsed > 0 ? (Math.abs(diff) / timeElapsed) * 1000 : 0; //in pixels per second
		velocity = precise(velocity)

		return ({
			scroll: currentScroll.current,
			direction,
			velocity,
			diff
		})
	},[])

	const registerScrollHandler = useCallback((key, handler, debug=false) => {
		if(debug){
			logTable({
				action: 'register',
				key,
				handler: handler
			})
		}
		if (!handlersRef.current.has(key)) {
			handlersRef.current.set(key, handler);
		}
	}, []);

	const unregisterScrollHandler = useCallback((key,debug=false) => {
		if(debug){
			logTable({
				action: 'unregister',
				key,
			})
		}
		if (handlersRef.current.has(key)) {
			handlersRef.current.delete(key);
		}
	}, []);


	// Use useEffect to add and remove scroll event listener
	useEffect(() => {
		const handleScroll = (event) => {
			let scrollY = window.scrollY;
			const maxScroll = document.documentElement.scrollHeight - viewportHeight; //maximum scrollable height
			// Ignore scroll events when rubber-banding (i.e., negative scroll values or values beyond max scroll) on IOS
			if (scrollY < 0 || scrollY > maxScroll) {
				return;
			}

			scrollProps.current = {
				...calculateScrollProps(scrollY),
				event
			}

			handlersRef.current.forEach((handler) => handler(scrollProps.current));
			prevScroll.current = window.scrollY

			//optional, reset values if data accessed out of event
			//keep the direction and scroll though
			scrollProps.current.velocity = 0
			scrollProps.current.diff = 0
			scrollProps.current.event = null
		};

		const throttledScroll = throttleScroll ? throttle(handleScroll, THROTTLE_LIMIT) : handleScroll;

		window.addEventListener('scroll', throttledScroll);

		// Cleanup listener on unmount
		return () => {
			window.removeEventListener('scroll', throttledScroll);
		};
	}, [viewportHeight, throttleScroll]);

	const scrollTo = useCallback((options=DEFAULT_SCROLL_TO_OPTIONS) => {
		let top = DEFAULT_SCROLL_TO_OPTIONS.top

		//handling different types of targets
		if(!isUndefined(options?.top)){
			if(isNumber(options.top)){
				top = options.top
			}

			if(isObject(options.top)){
				if(isReactRef(options.top)){
					const elTop = options.top?.current?.getBoundingClientRect()?.top || 0
					top = elTop + currentScroll.current
				} else {
					const elTop = options.top?.getBoundingClientRect()?.top || 0
					top = elTop + currentScroll.current
				}
			}

		}

		//add offset
		if(!isUndefined(options?.offset) && isNumber(options?.offset)){
			top += options.offset
		}

		//set options
		const opt = {
			top,
			left: options?.left || DEFAULT_SCROLL_TO_OPTIONS.left,
			behavior: options?.behavior || DEFAULT_SCROLL_TO_OPTIONS.behavior
		}

		window.scrollTo(opt)

		//onComplete handling
		if(!isUndefined(options?.onComplete) && isFunction(options?.onComplete)){
			const { onComplete } = options
			if(opt.behavior === 'smooth'){
				//Smooth
				function onCompleteListener(){
					const round = Math.round(top);
					const diff = Math.abs(currentScroll.current - round);
					// logTable({
					// 	target: round,
					// 	current: scrollProps.current.scroll,
					// 	diff
					// })
					if(diff < 1){
						onComplete(scrollProps.current);
						window.removeEventListener('scroll', onCompleteListener);
					}
				}
				window.addEventListener('scroll', onCompleteListener);
			} else {
				//Instant
				clearTimeout(onCompleteDebouncedRef.current) //cleanup

				//added tiny timeout because calling onComplete right away was working for old position
				onCompleteDebouncedRef.current = setTimeout(() => {
					onComplete(scrollProps.current);
				}, 50)

			}
		}


	},[])

	const lock = useCallback(() => {
		lockCount.current += 1; //increase lock count
		setScrollLock(true)
	},[])

	const unlock = useCallback((force) => {
		lockCount.current = Math.max(lockCount.current - 1, 0)
		if(lockCount.current === 0 || force){  //unlock only if no more lock requests were made or with force
			lockCount.current = 0
			setScrollLock(false)
		}
	},[])

	const refresh = useCallback((delay=0) => {
		clearTimeout(refreshTimeout.current)

		refreshTimeout.current = setTimeout(() => {
			// console.log('refresh');
			ScrollTrigger.refresh(true)
		},delay || 0)
	},[])

	useIsomorphicLayoutEffect(() => {
		window.history.scrollRestoration = 'manual'
	},[])

	useEffect(() => {
		//forcing scroll triggers refresh on content changing updates or other breaking changes
		refresh()
	}, [refresh, viewportWidth, selectedOption]);

	useIsomorphicLayoutEffect(() => {
		const ignoreLock = [...document.querySelectorAll(`[${ATTRIBUTE}]`)]
		// console.log(ignoreLock);

		function targetedPrevent(e) {
			const { target } = e
			const ignoreTarget = target.getAttribute(ATTRIBUTE) || ignoreLock.some((el) => el.contains(target));
			if(ignoreTarget) return
			preventDefault(e)
		}


		if(scrollLock){
			document.body.classList.add('no-scroll');
			window.addEventListener('touchmove', targetedPrevent); // mobile
			window.addEventListener('wheel', targetedPrevent, { passive: false }); // modern desktop
			window.addEventListener('keydown', preventDefaultForScrollKeys, { passive: false });
		}

		return () => {
			if(scrollLock){
				document.body.classList.remove('no-scroll');
				window.removeEventListener('touchmove', targetedPrevent); // mobile
				window.removeEventListener('wheel', targetedPrevent, { passive: false }); // modern desktop
				window.removeEventListener('keydown', preventDefaultForScrollKeys, { passive: false });
			}
		}
	},[scrollLock])

	// console.log(handlersRef.current);

	const context = useMemo(() => ({
		refresh,
		scrollTo,
		locked: scrollLock,
		lock,
		unlock,
		props: () => scrollProps.current,
		registerScrollHandler,
		unregisterScrollHandler
	}), [registerScrollHandler,unregisterScrollHandler, scrollLock, lock, unlock,scrollTo,refresh]);

	return (
		<ScrollContext.Provider value={context}>
			{children}
		</ScrollContext.Provider>
	)
})

export default function useScrollContext(selector) {
	return useCustomContext(ScrollContext,selector);
}

export const useScroll = (handler, debug=false) => {
	const { registerScrollHandler, unregisterScrollHandler  } = useScrollContext()
	const uniqueKey = useId()
	useEffect(() => {
		// Register the scroll handler
		registerScrollHandler(uniqueKey, handler, debug);

		// Cleanup the scroll handler when the component unmounts or handler changes
		return () => {
			unregisterScrollHandler(uniqueKey, debug);
		};
	}, [handler,uniqueKey, registerScrollHandler, unregisterScrollHandler, debug]);

};
export const useScrollDirection = () => {
	const [direction, setDirection] = useState(DEFAULT_SCROLL_PROPS.direction)

	useScroll(({ direction }) => {
		setDirection(direction)
	})

	return direction

};

//Following hooks are dangerous to use because they can lead to unnecessary component updates
//only really useful if it's essential to update on every scroll
//Preferred usage would be to utilize useScroll like below but store the desired value in ref, rather than state
export const useScrollValue = () => {
	const [scroll, setScroll] = useState(DEFAULT_SCROLL_PROPS.scroll)

	useScroll(({ scroll }) => {
		setScroll(scroll)
	})

	return scroll
};

export const useScrollVelocity = () => {
	const [velocity, setVelocity] = useState(DEFAULT_SCROLL_PROPS.velocity)

	useScroll(({ velocity }) => {
		setVelocity(velocity)
	})

	return velocity

};

export const useScrollDiff = () => {
	const [diff, setDiff] = useState(DEFAULT_SCROLL_PROPS.diff)

	useScroll(({ diff }) => {
		setDiff(diff)
	})

	return diff

};


