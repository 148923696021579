import {useRouter} from 'next/router';
import Link from 'next/link';
import {getLinkAriaLabel} from "@/components/common/Links/utils";
import styled from "styled-components";
import {forwardRef} from "react";

const StyledLink = styled(Link)`
	width: fit-content;
`

const PreserveSearchParamsLink = forwardRef(function PreserveSearchParamsLink({ href, children, disableScroll=true, onClick,  ...props }, fref) {
	const router = useRouter();
	const { label, rest } = getLinkAriaLabel(props)
	const { branch, slug } = router.query;
	const queryParams = {};
	let path = href
	if (branch) {
		queryParams.branch = branch;
	}
	if (slug) {
		path = href.replace('[slug]', slug);
	}
	let [pathname, hash] = path.split("#");
	const hrefWithParams = {
		pathname,
		hash,
		query: queryParams
	};
	return (
		<StyledLink ref={fref} onClick={onClick} scroll={!disableScroll} href={hrefWithParams} aria-label={label} {...rest}>
			{children}
		</StyledLink>
	);
})

export default PreserveSearchParamsLink;
