import {createGlobalStyle} from 'styled-components';
import {BREAKPOINTS} from './themeSettings';
import {getCssSizeValue} from "@/utils/utils";


const Grid = createGlobalStyle`
	:root{
		--grid-base: 16;
		--grid-division: 2;
        --grid-count: calc(var(--grid-base) / var(--grid-division));
        // --grid-gap: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        --grid-gap: 0;
        //--grid-gap: 2px;
        --grid-margin-l: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
        --grid-margin-r: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	}
	
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        :root {
            --grid-division: 2;
            --grid-gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
            --grid-margin-l: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
            --grid-margin-r: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
        }

    }
    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape) {
       :root{
           --grid-division: 1;
           --grid-gap: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
           --grid-margin-l: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
           --grid-margin-r: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
	   }
    }
	
    //Mobile
	.column{
		display: flex;
		flex-direction: column;
	}
	.col{
		--size: 1;
		grid-column: span var(--size);

        ${[...new Array(8)].map((item, index) => `
					&-mobile-${index + 1}{
						--size: ${index + 1};
						grid-column: span var(--size);
					}
				`
	)}
	}


    //Tablet
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        .col{
            grid-column: span var(--size);
            ${[...new Array(8)].map((item, index) => `
					&-tablet-${index + 1}{
						--size: ${index + 1};
						grid-column: span var(--size);
					}
				`
	)}
		}
    }

    //Desktop
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        .col, .ignore-indent{
            grid-column: span var(--size);

            ${[...new Array(16)].map((item, index) => `
					&-desktop-${index + 1}{
						--size: ${index + 1};
						grid-column: span var(--size);
					}
				`
	)}
		}
    }
	
    .indent{
        &-mobile-1{
            --indent: 2;
            > :first-child:not(.ignore-indent) {
                grid-column: var(--indent)/calc(var(--indent) + var(--size));
            }
        }
        &-mobile-3{
            --indent: 4;
            > :first-child:not(.ignore-indent) {
                grid-column: var(--indent)/calc(var(--indent) + var(--size));
            }
        }
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			&-tablet-1{
				--indent: 2;
				> :first-child:not(.ignore-indent) {
					grid-column: var(--indent)/calc(var(--indent) + var(--size));
				}
			}
            &-tablet-2{
                --indent: 3;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-tablet-4{
                --indent: 5;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-tablet-5{
                --indent: 6;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
        }

        @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape) {
            &-desktop-1{
                --indent: 2;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-2{
                --indent: 3;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-3{
                --indent: 4;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
			&-desktop-4{
                --indent: 5;
                > :first-child:not(.ignore-indent)  {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-5{
                --indent: 6;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-6{
                --indent: 7;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-7{
                --indent: 8;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }           
            &-desktop-8{
                --indent: 9;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-9{
                --indent: 10;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
            &-desktop-13{
                --indent: 14;
                > :first-child:not(.ignore-indent) {
                    grid-column: var(--indent)/calc(var(--indent) + var(--size));
                }
            }
        }
    }
	
`;

export default Grid;
