import {createContext, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {motion, useIsPresent} from "framer-motion";
import {useRouter} from "next/router";
import {mapRoutesToText} from "@/components/pageTransition/utils";
import {anim, slide} from "@/components/pageTransition/animate";
import useScrollContext from "@/hooks/useScrollContext";
import {removeAllQueryParamsFromAsPath} from "@/utils/utils";
import useCustomContext from "@/hooks/useCustomContext";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";

const Slide = styled(motion.div)`
	position: fixed;
	//position: absolute;
	z-index: 1000000;
	top: 0;
	left: 0;
	background: ${({theme}) => theme.colors.pennRed};
	width: 100vw;
	height: 100lvh;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Loader = styled(motion.div)`
	font-size: 30px;
	color: white;
`

export const PageTransitionContext = createContext({
	isTransitioning: false,
	ended: false,
});

const TransitionOverlay = ({ onStart, onComplete, children}) => {
	const router = useRouter()
	const isPresent = useIsPresent()
	// const isDebug = useIsDebug()
	const target = useRef(0)
	const scroll = useScrollContext()
	const handleAnimationComplete = (name) => {
		if (!isPresent) {
			return
		}

		if(name === 'enter'){
			scroll.unlock()

			//Look for an element in path and set target scroll to it
			const hash = router.asPath.split('#')[1];
			if (!!hash) {
				const el = document.getElementById(hash);
				// console.log(el, hash);
				if(!!el){
					const elTop = el.getBoundingClientRect().y;
					target.current = Math.max(0, elTop)
				}
			}
			if(target.current) {
				scroll.scrollTo({
					top: target.current,
					left: 0,
					behavior: "smooth"
				})
			}
		}
		onComplete()

		// if(isDebug){
		// logTable({
		// 	animation: name,
		// 	event: 'complete',
		// 	target: target.current,
		// })
		// }
	};

	const handleAnimationStart = (name) => {
		// console.log('start', name);

		if(name === 'enter'){
			//always go to top to reset pos
			scroll.scrollTo({
				top: 0,
				left: 0,
				behavior: "instant"
			})
		}

		if (isPresent) return

		if(name === 'exit'){
			scroll.lock()
		}
		onStart()

		// if(isDebug){
		// logTable({
		// 	animation: name,
		// 	event: 'start',
		// 	target: target.current
		// })
		// }
	};

	return (
		<>
			<Slide
				{...anim(slide)}
				onAnimationComplete={handleAnimationComplete}
				onAnimationStart={handleAnimationStart}
			>
				<Loader className='typo-borna'>{mapRoutesToText(router?.asPath)}</Loader>
			</Slide>
			{children}
		</>
	)
}


export default function PageTransition({ children }) {
	const router = useRouter()
	const [isTransitioning, setIsTransitioning] = useState(true);
	const [loaded, setLoaded] = useState(false);
	const prevPath = useRef('')
	// const [ended, setEnded] = useState(false);
	const [ended, setEnded] = useState(true);

	const context = useMemo(() => ({
		isTransitioning,
		ended
	}), [isTransitioning, ended])


	// const handleAnimationComplete = () => {
	// 	setEnded(true)
	// 	setIsTransitioning(false)
	// };
	//
	// const handleAnimationStart = () => {
	// 	setIsTransitioning(true)
	// 	setEnded(false)
	// };

	//this props skips query params changes and will update only on route change
	const redirect = useMemo(() =>  removeAllQueryParamsFromAsPath(router.asPath),[router.asPath])
	const scroll = useScrollContext()
	const target = useRef(0)

	// useEffect(() => {
	// 	target.current = 0
	// 	const hash = router.asPath.split('#')[1];
	// 	if (!!hash) {
	// 		const el = document.getElementById(hash);
	// 		// console.log(el, hash);
	// 		if(!!el){
	// 			const elTop = el.getBoundingClientRect().y;
	// 			target.current = Math.max(0, elTop)
	// 		}
	// 	}
	// 	scroll.scrollTo({
	// 		top: target.current,
	// 		left: 0,
	// 		behavior: "instant"
	// 	})
	// }, [redirect]);

	useEffect(() => {
		setLoaded(true)
		scroll.unlock()
	}, []);

	// Simulate loading by checking when components have fully loaded
	useEffect(() => {
		const handleRouteChange = () => {
			setLoaded(false)
		};

		const handleRouteComplete = () => {
			setLoaded(true)
		};

		// Set up Next.js route events to manage loading state
		router.events.on('routeChangeStart', handleRouteChange);
		router.events.on('routeChangeComplete', handleRouteComplete);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
			router.events.off('routeChangeComplete', handleRouteComplete);
		};
	}, [router]);

	useEffect(() => {
		if(!loaded){
			scroll.lock()
		}

		return () => {
			if(!loaded){
				scroll.unlock()
			}
		}
	}, [loaded]);


	useIsomorphicLayoutEffect(() => {
		// logNamed({ loaded, redirect })
		if(!loaded || (prevPath.current === redirect)) return
		const hash = router.asPath.split('#')[1];
		//reset pos for correct calculations
		scroll.scrollTo({
			top: 0,
			left: 0,
			behavior: "instant"
		})
		if (!!hash) {
			const el = document.getElementById(hash);
			// console.log(el, hash);
			if(!!el){
				const elTop = el.getBoundingClientRect().y;
				target.current = Math.max(0, elTop)

				scroll.scrollTo({
					top: target.current,
					left: 0,
					behavior: "instant"
				})
				target.current = 0
			}
		}

		prevPath.current = redirect
	}, [loaded, redirect]);

	return (
		<PageTransitionContext.Provider value={context}>
			{/*<AnimatePresence mode='wait'>*/}
			{/*<TransitionOverlay key={redirect} onStart={handleAnimationStart} onComplete={handleAnimationComplete}>*/}
			{children}
			{/*</TransitionOverlay>*/}
			{/*</AnimatePresence>*/}
		</PageTransitionContext.Provider>

	);
}

export function usePageTransitionContext(selector=undefined) {
	return useCustomContext(PageTransitionContext,selector);
}
