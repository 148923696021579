import React, {useMemo} from 'react';
import styled from 'styled-components';
import {usePathname} from 'next/navigation';
import PreserveSearchParamsLink from '@/components/common/Links/PreserveSearchParamsLink';
import Container from "@/components/common/Container";
import useIsWWDSingleAction from "@/hooks/useIsWWDSingleAction";
import {PAGES} from "@/constants";

const BreadcrumbsWrapper = styled(Container)`
	display: flex;
	height: 40px;
	align-items: center;
	column-gap: 49px;
	background-color: ${({ theme }) => theme.colors.richBlack};
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.antiflashWhite};
	flex-direction: row;
	justify-content: flex-start;
	overflow-x: auto;
	.segment:last-child:before {
		display: none;
	}
	&::-webkit-scrollbar {
		display: none;
	}
`;

const StyledSingleSegment = styled.div`
	position: relative;
	text-wrap: nowrap;
	&:before {
		content: '';
		display: block;
		position: absolute;
		right: -24px;
		top: 50%;
		transform: translateY(-50%);
		height: 10px;
		width: 1px;
		background-color: ${({ theme }) => theme.colors.antiflashWhite};
	}
	&:hover {
		color: ${({ theme }) => theme.colors.chilliRed};
	}
	&.hidden {
		display: none;
	}
`;

const getTranslatedText = (item, menuTexts) => {
	const translations = {
		[PAGES.WHAT_WE_DO]: 'Co robimy',
		'projekty': 'Projekty',
		'ogloszenia-i-przetargi': 'Ogłoszenia i Przetargi',
		'projekty-i-fakty': 'Projekty i fakty',
		'2027': '2027',
		'2026': '2026',
		'2025': '2025',
		'2024': '2024',
		'2023': '2023',
		'2022': '2022',
		'2021': '2021',
		'2020': '2020'
	};
	return translations[item] || menuTexts.find((el) => el.slug === item)?.text || '';
};

const BreadCrumbs = ({ data, menu, isSingleWhatWeDoPage, isSingleBeWithUs,isSingleArticlePage, isBeWithUsCategory, isAnnouncementsAndTendersYearPage, isAnnouncementsAndTendersSlugPage, isFactsPage, isProductPage }) => {
	const pathname = usePathname();
	const isAction = useIsWWDSingleAction()
	let segments = pathname.split('/').filter((item) => item !== '');
	const menuTexts = useMemo(() => {
		const texts = (menu || []).map((el) => {
			return ({
				text: el?.universalLink?.text || '',
				slug: el?.universalLink?.link?.link?.slug || '',
			})
		});
		texts.push({
			text: (isAction || isSingleWhatWeDoPage || isSingleBeWithUs || isSingleArticlePage)
				? data?.name
				: (isAnnouncementsAndTendersSlugPage || isAnnouncementsAndTendersYearPage || isFactsPage || isProductPage)
					? data?.pageName
					: data?.title || '',
			slug: data?.slug || '',
		});
		return texts;
	}, [isAction, menu, data]);

	return (
		<BreadcrumbsWrapper type="main">
			<StyledSingleSegment className="segment">
				<PreserveSearchParamsLink className="typo-strawford typo-strawford-33" href={`/`}>Home</PreserveSearchParamsLink>
			</StyledSingleSegment>
			{segments.map((item, index) => {
				return(
					<StyledSingleSegment key={item} className={(( isSingleBeWithUs || isAnnouncementsAndTendersSlugPage || isAnnouncementsAndTendersYearPage) && index === 1) ? 'segment hidden' : "segment"}>
						<PreserveSearchParamsLink className="typo-strawford typo-strawford-33" href={`/${segments.slice(0, index + 1).join('/')}`}>
							{getTranslatedText(item, menuTexts)}
						</PreserveSearchParamsLink>
					</StyledSingleSegment>
				)})}
		</BreadcrumbsWrapper>
	);
};

export default BreadCrumbs;
