import React, {useMemo, useState} from 'react';
import SingleSelect from "@/components/selectors/SingleSelect";
import JsCookies from "js-cookie";
import {useRouter} from "next/router";
import {DROPDOWN_VARIANTS} from "@/components/common/Dropdown";


const TRANSLATIONS = {
	classic: {
		pl: {
			pl: 'język polski',
			en: 'język angielski',
			de: 'język niemiecki'
		},
		en: {
			pl: 'polish',
			en: 'english',
			de: 'german'
		},
		de: {
			pl: 'polnisch',
			en: 'englisch',
			de: 'deutsch'
		}
	},
	short: {
		pl: {
			pl: 'PL',
			en: 'EN',
			de: 'DE'
		},
		en: {
			pl: 'PL',
			en: 'EN',
			de: 'DE'
		},
		de: {
			pl: 'PL',
			en: 'EN',
			de: 'DE'
		}
	}
}

function formatLocale(current,locale,short=false){
	const variant = short ? 'short' : 'classic'
	return {
		id: locale,
		value: locale,
		label: TRANSLATIONS[variant][current][locale]
	}
}
const LocaleSelect = ({ short=false,...props }) => {
	const router = useRouter()
	// const [localesOptions] = useState(() => router.locales.map((locale) => formatLocale(locale)))
	const localesOptions = useMemo(() => {
		return router.locales.map((locale) => formatLocale(router.locale, locale,short))
	},[router.locale, router.locales, short])
	const [locale, setLocale] = useState(localesOptions.find((option) => option.id === router.locale));


	const handleLangClick = (lang) => {
		JsCookies.set('NEXT_LOCALE', lang);
		setTimeout(() => {
			router.push(router.asPath, router.asPath, { locale: lang });
		}, 1000);
	}

	const handleLocaleChange = (event) => {
		const selectedValue = event.target.value;
		const selectedOption = localesOptions.find(option => option.value === selectedValue);
		setLocale(selectedOption);
		handleLangClick(selectedValue);
	};

	// return null
	return (
		<SingleSelect
			variant={DROPDOWN_VARIANTS.TERTIARY}
			options={localesOptions}
			selectedValue={locale}
			onChange={handleLocaleChange}
			short={short}
			{...props}
		/>
	);
};

export default LocaleSelect;
