import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-10, &-10i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
    &-11, &-11i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
    }
    &-12, &-12i { 
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
        }
    }
    &-13, &-13i {
		font-size: ${getFontSize(28, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(32.5, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.semiBold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(28, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.5, BREAKPOINTS.DESKTOP)};
        }
    }
    &-14, &-14i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(27, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-15, &-15i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height: normal;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(33, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(33, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-16, &-16i {
        font-size: ${getFontSize(11, BREAKPOINTS.MOBILE)};
		font-weight: 400;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(22, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(25.3, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
        }
    }
    &-17, &-17i {
        font-size: ${getFontSize(42, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(63, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(42, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(63, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(42, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(63, BREAKPOINTS.DESKTOP)};
        }
    }
    &-18, &-18i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(23.4, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(41.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(41.6, BREAKPOINTS.DESKTOP)};
        }
    }
    &-19, &-19i {
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(36, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
`
