import React, {useCallback, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {getCssSizeValue, isFunction} from "@/utils/utils";
import Text from "@/components/common/Text";
import useScrollContext from "@/hooks/useScrollContext";
import CommonIcon from "@/components/common/Icon/Icon";
import {ICONS} from "@/constants";
import LineBreak from "@/components/common/LineBreak";
import useRecap from "@/components/Recap/RecapProvider";
import SimpleIconButton, {SIMPLE_ICON_BUTTON_ANIMATIONS} from "@/components/common/SimpleIconButton";
import {colors} from "@/styles/themeSettings";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${getCssSizeValue(32)};
    width: 100%;
    height: fit-content;
    min-height: ${getCssSizeValue(480)};
    background-color: ${({theme}) => theme.colors.periwinkle};
    padding: ${getCssSizeValue(32)} ${getCssSizeValue(24)};

    .to-right {
        margin-left: auto;
    }
`

const ListContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    row-gap: ${getCssSizeValue(32)};

`

const ListItemWrapper = styled.button`
    --target-scale: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    border: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    .text {
        transition: color var(--rgr-duration) var(--ease);
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    row-gap: ${getCssSizeValue(27)};
`

const Icon = styled(CommonIcon)`
    width: ${getCssSizeValue(64)};
    height: fit-content;
    aspect-ratio: 1;
`

const Top = ({header, active}) => {
	const isActive = active.length > 0
	return (
		<Row>
			<Icon name={ICONS.PILL}/>
			<Right>
				{header.length > 0 && (
					<Text className="typo-borna-53 to-right">
						{header}
					</Text>
				)}
				<SimpleIconButton
					color={colors.richBlack}
					bgColor={isActive ? colors.white : 'transparent'}
					borderColor={isActive ? colors.chilliRed : colors.richBlack}
					rotation={-90}
					animation={isActive ? SIMPLE_ICON_BUTTON_ANIMATIONS.ROTATE_45 : null}
				/>
			</Right>
		</Row>
	)
}

const Item = ({data, activeId, onPointerEnter, onPointerLeave}) => {
	const [scale, setScale] = useState(0)
	const HeaderRef = useRef()
	const WrapperRef = useRef()
	const targetId = useMemo(() => data?.id || '', [data?.id])
	const header = data?.recap?.header || ''
	const subHeader = data?.recap?.subHeader || ''
	const active = useMemo(() => activeId === targetId, [targetId, activeId])
	const scroll = useScrollContext()
	const display = data?.recap?.inRecap || false

	const handleOnClick = useCallback(() => {
		const target = document.getElementById(targetId)
		if (target) {
			scroll.scrollTo({
				top: target,
				offset: -80,
				behavior: 'smooth'
			})
		}

	}, [scroll, targetId])

	return display && (
		<ListItemWrapper
			ref={WrapperRef}
			onClick={handleOnClick}
			// type={BUTTON_TYPE.UNSTYLED}
			onPointerEnter={() => {
				if (isFunction(onPointerEnter)) {
					onPointerEnter(targetId)
				}
			}}
			animation={false}
			onPointerLeave={onPointerLeave}
		>
			<Text ref={HeaderRef} className="typo-borna-54" color={active ? 'red' : 'black'}>
				{header}
			</Text>
			<LineBreak active={active} target={HeaderRef} size={4}/>
			{subHeader.length > 0 && (
				<Text className="typo-borna-55">
					{subHeader}
				</Text>
			)}
		</ListItemWrapper>
	)
}


const List = ({header: head = '', originId = '', ...props}) => {
	const {recapData} = useRecap()
	const list = recapData?.list || []
	const header = head || ''
	const [active, setActive] = useState('')

	const handlePointerEnter = useCallback((id) => {
		setActive(id)
	}, [])

	const handlePointerLeave = useCallback(() => {
		setActive('')
	}, [])

	return (
		<Wrapper {...props}>
			<Top header={header} active={active}/>
			<ListContainer>
				{list.map((item, index) => (
					<Item
						data={item}
						key={index}
						onPointerEnter={handlePointerEnter}
						onPointerLeave={handlePointerLeave}
						activeId={active}
					/>
				))}
			</ListContainer>
		</Wrapper>
	);
};

export default List;
