import {useEffect, useRef, useState} from "react";
import {isFunction} from "@/utils/utils";

export default function useHover({
	onMouseEnterCallback,
	onMouseLeaveCallback,
} = {}) {
	const [isHovered, setHovered] = useState(false);
	const ref = useRef(null);

	const handleMouseEnter = () => {
		setHovered(true)
		if (isFunction(onMouseEnterCallback)) {
			onMouseEnterCallback()
		}
	};
	const handleMouseLeave = () => {
		setHovered(false);
		if (isFunction(onMouseLeaveCallback)) {
			onMouseLeaveCallback()
		}
	}

	useEffect(() => {
		if (ref.current) {
			ref.current.addEventListener("mouseenter", handleMouseEnter);
			ref.current.addEventListener("mouseleave", handleMouseLeave);

		}

		return () => {
			if (ref.current) {
				ref.current.removeEventListener("mouseenter", handleMouseEnter);
				ref.current.removeEventListener("mouseleave", handleMouseLeave);
			}
		};
	}, []);

	return [ref, isHovered];
}