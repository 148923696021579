import GridContainer from "@/components/common/GridContainer";
import styled from "styled-components";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import React from "react";
import LogoImage from "@/assets/images/logo-pck.svg";
import Icon from "@/components/common/Icon/Icon";
import {ICONS, LOCALES} from "@/constants";
import Container from "@/components/common/Container";
import {useRouter} from "next/router";
import ExternalLink from "@/components/common/Links/ExternalLink";
import {getButtonLinkData} from "@/components/common/Buttons/Button";
import useGlobals from "@/hooks/useGlobals";

const Wrapper = styled.div`
    display: flex;
    background: ${({theme}) => theme.colors.richBlue};
    padding: var(--paddingSize) 0 var(--paddingSize) var(--paddingX);
    --paddingSize: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        --paddingSize: ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --paddingSize: ${getCssSizeValue(40)};
    }

    .credits {
        position: absolute;
        bottom: 0;
        right: var(--paddingX);
        display: block;
        color: ${({theme}) => theme.colors.slateGray};
        margin-bottom: ${getCssSizeValue(20)};
    }
`

const Logo = styled(PreserveSearchParamsLink)`
    width: var(--size);
    height: var(--size);
    aspect-ratio: 1;
    --size: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    margin-right: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};

    .image {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        --size: ${getCssSizeValue(120, BREAKPOINTS.TABLET)};
        margin-right: ${getCssSizeValue(42, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)};
        margin-right: ${getCssSizeValue(104, BREAKPOINTS.DESKTOP)};
    }
`;

const Slogan = styled.div`
    width: auto;
    display: flex;
    align-items: flex-end;
    translate: 0 ${getCssSizeValue(-16)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        translate: 0 ${getCssSizeValue(-16, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        translate: 0 ${getCssSizeValue(-16)};
        width: 100%;
    }

    .icon {
        height: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            height: ${getCssSizeValue(52, BREAKPOINTS.TABLET)};
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            width: 100%;
            height: initial;
        }

        svg {
            width: unset !important;

            @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
                width: 100% !important;
            }

            path {
                fill: ${props => props.theme.colors.antiflashWhite};
            }

            polygon {
                fill: ${props => props.theme.colors.chilliRed};

                &.white {
                    fill: ${props => props.theme.colors.antiflashWhite} !important;
                }
            }
        }
    }
`

function getIconNameBasedOnLocale(locale) {
	switch (locale) {
		case LOCALES.PL:
			return ICONS.FOOTER_SLOGAN_PL
		case LOCALES.EN:
			return ICONS.FOOTER_SLOGAN_EN
		default:
			return ICONS.FOOTER_SLOGAN_EN
	}
}


export default function LogoWithSlogan() {
	const {locale} = useRouter()
	const iconName = getIconNameBasedOnLocale(locale)
	const {footer} = useGlobals()
	const {text, newCard, url} = getButtonLinkData(footer?.credits || {})

	return (
		<Wrapper>
			<Logo href={`/`}>
				<LogoImage/>
			</Logo>
			<Slogan>
				<Icon name={iconName}/>
			</Slogan>
			<ExternalLink className='credits typo-strawford typo-strawford-123' newCard={newCard} href={url}>
				{text}
			</ExternalLink>
		</Wrapper>
	)
}