import React, {useRef} from 'react';
import styled from "styled-components";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField, getItemType, setCssVars} from "@/utils/utils";
import Reveal, {REVEAL_CLASSNAME} from "@/animations/Reveal";
import {CONTENT_TYPES} from "@/constants";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import useColor from "@/hooks/useColor";
import dynamic from "next/dynamic";
import SpotImageSecondHeader from "@/components/common/Header&Description/SpotImageSecondHeader";
import Image from "@/components/common/Image";
import DefaultHeader from "@/components/common/Header&Description/DefaultHeader";
import {BOX_VARIANTS} from "@/components/common/Header&Description/BoxDescription";

const BoxDescription = dynamic(() => import('./BoxDescription'));
const SingleColumnDescription = dynamic(() => import('./SingleColumnDescription'));
const DoubleColumnDescription = dynamic(() => import('./DoubleColumnDescription'));
const AudioDescription = dynamic(() => import('./AudioDescription'));
const ImageDescription = dynamic(() => import('./ImageDescription'));
const TightDescription = dynamic(() => import('./TightDescription'));
const TightHeader = dynamic(() => import('./TightHeader'));
const HeaderButton = dynamic(() => import('./HeaderButton'));
const FilesDescription = dynamic(() => import('./FilesDescription'));
const DescriptionButton = dynamic(() => import('./DescriptionButton'));

const VARIANTS = {
	SINGLE_COLUMN: 'single-column',
	TWO_COLUMN_BOX: 'single-column-box',
	DOUBLE_COLUMN: 'double-column',
	STRUCTURED_TEXT: 'structured',
	AUDIO: 'audio',
	IMAGE: 'image',
	SPOT_IMAGE: 'spot-image',
	ICONS_GRID: 'icons-grid',
	SINGLE_COLUMN_TIGHT: 'single-column-tight',
	FILES: 'files',
	BUTTON: 'button'
}

const DEFAULT = {
	COLOR: 'black',
	ANIMATION: false,
	INDENT: false,
	INDENT_TARGET: 'description',
	VARIANT: '',
	BORDER_COLOR: colors.antiflashWhite
}

function getIndentRowClass(indent, row){
	if(!indent) return ``
	return `indent-row-${row}`
}

const WrapperReveal = styled(Reveal)`
	position: relative;
    --local-indent: 2;
    --local-size: var(--size, 8);
	--border-color: ${({ theme }) => theme.colors.antiflashWhite};
	width: 100%;
    //grid-column: span var(--local-size);

    .${REVEAL_CLASSNAME}-child{
        display: grid;
        grid-template-columns: repeat(var(--local-size), 1fr);
        align-content: start;
		row-gap: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    }
    .header{
		display: flex;
        grid-column: span var(--local-size);
    }
    .description{
        grid-column: span var(--local-size);
		img{
			width: 100%;
		}
    } 
	&.image {
		border-top: none!important;
		--local-size: var(--size, 10);
		ul li {
			&:before {
				background: ${({theme}) => theme.colors.white};
			}
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			//padding-top: ${getCssSizeValue(120, BREAKPOINTS.DESKTOP)}!important;
		}
		.header {
			font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
			line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
			@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
				line-height: ${getFontSize(43.2, BREAKPOINTS.TABLET)};
				margin-bottom: 0;
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
				line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
				max-width: unset;
				margin-bottom: 0;
			}
		}
		.description {
			line-height: ${getFontSize(25.2, BREAKPOINTS.MOBILE)};
			@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
				line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
				//grid-column: 2/8;
				grid-column: var(--local-indent)/calc(var(--local-size));
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
				line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
			}
		}
	}

	&.icons-grid {
		--local-size: var(--size, 9);
		border-top: none!important;
		padding-top: ${getCssSizeValue( 45, BREAKPOINTS.MOBILE)} !important;
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			padding-top: ${getCssSizeValue( 135, BREAKPOINTS.TABLET)} !important;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			padding-top: ${({ theme }) => getCssSizeValue(theme.spacing.desktop.s)}!important;
		}
		.header {
			font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
			line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
			margin-bottom: ${getCssSizeValue( 35, BREAKPOINTS.MOBILE)};
			@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				font-size: ${getFontSize(36, BREAKPOINTS.TABLET)};
				line-height: ${getFontSize(43.2, BREAKPOINTS.TABLET)};
				max-width: 60vw;
				margin-bottom: ${getCssSizeValue( 30, BREAKPOINTS.TABLET)};
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
				line-height: ${getFontSize(69, BREAKPOINTS.DESKTOP)};
				max-width: unset;
				margin-bottom: 0;
			}
		}
		.description {
			line-height: ${getFontSize(25.2, BREAKPOINTS.MOBILE)};
			@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
				line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
				//grid-column: 2/8;
				grid-column: var(--local-indent)/calc(var(--local-size));
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
				line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
			}
		}
	}
	&.spot-image {
		--local-size: var(--size, 8);
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			--local-size: var(--size, 4);
			padding-right: ${getCssSizeValue(60, BREAKPOINTS.TABLET)};
		}
		border-top: none!important;
		.header {
			font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
			line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
			font-weight: ${({theme}) => theme.fontWeight.medium};
			@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
				font-size: ${getFontSize(46, BREAKPOINTS.TABLET)};
				line-height: ${getFontSize(55.2, BREAKPOINTS.TABLET)};
			}
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
				line-height: ${getFontSize(69, BREAKPOINTS.DESKTOP)};
			}
		}
	}
    &.border-top{
        border-top: solid 1px var(--border-color, currentColor);
        padding-top: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	}

    &.${VARIANTS.DOUBLE_COLUMN}{
        //background-color: red;
        --local-size: var(--size, 12);

        &.indent-row{
            &-description{
                .description{
                    //grid-column: span calc(var(--local-size) / 2);
                    grid-column: span calc(var(--local-size) / 1);
                }
            }
        }
    }

    &.${VARIANTS.TWO_COLUMN_BOX} {
        --local-size: var(--size, 16);

        .header {
            width: 100%;
            justify-content: start;
            grid-column-start: 1;
            grid-column-end: calc(var(--local-size) + 1);
        } 

        .description {
            grid-column: var(--local-indent)/var(--local-size);
        } 

        .box {
            overflow: visible;
            height: fit-content;

            > div {
                left: calc(-1 * var(--paddingX));
                position: relative;
                width: 100vw;
                padding: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)} ${getCssSizeValue(42, BREAKPOINTS.MOBILE)};
            }

            //transform: translateX(-20px);
            grid-column-start: 1;
            grid-column-end: calc(var(--local-size) + 1);

        }
    }
	
	&.${VARIANTS.BUTTON} {
		border-top: none !important;
		
		.header {
			font-weight: 500;
		}
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        &.${VARIANTS.TWO_COLUMN_BOX}{
            --local-size: var(--size, 16);
            .box {
                position: absolute;
                right: 0;
                top: ${getCssSizeValue(-16)};
                overflow: visible;
                > div{
					left: calc(var(--paddingX));
                    position: relative;
                    width: 27vw;
                    padding: ${getCssSizeValue(16, BREAKPOINTS.TABLET)} ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
                }
            }
        }
		&.${VARIANTS.DOUBLE_COLUMN}{
			--local-size: var(--size, 12);

			&.indent-row{
				&-description{
					.description{
						grid-column: span calc(var(--local-size) / 2);
					}
				}
			}
		}
	}	

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        .${REVEAL_CLASSNAME}-child {
            grid-gap: var(--grid-gap);
        }
		&.spot-image {
			--local-size: var(--size, 7);
			border-top: none!important;
			padding-right: 0;
		}
        &.indent-row{
            &-header{
                .header{
                    grid-column: var(--local-indent)/calc(1 + var(--local-size));
                }
            }
            &-description{
                .description{
                    grid-column: var(--local-indent)/calc(1 + var(--local-size));
					//background-color: red;
                }
            }
        }
		

        &.border-top{
            padding-top: ${({ theme }) => getCssSizeValue(theme.spacing.desktop.s)}
        }

        &.${VARIANTS.TWO_COLUMN_BOX}{
            --local-size: var(--size, 16);
            .description{
                grid-column: var(--local-indent)/calc(3 + var(--local-size) / 2);
            }
            .box {
				position: static;
				width: 100%;
                grid-column-start: calc(var(--local-size) - 3);
				>div{
                    padding: ${getCssSizeValue(32)};
                    padding-right: ${getCssSizeValue(24)};
					left: 0;
					width: 100%;
				}
            }
        }

        &.${VARIANTS.DOUBLE_COLUMN}{
            &.indent-row{
                &-description{
                    .description{
                        grid-column: span calc(var(--local-size) / 2);
                        &:first-of-type{
                            grid-column: var(--local-indent)/calc((var(--local-size) / 2) + 1);
                        }
                    }
                }
            }
        }
    }
`

const RightImageWrapper = styled.div`
	width: 50vw;
	position: absolute;
	right: 0;
	top: 0;
	aspect-ratio: 3/4;
	display: none;
	.image {
		width: 100%;
		aspect-ratio: 3/4;
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			aspect-ratio: 3/2;
		}
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		display: block;
		// padding-top: ${getCssSizeValue(70, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		aspect-ratio: 3/2;
		padding-top: 0;
	}
`

function getHeaderAndDescriptionVariant(typename) {
	switch (typename) {
		case CONTENT_TYPES.HEADER_AND_DESCRIPTION_TWO_COLUMN_BLOCK:
			return VARIANTS.DOUBLE_COLUMN
		case CONTENT_TYPES.HEADER_DESCRIPTION_BOX:
			return VARIANTS.TWO_COLUMN_BOX
		case CONTENT_TYPES.HEADER_DESCRIPTION_AND_AUDIO_BLOCK:
			return VARIANTS.AUDIO
		case CONTENT_TYPES.HEADER_DESCRIPTION_AND_IMAGE_BLOCK:
			return VARIANTS.IMAGE
		case CONTENT_TYPES.HEADER_DESCRIPTION_AND_SPOT_IMAGE_BLOCK:
			return VARIANTS.SPOT_IMAGE
		case CONTENT_TYPES.HEADER_DESCRIPTION_AND_ICONS_GRID_BLOCK:
			return VARIANTS.ICONS_GRID
		case CONTENT_TYPES.HEADER_AND_DESCRIPTION_TIGHT_BLOCK:
			return VARIANTS.SINGLE_COLUMN_TIGHT
		case CONTENT_TYPES.HEADER_AND_DESCRIPTION_FILES_BLOCK:
			return VARIANTS.FILES
		case CONTENT_TYPES.HEADER_AND_DESCRIPTION_BUTTON:
			return VARIANTS.BUTTON
		default:
			return VARIANTS.SINGLE_COLUMN
	}
}

function Header({ variant, data, color }) {
	const header = data?.header || ''

	switch (variant) {
		case VARIANTS.SINGLE_COLUMN_TIGHT:
			return <TightHeader color={color} header={header} />
		case VARIANTS.BUTTON:
			return <HeaderButton data={data} color={color}/>
		default:
			return <DefaultHeader color={color} header={header} />
	}
}

function Description({ variant, data, color }) {
	const description = data?.description || ''
	const description2 = data?.description2 || ''
	const boxText = data?.boxText || ''
	const files = data?.files || []
	const boxVariant = data?.boxVariant || BOX_VARIANTS.DEFAULT
	const redactor = getHtmlFromRedactorField(description)
	const redactor2 = getHtmlFromRedactorField(description2)
	const boxTextRedactor = getHtmlFromRedactorField(boxText)
	if(!redactor) {
		return null
	}
	switch (variant) {
		case VARIANTS.DOUBLE_COLUMN:
			if(!redactor2){
				return <SingleColumnDescription desc={redactor} color={color}/>
			}
			return <DoubleColumnDescription desc={redactor} desc2={redactor2} color={color}/>
		case VARIANTS.TWO_COLUMN_BOX:
			return <BoxDescription desc={redactor} boxText={boxTextRedactor} color={color} boxVariant={boxVariant}/>
		case VARIANTS.AUDIO:
			return <AudioDescription data={data} desc={redactor} color={color}/>
		case VARIANTS.IMAGE:
			return <ImageDescription data={data} desc={redactor} color={color}/>
		case VARIANTS.SPOT_IMAGE:
			return <SpotImageSecondHeader data={data} desc={redactor} color={color}/>
		case VARIANTS.ICONS_GRID:
			return <SingleColumnDescription desc={redactor} color={color}/>
		case VARIANTS.SINGLE_COLUMN_TIGHT:
			return <TightDescription desc={redactor} color={color} />
		case VARIANTS.FILES:
			return <FilesDescription desc={redactor} color={color} files={files} />
		case VARIANTS.BUTTON:
			return <DescriptionButton desc={redactor} color={color} />
		default:
			return <SingleColumnDescription desc={redactor} color={color}/>
	}
}

const HeaderDescription = ({
	data,
	className = '',
	color = DEFAULT.COLOR,
	animation: forceAnimation = DEFAULT.ANIMATION,
	indent = DEFAULT.INDENT, //enables internal indent
	indentTarget = DEFAULT.INDENT_TARGET, //which element should be indented
	variant: vt = DEFAULT.VARIANT,
	borderColor: bc = null,
	noDescription=false,
	...props
}) => {
	const typename = getItemType(data)
	const variant = vt || getHeaderAndDescriptionVariant(typename) //overwrite variant or define it by typename
	const WrapperRef = useRef()
	const indentClass = getIndentRowClass(indent, variant === VARIANTS.BUTTON ? 0 : indentTarget);
	const animation = data?.animation || false
	const enabled = forceAnimation || animation
	const borderColor = useColor(color === DEFAULT.COLOR ? 'antiflashWhite' : color) //always use typo color except default state
	const isSpotImageVariant = variant === VARIANTS.SPOT_IMAGE;
	useIsomorphicLayoutEffect(() => {
		setCssVars(WrapperRef.current, {
			['border-color']: bc ? bc : borderColor
		})
	},[borderColor])

	return (
		<>
			<WrapperReveal
				ref={WrapperRef}
				disabled={!enabled}
				onInView
				className={`header-and-description ${className} ${indentClass} ${variant}`}
				{...props}
			>
				<Header
					color={color}
					variant={variant}
					data={data}
				/>
				{!noDescription && (
					<Description
						data={data}
						variant={variant}
						color={color}
					/>
				)}
			</WrapperReveal>
			{isSpotImageVariant && <RightImageWrapper className='col-mobile-4 col-tablet-4 col-desktop-8'>
				<Image src={data?.spotImage?.url || ''} sizes="100%" fit="cover"/>
			</RightImageWrapper>}
		</>
	);
};

export default HeaderDescription;
