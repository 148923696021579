import React, {forwardRef} from 'react';
import styled from 'styled-components';
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

const StyledGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(var(--grid-count), 1fr);
	margin-left: var(--grid-margin-l);
	margin-right: var(--grid-margin-r);
    grid-gap: var(--grid-gap);
	//width: 100%;

    &.fixed-tester {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        padding: 0 ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        margin: 0;
        background-color: ${({theme}) => theme.colors.slateGray}
    }

	${[...new Array(16)].map((item, index) => {
		return `
            &.grid-${index + 1} {
                grid-template-columns: repeat(${index + 1}, 1fr);
            }
        `;
	})}

	&.grid-5 {
		grid-template-columns: repeat(2, 1fr);
		column-gap: 4px !important;
		row-gap: 3px !important;
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			grid-template-columns: repeat(3, 1fr);
			row-gap: 0 !important;
			column-gap: 0 !important;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			grid-template-columns: repeat(5, 1fr);
		}
	}
	
    &.no-margin {
        margin-left: 0;
        margin-right: 0;
    }    
	&.no-margin-desktop {
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-left: 0;
            margin-right: 0;
        }
    }
    &.no-margin-l{
        margin-left: 0;
    }
    &.no-margin-r{
        margin-right: 0;
    }
	
	&.padding{
        padding-left: var(--grid-margin-l);
        padding-right: var(--grid-margin-r);
	}
	
    &.padding-l{
        padding-left: var(--grid-margin-l);
    }
	
    &.padding-r{
        padding-right: var(--grid-margin-r);
    }
	
	
	&.no-gap{
		grid-gap: 0;
	}
	
	&.full-width{
		width: 100%;
	}
`

const GridContainer = forwardRef(function GridContainer({ style, className='', children, ...props}, ref) {
	return (
		<StyledGridContainer ref={ref} style={style} className={`grid-container ${className}`} {...props}>
			{children}
		</StyledGridContainer>
	);
});

export default GridContainer;
