import {useEffect, useMemo, useState} from "react";
import {v4} from 'uuid';
import {gsap, ScrollTrigger, useGSAP} from "@/utils/gsap";
import {isReactRef, isString} from "@/utils/utils";

const DEFAULT_PROPS = {
	id:'',
	pinnedContainer: undefined,
	disabled: false,
	once: false,
	trigger: {
		start: `top bottom`,
		end: `bottom top`
	},
	progress: null,
	markers: false,
	pin: false,
	pinSpacing: true,
	revert:true
}

export default function useInView(el = { current: null }, props=DEFAULT_PROPS ) {
	const passedId = props?.id || DEFAULT_PROPS.id
	const markers = props?.markers || DEFAULT_PROPS.markers
	const trigger = props?.trigger || DEFAULT_PROPS.trigger
	const once = props?.once || DEFAULT_PROPS.once
	const pinSpacing = props?.pinSpacing || DEFAULT_PROPS.pinSpacing
	const pin = props?.pin || DEFAULT_PROPS.pin
	const pinnedContainer = props?.pinnedContainer || DEFAULT_PROPS.pinnedContainer
	const revert = props?.revert || DEFAULT_PROPS.revert
	const progress = props?.progress || DEFAULT_PROPS.progress
	const disabled = props?.disabled || DEFAULT_PROPS.disabled

	const [target, setTarget] = useState(undefined)
	const [uuid] = useState(() => v4());
	const [inView, setInView] = useState(disabled);
	const [above, setAbove] = useState(false);
	const [under, setUnder] = useState(true);
	const [intersected, setIntersected] = useState(disabled);
	gsap.registerPlugin(ScrollTrigger);
	const memoProps = useMemo(() => ({
		id: `trigger-${passedId}-${uuid}`,
		// trigger: target,
		start: `${trigger.start}`,
		end: `${trigger.end}`,
		markers,
		// markers: true,
		pin,
		pinSpacing,
		once,
		// once: false,
		pinnedContainer: pinnedContainer?.current
	}),[once, passedId, markers, pin, trigger,pinSpacing])

	useEffect(() => {
		let tg = undefined
		if(isString(el)){
			tg = el
		}
		if(isReactRef(el)){
			tg = el.current
		}
		setTarget(tg)
	}, [el]);

	useGSAP(() => {
		if(!disabled){
			if(target){
				ScrollTrigger.create({
					trigger: target,
					...memoProps,
					invalidateOnRefresh: true,
					onEnter: () => {
						setAbove(false);
						setUnder(false);
						setIntersected(true)
					},
					onEnterBack: () => {
						setAbove(false);
						setUnder(false);
						setIntersected(true)
					},
					onLeave: () => {
						setAbove(true);
						setUnder(false);
					},
					onLeaveBack: () => {
						setAbove(false);
						setUnder(true);
					},
					onUpdate: ({ progress: stProgress, isActive }) => {
						setInView(isActive)
						if (progress) {
							progress.current = stProgress;
						}
					},
				});
			}
		}
	},{
		dependencies: [target, disabled, memoProps],
		revertOnUpdate: revert
	})

	return { inView, under, above, intersected };
}
