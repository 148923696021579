import React, {createRef, useEffect, useState} from 'react';
import {InstantSearch} from 'react-instantsearch';
import StyledSearchBox from './SearchBox';
import StyledSearchResult from './SearchResults';
import algoliasearch from 'algoliasearch/lite';
import styled from "styled-components";
import {useDispatch} from "react-redux";
import useClickOutside from "@/hooks/useClickOutside";
import {setIsSearchBarOpened} from "@/state/searchBarSlice";
import {BREAKPOINTS} from "@/styles/themeSettings";
import GridContainer from "@/components/common/GridContainer";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import useGlobals from "@/hooks/useGlobals";
import {useIsDesktop, useIsMobile, useIsTablet} from "@/hooks/useDevice";
import {useRouter} from "next/router";

const ALGOLIA_APP_ID = process.env.NEXT_ALGOLIA_APPLICATION_ID;
const ALGOLIA_ADMIN_API_KEY = process.env.NEXT_ALGOLIA_SEARCH_API_KEY;

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_ADMIN_API_KEY);

const indices = [
	{name: "global", label: "global"},
	{name: "dolnoslaskie", label: "dolnoslaskie"},
	{name: "kujawskoPomorskie", label: "kujawskoPomorskie"},
	{name: "lodzkie", label: "lodzkie"},
	{name: "lubelskie", label: "lubelskie"},
	{name: "lubuskie", label: "lubuskie"},
	{name: "malopolskie", label: "malopolskie"},
	{name: "mazowieckie", label: "mazowieckie"},
	{name: "opolskie", label: "opolskie"},
]

const StyledSearchRoot = styled.div`
	transition: opacity 0.3s ease-in-out -0.2s;
	opacity: 1;
	//position: absolute;
	//top: 0;
	//left:0;
	//border: 2px solid red;
	//height: 100%;
	//background-color: deeppink;
	//background-color: rebeccapurple;
	::-webkit-scrollbar {
		display: none;
	}
	 .ais-Hits-item {
		font-family: ${({theme}) => theme.fontFamily.StrawfordFont};
		box-shadow: none;
		border-bottom: 1px solid ${({theme}) => theme.colors.chineseViolet};
	 }
	&.visible {
		opacity: 1;
		transition: opacity 0.3s ease-in-out 0.4s;
		pointer-events: auto;
	}

	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		width: 100vw;
		border: none;
		position: absolute;
		margin-top: 0;
		top: ${getCssSizeValue(220, BREAKPOINTS.DESKTOP)};
		left: 0;
	}
	
`;

const HeaderWrapper = styled(GridContainer)`
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease-in-out;
	max-height: 0;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {

	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-top: ${getCssSizeValue(-100, BREAKPOINTS.DESKTOP)};
		margin-bottom: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
	}
	&.visible {
		opacity: 1;
		max-height: 100px;
		pointer-events: auto;
		transition: opacity 0.3s ease-in-out 0.1s;
	}
	p {
		color:${({theme}) => theme.colors.richBlack};
		font-family: ${({theme}) => theme.fontFamily.bornaFont};
		font-size: ${getFontSize(32, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
        line-height:${getFontSize(48, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(48, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape)  {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(48, BREAKPOINTS.DESKTOP)};
        }
	}
`

export default function SearchMain({ indices, onClick, searchIndex, handleCloseSearch, activeSite, className, isVisible, isDarkSearch, recapEnabled }) {
	const rootRef = createRef();
	const [query, setQuery] = useState();
	const [hasQuery, setHasQuery] = useState(false);
	const [hasFocus, setFocus] = useState(false);
	const dispatch = useDispatch();
	const globals = useGlobals();
	const resultsHeading = globals?.resultsHeading || '';
	const isDesktop = useIsDesktop();
	const isTablet = useIsTablet();
	const isMobile = useIsMobile();
	const router = useRouter()
	useClickOutside(rootRef, () => {
		setFocus(false)
	});
	useEffect(() => {
		if(isMobile && isVisible) {
			setFocus(true);
		}
	}, [isVisible, isMobile]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const keyDownHandler = (event) => {
				if (event.keyCode === 27) {
					dispatch(setIsSearchBarOpened(false));
				}
			};
			window.addEventListener('keydown', keyDownHandler);
			return () => window.removeEventListener('keydown', keyDownHandler);
		}
	}, []);

	const handleChange = (val) => {
		if(val) {
			setQuery(val);
			if(val !== '' && val !== 'undefined') {
				setHasQuery(true)
			} else {
				setHasQuery(false)
			}
		} else {
			setQuery('')
			setHasQuery(false)
		}
	};
	return (
		<>
			<StyledSearchRoot
				key={router.asPath}
				$isDarkSearch={isDarkSearch}
				className={className} ref={rootRef}>
				<InstantSearch
					searchClient={searchClient}
					indexName={searchIndex}
				>
					<StyledSearchBox
						recapEnabled={recapEnabled}
						onClick={onClick}
						// onChange={(query) => setQuery(query)}
						// onChange={(e) => handleChange(e)}
						onChange={(val) => handleChange(val)}
						hasFocus={hasFocus}
						isVisible={isVisible}
						handleCloseSearch={handleCloseSearch}
						isDarkSearch={isDarkSearch}
						isDesktop={isDesktop}
						isTablet={isTablet}
						query={query}
					/>
					<>
						{(isDesktop || isTablet) && <HeaderWrapper
							// className={isVisible && query && query !== '' && query !== 'undefined' ? 'visible' : ''}
							className={isVisible ? 'visible' : ''}
						>
							<p style={isDesktop ? { gridColumnStart: 4, gridColumnEnd: 17 } : {gridColumnStart: 1, gridColumnEnd: 9}}>{resultsHeading}</p>
						</HeaderWrapper> }
						<StyledSearchResult
							activeSite={activeSite}
							// hasQuery={query && query !== '' && query !== 'undefined'}
							hasQuery={hasQuery}
							show={isVisible}
							indices={'all_content_versions'}
							handleCloseSearch={handleCloseSearch}
						/>
					</>
				</InstantSearch>
			</StyledSearchRoot>
		</>
	)
}
