import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-20, &-20i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(16.8, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
        }
    }
    &-21, &-21i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
		//font-weight: ${({theme}) => theme.fontWeight.medium};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(16.8, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
        }
    }
    &-22, &-22i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: normal;
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-weight: ${({theme}) => theme.fontWeight.regular};
			font-size: ${getFontSize(30, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(45, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(30, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(45, BREAKPOINTS.DESKTOP)};
		}
    }
    &-23, &-23i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(18, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }
	&-24, &-24i {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(24, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(120, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(180, BREAKPOINTS.DESKTOP)};
        }
    }
    &-25, &-25i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
        }
    }
    &-26, &-26i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(40, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(52, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(40, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(52, BREAKPOINTS.DESKTOP)};
        }
    }
    &-27, &-27i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(31.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(64, BREAKPOINTS.DESKTOP)};
        }
    }
    &-28, &-28i {
        font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(20, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(41.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(41.6, BREAKPOINTS.DESKTOP)};
        }
    }
	&-29, &-29i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
        }
    }
`
