import React, {forwardRef} from 'react';
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import ExternalLink from "@/components/common/Links/ExternalLink";
import useScrollContext from "@/hooks/useScrollContext";
import {getIdWithoutSpecialSymbols} from "@/utils/utils";


const UniversalLink = forwardRef(function UniversalLink({
	external = false,
	href = '',
	newCard = 'no',
	children,
	onClick,
	isScrollTo = false,
	id,
	...props
}, fref) {
	const scroll = useScrollContext()

	const handleScroll = () => {
		const idWithoutSpecialSymbols = getIdWithoutSpecialSymbols(id);
		const el = document.getElementById(idWithoutSpecialSymbols)
		if (!el) return

		scroll.scrollTo({
			top: el,
			left: 0,
			behavior: "smooth"
		})
	}

	return external ? (
		<ExternalLink
			ref={fref}
			newCard={newCard}
			href={href}
			{...props}
		>
			{children}
		</ExternalLink>
	) : isScrollTo ? (
		<div ref={fref} onClick={handleScroll} {...props}>{children}</div>
	) : (
		<PreserveSearchParamsLink
			ref={fref}
			href={href}
			onClick={onClick}
			{...props}
		>
			{children}
		</PreserveSearchParamsLink>
	)

})

export default UniversalLink;