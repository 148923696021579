import styled from "styled-components";
import Text from "@/components/common/Text";
import Button from "@/components/common/Buttons/Button";
import React from "react";
import {getCssSizeValue} from "@/utils/utils";
import Image from "@/components/common/Image";
import {BREAKPOINTS} from "@/styles/themeSettings";

const ModalContent = styled.div`
    figure {
        min-height: auto !important;
    }
	
    .header {
        margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
		padding-top: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
		border-top: 1px solid ${({theme}) => theme.colors.antiflashWhite};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-bottom: ${getCssSizeValue(28)};
            padding-top: ${getCssSizeValue(16)};
        }
    }

    .button {
        margin-top: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-top: ${getCssSizeValue(80)};
        }
    }
`

const StyledImage = styled(Image)`
    height: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
    width: ${getCssSizeValue(80, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints.tablet || 768}px) {
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop || 1024}px) and (orientation: landscape) {
        height: ${getCssSizeValue(140)};
        width: ${getCssSizeValue(140)};
    }
`

export default function FormSubmissionModal({
	header = '',
	icon = { },
	closeButtonText = '',
	handleClose = () => {},
}) {
	return (
		<ModalContent>
			<Text className="typo-borna typo-borna-70 header">
				{header}
			</Text>
			<StyledImage
				src={icon?.url || ''}
				alt={icon?.alt || ''}
				sizes={'100vw'}
			/>
			<Button onClick={handleClose} className='button'>
				{closeButtonText}
			</Button>
		</ModalContent>
	)
}
