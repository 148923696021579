import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-120, &-120i {
        font-size: ${getFontSize(32, BREAKPOINTS.MOBILE)};
        line-height: 110%;
    	font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.medium};
            font-size: ${getFontSize(80, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(112, BREAKPOINTS.DESKTOP)};
        }
    }
	&-121, &-121i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(41.6, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-122, &-122i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(27, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(26, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(25, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(46.8, BREAKPOINTS.DESKTOP)};
        }
    } 
	
	&-123, &-123i {
        font-size: ${getFontSize(10, BREAKPOINTS.MOBILE)};
        line-height: normal;
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(13, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(12, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-124, &-124i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.medium};
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(30, BREAKPOINTS.DESKTOP)};
        }
    }
    &-125, &-125i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(34, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.medium};
			font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
		}
	}
	
	&-126, &-126i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(27, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)}; //unknown
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            //font-weight: ${({theme}) => theme.fontWeight.medium};
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(54, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-127, &-127i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(27, BREAKPOINTS.MOBILE)};
    	font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)}; //unknown
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.black};
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: 100%;
        }
    }
	&-128, &-128i {
    	font-weight: ${({theme}) => theme.fontWeight.medium};
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(23.4, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)}; //unknown
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.semiBold};
            font-size: ${getFontSize(25, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
    }
	&-129, &-129i {
    	font-weight: ${({theme}) => theme.fontWeight.bold};
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.semiBold};
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
        }
    }
`