const routeToTextItems = {
	'/': 'Home',
	'/history': 'History',
	'/news': 'News',
	'/about': 'About'
};

const mapRoutesToText = (route) => {
	let result = ''
	switch (true) {
		case route === '/':
			result = 'Home'
			break
		case route.includes('/history'):
			result = 'History'
			break
		case route.includes('/news'):
			result = 'News'
			break
		case route.includes('/about'):
			result = 'About'
			break
		default:
			result = 'PCK'
	}

	return result
}

export { mapRoutesToText }