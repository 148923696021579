import {Provider as Redux} from 'react-redux';
import Theme from "@/components/theme/Theme";
import Layout from "@/layout/Layout";
import {BranchProvider} from "@/hooks/useBranch";
import PageTransition from "@/components/pageTransition/PageTransition";
import Debug from "@/components/Debug";
import Seo from "@/components/SEO/SEO";
import store from '@/state/store';
import {ViewportProvider} from "@/hooks/useViewport";
import {DeviceProvider} from "@/hooks/useDevice";
import {ScrollProvider} from "@/hooks/useScrollContext";
import {GlobalsProvider} from "@/hooks/useGlobals";
import {RecapProvider} from "@/components/Recap/RecapProvider";
import {GoogleTagManager} from '@next/third-parties/google'

export default function App({Component, pageProps}) {
	const {
		menu,
		data,
		cookies,
		isDarkSearch,
		globals,
		isHomePage,
		isSingleWhatWeDoPage,
		isSingleBeWithUs,
		isSingleArticlePage,
		isBeWithUsCategory,
		isAnnouncementsAndTendersYearPage,
		isAnnouncementsAndTendersSlugPage,
		isFactsPage,
		isProductPage
	} = pageProps;

	return (
		<GlobalsProvider data={globals}>
			<DeviceProvider>
				<ViewportProvider>
					<BranchProvider>
						<ScrollProvider>
							<RecapProvider data={data}>
								<Redux store={store}>
									<Seo data={data}>
										<meta name="viewport" content="width=device-width, initial-scale=1"/>
										<link rel="icon" href="/favicon.svg"/>
									</Seo>
									<GoogleTagManager gtmId={process.env.GTM_ID}/>
									<Theme>
										<Debug/>
										<PageTransition>
											<Layout
												menu={menu}
												data={data}
												cookies={cookies}
												isDarkSearch={isDarkSearch}
												isHomePage={isHomePage}
												isSingleWhatWeDoPage={isSingleWhatWeDoPage}
												isSingleBeWithUs={isSingleBeWithUs}
												isSingleArticlePage={isSingleArticlePage}
												isBeWithUsCategory={isBeWithUsCategory}
												isAnnouncementsAndTendersYearPage={isAnnouncementsAndTendersYearPage}
												isAnnouncementsAndTendersSlugPage={isAnnouncementsAndTendersSlugPage}
												isFactsPage={isFactsPage}
												isProductPage={isProductPage}
											>
												<Component {...pageProps} />
												{/*<SpeedInsights/>*/}
											</Layout>
										</PageTransition>
									</Theme>
								</Redux>
							</RecapProvider>
						</ScrollProvider>
					</BranchProvider>
				</ViewportProvider>
			</DeviceProvider>
		</GlobalsProvider>
	)
}
