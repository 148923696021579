import React, {forwardRef} from 'react';
import {getLinkAriaLabel} from "@/components/common/Links/utils";
import styled from "styled-components";
import dynamic from 'next/dynamic'
import {extractButtonParams, extractButtonParamsFromClassName} from "@/components/common/Buttons/Button";
import {MODAL_VARIANT} from "@/constants";

const ModalButton = dynamic(() => import('@/components/common/Buttons/ModalButton'), { ssr: false });

const Anchor = styled.a`
`

export const NEW_CARD_VARIANT = {
	NO: 'no',
	YES: 'yes',
	MODAL: 'modal'
}

function getExternalLinkTarget(newCard){
	switch (newCard){
		case NEW_CARD_VARIANT.NO:
			return undefined
		case NEW_CARD_VARIANT.YES:
			return '_blank'
		case NEW_CARD_VARIANT.MODAL:
			return '_blank'
		default:
			return undefined
	}
}


const ExternalLink = forwardRef(function ExternalLink({ newCard = NEW_CARD_VARIANT.NO, href='', children, className='', ...props }, fref) {
	const { label, rest } = getLinkAriaLabel(props)
	const finalProps = extractButtonParams(rest)
	const buttonParams = extractButtonParamsFromClassName(className)
	const target = getExternalLinkTarget(newCard)
	const withModal = newCard === NEW_CARD_VARIANT.MODAL


	return withModal ? (
		<ModalButton
			ref={fref}
			modalVariant={MODAL_VARIANT.NEW_TAB}
			modalProps={{
				openLabel: label,
				href
			}}
			{...buttonParams}
			{...props}
		>
			{children}
		</ModalButton>
	) : (
		<Anchor
			ref={fref}
			target={target}
			href={href}
			aria-label={label}
			rel="noopener noreferrer"
			className={`${className} external-link`}
			{...finalProps}
		>
			{children}
		</Anchor>
	);
})

export default ExternalLink;
