import styled from "styled-components";
import {rangeConfigs} from "@/components/AccessibilityTool/constants";
import {Button} from "@/components/AccessibilityTool/index";

const RangeWrapper = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const RangeControls = ({decrementRangeValue, incrementRangeValue}) => {
	return (
		<>
			{rangeConfigs.map(({ label, cssVar, min, max, step, defaultValue }) => (
				<RangeWrapper key={cssVar} className="range-control">
					<Button onClick={() => incrementRangeValue(cssVar, min, step)}>-</Button>
					<label className='typo-borna' htmlFor={cssVar}>{label}</label>
					<Button onClick={() => decrementRangeValue(cssVar, max, step)}>+</Button>
				</RangeWrapper>
			))}
		</>
	);
};

export default RangeControls;