import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-60, &-60i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${props => props.theme.fontWeight.black};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(27, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		}
    }
    &-61, &-61i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.medium};
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(30, BREAKPOINTS.DESKTOP)};
        }
    }    
    &-62, &-62i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.regular};
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(33, BREAKPOINTS.DESKTOP)};
        }
    }    
    &-63, &-63i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(18, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: normal;
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
        }
    }
	&-64, &-64i {
        font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
	    line-height: ${getFontSize(30, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(57.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(57.6, BREAKPOINTS.DESKTOP)};
        }
    }
	&-65, &-65i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
		}
    }
	&-66, &-66i {
		font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(26, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(40, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(72, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
		}
    }
	&-67, &-67i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
    }
	&-68, &-68i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(18, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
        }
    }
	&-69, &-69i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(23.4, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(31.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(31.2, BREAKPOINTS.DESKTOP)};
        }
    }
`
