import {useIsMobile, useIsTablet} from "@/hooks/useDevice";
import {useMemo} from "react";

export default function useGrid(five=false) {
	const isMobile = useIsMobile()
	const isTablet = useIsTablet()
	return useMemo(() => {
		if(five) return 5
		if (isMobile) return 8
		if (isTablet) return 8
		return 16
	}, [isMobile, five, isTablet])
}
