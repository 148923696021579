export const colors = {
	black: 'rgba(0,0,0,1)',
	white: '#ffffff',
	platinum: '#e6e6e6', //grey
	antiflashWhite: '#EBEBEB', //basicGrey
	richBlue: '#091F2C', //darkBlue
	richBlack: '#051016', //darkDarkBlue
	richBlack60: 'rgba(5,16,22,0.6)', //darkDarkBlue60
	gunmetal: '#092D3B', //blue
	chilliRed: '#E41F13', //red
	pennRed: '#9F160D', //darkRed
	barnRed: '#72100A', //darkDarkRed
	barnRedHalfOpacity: 'rgba(114,16,10,0.5)', //darkDarkRedHalfOpacity
	periwinkle: '#D5C4F1', //lavenda
	chineseViolet: '#6B6279', //darkLavenda
	slateGray: '#838E95', //darkGrey
	//special colors
	indianRed: '#ec5853',
	pumpkin: '#FD7322',
	yellowGreen: '#9BC730',
	vistaBlue: '#98A4FF',
	xanthous: '#FCB339',
	peachYellow: '#F6DA9D',
	linen: '#f6eae1',
	paleDogwood: '#F8DACB',
	champagnePink: '#FEEAE0',
	whiteSmoke: '#F3F3F3',
	green: '#5EA775'
};

export const BREAKPOINTS = {
	LARGE_DESKTOP: 'large-desktop',
	DESKTOP: 'desktop',
	TABLET: 'tablet',
	MOBILE: 'mobile',
};

export const fontFamily = 	 {
	StrawfordFont: "'Strawford', sans-serif",
	bornaFont: "'Borna', sans-serif",
}

export const fontWeight = {
	thin: 100,
	extraLight: 200,
	light: 300,
	regular: 400,
	medium: 500,
	semiBold: 600,
	bold: 700,
	extraBold: 800,
	black: 900,
}

export const spacing = {
	[BREAKPOINTS.MOBILE]: {
		xs: 8,
		s: 16,
		m: 32,
		l: 40,
		xl: 56,
	},
	[BREAKPOINTS.TABLET]: {
		xs: 8,
		s: 16,
		m: 32,
		l: 40,
		xl: 56,
	},
	[BREAKPOINTS.DESKTOP]: {
		xs: 24,
		s: 32,
		m: 40,
		l: 80,
		xl: 136,
	},
}

export const theme = {
	fontFamily,
	fontWeight,
	spacing,
	breakpoints: {
		[BREAKPOINTS.TABLET]: 768,
		[BREAKPOINTS.DESKTOP]: 1024,
		[BREAKPOINTS.LARGE_DESKTOP]: 2560,
	},
	colors
}
