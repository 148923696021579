import {useContext} from "react";
import {isFunction, isString, isUndefined} from "@/utils/utils";

export default function useCustomContext(context, selector){
	const ct = useContext(context);
	if (isUndefined(ct)) {
		throw new Error(
			"Context is undefined"
		);
	}
	let target = ct
	if (isFunction(selector)) {
		target = selector(ct);
	}
	if(isString(selector)){
		const prop = ct[selector]
		if(isUndefined(prop)) {
			console.error(`"${selector}" prop is not part of this context, use one of existing props:`, ct)
		} else {
			target = prop
		}
	}
	return target
}
