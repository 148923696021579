import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	open: false,
};

export const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		setIsMenuOpen: (state, payload) => {
			state.open = payload.payload;
		},
	},
});

export const { setIsMenuOpen } = menuSlice.actions;

export default menuSlice.reducer;
