import {createSlice} from '@reduxjs/toolkit';

export const topBarSlice = createSlice({
	name: 'topBar',
	initialState: {
		sticky: false,
	},
	reducers: {
		setIsTopBarSticky: (state, action) => {
			state.sticky = action.payload;
		},
	},
});

export const { setIsTopBarSticky } = topBarSlice.actions;

export default topBarSlice.reducer;
