import React, {useRef} from 'react';
import useIsDebug from "@/hooks/useIsDebug";
import styled from "styled-components";
import GridContainer from "@/components/common/GridContainer";
import useIsDebug5Col from "@/hooks/useIsDebug5Col";
import useGrid from "@/hooks/useGrid";
import {useScroll} from "@/hooks/useScrollContext";
import Text from "@/components/common/Text";

const DebugGrid = styled(GridContainer)`
    position: fixed;
    inset: 0;
    z-index: 1000001;
    pointer-events: none;
`

const Stripe = styled.span`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.colors.chilliRed};
    opacity: 0.2;
	&.five {
		border: 1px solid white;
	}
`

const ScrollInfo = styled.div`
	display: flex;
	flex-direction: column;
	position: fixed;
    background-color: ${({theme}) => theme.colors.richBlue};
	padding: 15px;
	bottom: 2%;
	right: 2%;
	z-index: 1000000;
	color: white;
`

const Debug = () => {
	const isDebug = useIsDebug()
	const isDebug5Col = useIsDebug5Col();
	const length = useGrid(false)
	const length5Col = useGrid(true)
	const ScrollInfoRef = useRef()

	useScroll(({ scroll, direction, velocity, diff }) => {
		if(!(isDebug || isDebug5Col)) return
		const scrollParagraph = ScrollInfoRef.current.children[0]
		const directionParagraph = ScrollInfoRef.current.children[1]
		const velocityParagraph = ScrollInfoRef.current.children[2]
		const diffParagraph = ScrollInfoRef.current.children[3]
		scrollParagraph.innerHTML = `scroll: ${scroll}`
		directionParagraph.innerHTML = `direction: ${direction}`
		velocityParagraph.innerHTML = `velocity: ${velocity}`
		diffParagraph.innerHTML = `diff: ${diff}`
	})

	return(
		<>
			{isDebug && (
				<DebugGrid key="debug">
					{[...Array(length)].map((block, index) =>(
						<Stripe key={index} className="col-mobile-1 col-tablet-1 col-desktop-1"/>
					))}
				</DebugGrid>
			)}
			{isDebug5Col && (
				<DebugGrid className="grid-5 no-gap" key="debug">
					{[...Array(length5Col)].map((block, index) =>(
						<Stripe key={index} className="five col-mobile-1 col-tablet-1 col-desktop-1"/>
					))}
				</DebugGrid>
			)}
			{(isDebug || isDebug5Col) && (
				<ScrollInfo ref={ScrollInfoRef}>
					<Text>
						scroll:
					</Text>
					<Text>
						direction:
					</Text>
					<Text>
						velocity:
					</Text>
					<Text>
						diff:
					</Text>
				</ScrollInfo>
			)}
		</>
	)
};

export default Debug;
