import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	hasConsent: true,
};

export const cookiesSlice = createSlice({
	name: 'cookies',
	initialState,
	reducers: {
		setHasConsent: (state, payload) => {
			state.hasConsent = payload.payload;
		},
	},
});

export const { setHasConsent } = cookiesSlice.actions;

export default cookiesSlice.reducer;