import React from 'react';
import {Highlight} from 'react-instantsearch';
import styled from "styled-components";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";
import {setIsSearchBarOpened} from "@/state/searchBarSlice";
import SimpleIconButton from "@/components/common/SimpleIconButton";

const StyledSingleResultItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	cursor: pointer;
	position: relative;
	color: ${({theme}) => theme.colors.richBlack};
	padding: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)} ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	//border-bottom: 1px solid ${({theme}) => theme.colors.chineseViolet};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
		padding: ${getCssSizeValue(32, BREAKPOINTS.TABLET)} ${getCssSizeValue(32, BREAKPOINTS.TABLET)} ${getCssSizeValue(40, BREAKPOINTS.TABLET)};
		flex-direction: row;
		justify-content: space-between;
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		flex-direction: row;
		justify-content: space-between;
		padding: 0;
	}
	.icon{
		max-width: 40px;
	}
	mark {
		transition: all 0.3s ease-in-out;
		background-color: ${({theme}) => theme.colors.periwinkle};
		color: ${({theme}) => theme.colors.richBlack};
	}
	span.title {
		margin-bottom: 80px;
	}
	span.title span.ais-Highlight {
		background-image: linear-gradient(180deg,transparent 96%, #051016 0);
		background-size: 0 100%;
		background-repeat: no-repeat;
		text-decoration: none;
		transition: background-size 0.4s ease 0s;
	}
	&:hover {
		span.title span.ais-Highlight {
			transition: background-size 0.4s ease 0s;
			background-size: 100% 100%;
			mark {
				transition: all 0.3s ease-in-out;
				background-color: ${({theme}) => theme.colors.richBlack};
				color: ${({theme}) => theme.colors.periwinkle};
			}
		}
	}
	
`

const ResultElements = styled.div`
	width: 100%;
	//background-color: indianred;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		width: calc(100% - 200px);
	}
`

const VersionElement = styled.p`
	text-transform: uppercase;
	position: relative;
	//margin-top: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
	margin-right: ${getCssSizeValue(50, BREAKPOINTS.MOBILE)};
	width: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	margin-left:  ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		margin-left:  ${getCssSizeValue(22, BREAKPOINTS.TABLET)};
		min-width: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
		max-width: ${getCssSizeValue(88, BREAKPOINTS.TABLET)};
		margin-right: ${getCssSizeValue(48, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		width: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
		max-width: unset;
		min-width: unset;
		margin-bottom: 0;
		margin-left: 0;
		margin-right: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
	}
	&:before {
		content: '';
		position: absolute;
		left: ${getCssSizeValue(-15, BREAKPOINTS.MOBILE)};
		top:${getCssSizeValue(4, BREAKPOINTS.MOBILE)};
		width:${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
		height: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
		background-color: ${({theme}) => theme.colors.gunmetal};
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			left: ${getCssSizeValue(-16, BREAKPOINTS.TABLET)};
			top: ${getCssSizeValue(3, BREAKPOINTS.TABLET)};
			width: ${getCssSizeValue(8, BREAKPOINTS.TABLET)};
			height: ${getCssSizeValue(8, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			left: ${getCssSizeValue(-15, BREAKPOINTS.DESKTOP)};
			top:${getCssSizeValue(4, BREAKPOINTS.DESKTOP)};
			width:${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
			height: ${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
		}
	}
`

const ArrowContainer = styled.div`
	//width: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
	//display: none;
	position: absolute;
	top: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	right: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-end;
	//background-color: red;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		position: relative;
		right: unset;
		top: unset;
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		width: ${getCssSizeValue(100, BREAKPOINTS.DESKTOP)};
		position: unset;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-end;
	}
`

const ArrowWrapper = styled.div`
	height: 32px;
	width: 32px;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		height: 40px;
		width: 41px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		height: 40px;
		width: 41px;
		display: block;
	};
	.icon {
		max-width: 100px;
		max-height: 100px;
	}
	svg {
		width: 100%;
		height: 100%;
	}
`

const SingleResultItem = ({hit}) => {
	const router = useRouter();
	const dispatch = useDispatch()
	const handleResultClick = (e,slug) => {
		e.stopPropagation()
		router.push(slug);
		dispatch(setIsSearchBarOpened(false))
	}
	return (
		<StyledSingleResultItem className='typo-strawford typo-strawford-76' onClick={(e) => handleResultClick(e,hit.slug)}>
			<VersionElement className='typo-strawford typo-strawford-33'>{hit.version}</VersionElement>
			<ResultElements>
				{hit.title && <span className='title typo-borna typo-borna-75'>
					<Highlight attribute="title" hit={hit}/>
					<br/>
				</span>}
				{hit.header && (
					<>
						<Highlight attribute="header" hit={hit}/>
						<br/>
					</>
				)}
				{hit.description && (
					<>
						<Highlight attribute="description" hit={hit}/>
						<br/>
					</>
				)}
				{hit.content && (
					<>
						<Highlight attribute="content" hit={hit}/>
						<br/>
					</>
				)}
				{hit.motto && (
					<>
						<Highlight attribute="motto" hit={hit}/>
						<br/>
					</>
				)}
			</ResultElements>
			<ArrowContainer>
				<ArrowWrapper>
					<SimpleIconButton color={colors.chilliRed} rotation={135} />
				</ArrowWrapper>
			</ArrowContainer>
		</StyledSingleResultItem>
	);
};

export default SingleResultItem;