import React, {forwardRef, useMemo} from 'react';
import Button, {getButtonLinkData} from "@/components/common/Buttons/Button";

const LinkButton = forwardRef(
	function LinkButton({
		url='',
		external=undefined,
		newCard=undefined,
		label=undefined,
		data,
		variant='',
		type='',
		font='',
		text='',
		typo=undefined,
		children,
		...props
	}, fref) {
		const button = useMemo(() => {
			return ({
				id: data?.id || '',
				...getButtonLinkData(data || {})
			})
		}, [data])

		return (
			<Button
				as="link"
				ref={fref}
				variant={variant}
				type={type}
				typo={typo}
				font={font}
				key={button.id}
				href={url || button.url}
				external={external || button.isExternal}
				newCard={newCard || button.newCard}
				aria-label={label || button.ariaLabel}
				{...props}
			>
				{text || button.text}
				{children}
			</Button>
		);
	});

export default LinkButton;
