import React, {createContext, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {useRouter} from "next/router";
import Cookies from "@/components/Cookies/Cookies";
import Footer from "@/components/footer/Footer";
import AccessibilityTool from "@/components/AccessibilityTool";
import TopBar from "@/components/topBar/TopBar";
import BreadCrumbs from "@/components/breadCrumbs/BreadCrumbs";
import {useDispatch, useSelector} from "react-redux";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {setIsSearchBarOpened} from "@/state/searchBarSlice";
import useCustomContext from "@/hooks/useCustomContext";
import BranchInformator from "@/components/BranchInformator";

const Main = styled.main`
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const StyledSearchBlendWrapper = styled.div`
    align-items: flex-start;
    background-color: ${({theme}) => theme.colors.richBlack60};
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    left: 0;
    position: fixed;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s ease-in-out 0.3s;
    width: 100%;
    z-index: 20;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        //padding: 40px 50px;
		padding-bottom: 20vh;
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        //padding: 80px;
        padding-bottom: 20vh;
        display: flex;
        position: fixed;
        top: 0;
        bottom: unset;
        justify-content: flex-end;
    }

    &.visible {
        transform: scaleY(1);
        transition: transform 0.3s ease-in-out;
    }
`;

const StyledInnerSearchWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.colors.antiflashWhite};
    //padding: 80px 56px;
    .ais-Hits-item {
        width: 100%;
        border-bottom: 1px solid ${({theme}) => theme.colors.darkLavenda};
    }
`

const transformMenuData = (menu) => {
	if(menu) {
		const menuMap = new Map();
		menu.forEach(item => {
			if(item.text === 'Home') return
			menuMap.set(item.universalLink.text, { ...item, children: [] });
		});


		menu.forEach(item => {
			if (item.parent) {
				const parentItem = menuMap.get(item.parent.universalLink.text);
				parentItem.children.push(menuMap.get(item.universalLink.text));
			}
		});
		return Array.from(menuMap.values()).filter(item => !item.parent);
	}
	return [];

};

const collectivePagesMenuData = (menu) => {
	if(menu) {
		return menu.filter((item) => item.hasCollectiveTiles);
	}
	return[];
}


const INIT_CONTEXT = {
	menuData: []
}

const TransformedMenuContext = createContext(INIT_CONTEXT);

function Layout({ children, data, menu, isDarkSearch, isHomePage, isSingleWhatWeDoPage, isSingleBeWithUs, isSingleArticlePage, isBeWithUsCategory, isAnnouncementsAndTendersYearPage, isAnnouncementsAndTendersSlugPage, isFactsPage, isProductPage, ...props}) {
	const transformedMenuData = useMemo(() => transformMenuData(menu),[menu])
	const collectivePagesMenuDataArray = useMemo(() => collectivePagesMenuData(transformedMenuData),[transformedMenuData])
	const router = useRouter();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const dispatch = useDispatch();
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);


	const context = useMemo(() => ({
		menuData: collectivePagesMenuDataArray
	}),[collectivePagesMenuDataArray]);
	useEffect(() => {
		if(isMenuOpen) {
			setIsMenuOpen(false)
		}
		if(isSearchBarVisible) {
			dispatch(setIsSearchBarOpened(false))
		}
	},[router])

	const handleWrapperClick = () => {
		dispatch(setIsSearchBarOpened(false))
	};

	const handleInnerClick = (event) => {
		event.stopPropagation();
	};
	return(
		<Main>
			<TopBar menu={transformedMenuData} isDarkSearch={isDarkSearch} data={data} />
			<StyledSearchBlendWrapper onClick={handleWrapperClick} className={isSearchBarVisible ? 'visible' : ''}>
				<StyledInnerSearchWrapper onClick={handleInnerClick}>
				</StyledInnerSearchWrapper>
			</StyledSearchBlendWrapper>
			<Cookies />
			<TransformedMenuContext.Provider value={context}>
				{children}
			</TransformedMenuContext.Provider>
			<AccessibilityTool />
			{!isHomePage &&
				<BreadCrumbs
					data={data}
					menuData={transformedMenuData}
					menu={menu}
					isSingleWhatWeDoPage={isSingleWhatWeDoPage}
					isSingleBeWithUs={isSingleBeWithUs}
					isSingleArticlePage={isSingleArticlePage}
					isBeWithUsCategory={isBeWithUsCategory}
					isAnnouncementsAndTendersYearPage={isAnnouncementsAndTendersYearPage}
					isAnnouncementsAndTendersSlugPage={isAnnouncementsAndTendersSlugPage}
					isFactsPage={isFactsPage}
					isProductPage={isProductPage}
				/>}
			<Footer/>
			<BranchInformator key="branch-informator"/>
		</Main>
	)
}

export function useTransformedMenuContext(selector=undefined){
	return useCustomContext(TransformedMenuContext, selector)
}

export default Layout;
