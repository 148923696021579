import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import Portal from "@/components/common/Portals/Portal";
import {AnimatePresence, motion} from "framer-motion";
import {isFunction} from "@/utils/utils";

const Span = styled.span`
	cursor: pointer;
`

export const TOOLTIP_POSITION = {
	TOP: 'top',
	TOP_START: 'top-start',
	TOP_END: 'top-end',
	BOTTOM: 'bottom',
	BOTTOM_START: 'bottom-start',
	BOTTOM_END: 'bottom-end',
	RIGHT: 'right',
	RIGHT_START: 'right-start',
	RIGHT_END: 'right-end',
	LEFT: 'left',
	LEFT_START: 'left-start',
	LEFT_END: 'left-end'
};

const TooltipContentWrapper = styled(motion.div)`
    position: absolute;
    pointer-events: none;
	z-index: 1;
`;

const calculateTooltipPosition = (triggerRef, toolTipSizes, position, spacing) => {
	const triggerRect = triggerRef.current.getBoundingClientRect();

	const { top, left, width, height } = triggerRect;
	const tooltipHeight = toolTipSizes?.height || 0;
	const tooltipWidth = toolTipSizes?.width || 0;

	switch (position) {
		case TOOLTIP_POSITION.TOP:
			return { top: top + window.scrollY - tooltipHeight - spacing, left: left + window.scrollX + (width / 2) - (tooltipWidth / 2) };
		case TOOLTIP_POSITION.TOP_START:
			return { top: top + window.scrollY - tooltipHeight - spacing, left: left + window.scrollX };
		case TOOLTIP_POSITION.TOP_END:
			return { top: top + window.scrollY - tooltipHeight - spacing, left: left + window.scrollX + width - tooltipWidth };
		case TOOLTIP_POSITION.BOTTOM:
			return { top: top + height + spacing + window.scrollY, left: left + window.scrollX + (width / 2) - (tooltipWidth / 2) };
		case TOOLTIP_POSITION.BOTTOM_START:
			return { top: top + height + spacing + window.scrollY, left: left + window.scrollX };
		case TOOLTIP_POSITION.BOTTOM_END:
			return { top: top + height + spacing + window.scrollY, left: left + window.scrollX + width - tooltipWidth };
		case TOOLTIP_POSITION.RIGHT:
			return { top: top + window.scrollY + (height / 2) - (tooltipHeight / 2), left: left + width + spacing + window.scrollX };
		case TOOLTIP_POSITION.RIGHT_START:
			return { top: top + window.scrollY, left: left + width + spacing + window.scrollX };
		case TOOLTIP_POSITION.RIGHT_END:
			return { top: top + window.scrollY + height - tooltipHeight, left: left + width + spacing + window.scrollX };
		case TOOLTIP_POSITION.LEFT:
			return { top: top + window.scrollY + (height / 2) - (tooltipHeight / 2), left: left - tooltipWidth - spacing + window.scrollX };
		case TOOLTIP_POSITION.LEFT_START:
			return { top: top + window.scrollY, left: left - tooltipWidth - spacing + window.scrollX };
		case TOOLTIP_POSITION.LEFT_END:
			return { top: top + window.scrollY + height - tooltipHeight, left: left - tooltipWidth - spacing + window.scrollX };
		default:
			return { top: top + window.scrollY, left: left + width + spacing + window.scrollX };
	}
};

const TOOLTIP_TRIGGER_ELEMENT_CLASSNAME = 'tooltip-trigger-element-classname'
const TOOLTIP_ELEMENT_CLASSNAME = 'tooltip-element-classname'

const BaseToolTip = ({
	children,
	wrapperId = "tooltip-portal-wrapper",
	TooltipContent = JSX.Element,
	position = TOOLTIP_POSITION.RIGHT,
	visibleDuration = 800,
	spacing = 8,
	onClick = () => {},
	toolTipSizes = {
		height: 40,
		width: 120
	},
	hover = false,
	props
}) => {
	const [visible, setVisible] = useState(false);
	const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
	const triggerRef = useRef(null);

	const updateVisibilityAndPosition = () => {
		if (!triggerRef.current || visible) return;

		setVisible(true);

		const newPosition = calculateTooltipPosition(triggerRef, toolTipSizes, position, spacing);
		setTooltipPosition(newPosition);
	};

	const toggleTooltip = () => {
		updateVisibilityAndPosition()

		setTimeout(() => {
			setVisible(false);
		}, visibleDuration);
	};

	const handleClick = () => {
		if (hover) return

		toggleTooltip()
		if (isFunction(onClick)) {
			onClick()
		}
	}

	const handleMouseEnter = () => {
		if (!hover) return
		updateVisibilityAndPosition()
	}

	const handleMouseLeave = () => {
		if (!hover) return
		setVisible(false)
	}

	return (
		<>
			<Span
				ref={triggerRef}
				onClick={handleClick}
				className={TOOLTIP_TRIGGER_ELEMENT_CLASSNAME}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				{...props}
			>
				{children}
			</Span>
			<AnimatePresence mode='wait'>
				{visible && (
					<Portal wrapperId={wrapperId} appendClass=''>
						<TooltipContentWrapper
							style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
							className={TOOLTIP_ELEMENT_CLASSNAME}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.3 }}
						>
							{TooltipContent}
						</TooltipContentWrapper>
					</Portal>
				)}
			</AnimatePresence>
		</>
	);
};

export default BaseToolTip;
