import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-130, &-130i {
		font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		line-height:${getFontSize(29.4, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(44, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(53.2, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(42, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(65, BREAKPOINTS.DESKTOP)};
		}
    }
`