import React, {createContext, useEffect, useMemo, useState} from 'react';
import useMediaQuery from "@/hooks/useMediaQuery";
import {theme} from "@/styles/themeSettings";
import useCustomContext from "@/hooks/useCustomContext";
import {isMobile as isMobileDetect} from 'react-device-detect';
import {DEVICE_TYPES} from "@/constants";

const INIT_CONTEXT = {
	isDesktop: true,
	isTablet: false,
	isMobile: false,
	device: DEVICE_TYPES.DESKTOP,
	orientation: 'landscape'
}
const DeviceContext = createContext(INIT_CONTEXT);
export const DeviceProvider = ({ children }) => {
	const [isMobileDevice, setMobileDevice] = useState(false);
	const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.tablet - 1}px)`);
	const isTablet = useMediaQuery(`(min-width:${theme.breakpoints.tablet}px)`)
	const isSimpleDesktop = useMediaQuery(`(min-width:${theme.breakpoints.desktop}px)`);
	const isLandscape = useMediaQuery(`(orientation: landscape)`);
	const isDesktop = useMemo(() => isSimpleDesktop && isLandscape,[isLandscape, isSimpleDesktop])


	useEffect(() => {
		setMobileDevice(isMobileDetect);
	}, []);

	const context = useMemo(() => {
		return ({
			isDesktop,
			isTablet: isTablet && !isDesktop,
			isMobile,
			device: isMobileDevice ? DEVICE_TYPES.MOBILE: DEVICE_TYPES.DESKTOP,
			orientation: isLandscape ? 'landscape' : 'portrait'
		})
	},[isMobile,isTablet,isLandscape, isMobileDevice, isDesktop])

	return (
		<DeviceContext.Provider value={context}>
			{children}
		</DeviceContext.Provider>
	)
}

export default function useDevice(selector) {
	return useCustomContext(DeviceContext, selector)
}

export function useIsDesktop(deviceCheck=true){
	const isDesktopQuery = useDevice('isDesktop')
	const device = useDevice('device')
	// console.log(device);
	if(!deviceCheck) return isDesktopQuery
	return isDesktopQuery && device !== DEVICE_TYPES.MOBILE
}
export function useIsTablet(deviceCheck=false) {
	const isTabletQuery = useDevice('isTablet')
	const device = useDevice('device')
	if(!deviceCheck) return isTabletQuery
	return isTabletQuery && device === DEVICE_TYPES.MOBILE
}
export function useIsMobile(deviceCheck=false) {
	const isMobileQuery = useDevice('isMobile')
	const device = useDevice('device')
	if(!deviceCheck) return isMobileQuery
	return isMobileQuery && device === DEVICE_TYPES.MOBILE
}
