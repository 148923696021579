import styled from "styled-components";
import {getCssSizeValue, isStringEmpty} from "@/utils/utils";
import {useGSAP} from "@gsap/react";
import {ScrollTrigger, gsap} from "@/utils/gsap";
import {useCallback, useEffect, useRef, useState} from "react";
import useRecap from "@/components/Recap/RecapProvider";
import useScrollContext from "@/hooks/useScrollContext";
import {useRouter} from "next/router";
import {useSelector} from "react-redux";

const StyledWrapper = styled.div`
    background: ${({theme}) => theme.colors.white};
    display: flex;
    align-items: center;
    gap: ${getCssSizeValue(48)};
    transform-origin: center right;
    overflow: hidden;
    width: 0;
`

const ListItemWrapper = styled.a`
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    flex-direction: column;

    &:hover {
        color: ${({theme}) => theme.colors.chilliRed};
    }

    transition: color var(--rgr-duration) var(--ease);
`

export default function TopBarRecap({recapData = {}}) {
	const router = useRouter()
	const scroll = useScrollContext()
	const {searchWrapper} = useRecap()
	const wrapperRef = useRef(null)
	const {recapData: data} = useRecap()
	const list = data?.list || []
	const animationTl = useRef(gsap.timeline({ paused: true }))
	const [isAfterThreshold, setIsAfterThreshold] = useState(false);

	useGSAP(() => {
		if (!data?.topBarRecapEnabled || !searchWrapper.current || !router.isReady) return

		animationTl.current
			.to(['.be-with-us', searchWrapper.current], {
				y: -150,
				ease: 'power1.inOut',
			})
			.to(wrapperRef.current, {
				width: '100%',
				paddingRight: 48,
				paddingLeft: 48,
				marginRight: 14,
				duration: list.length * 0.1,
				ease: 'power1.inOut',
			});

		ScrollTrigger.create({
			trigger: '.recap',
			start: `top top`,
			markers: false,
			animation: animationTl.current,
			toggleActions: "play none none reverse",
			onEnter: () => {
				setIsAfterThreshold(true)
			},
			onLeaveBack: () => {
				setIsAfterThreshold(false)
			}
		});
	}, {dependencies: [router.asPath, data, searchWrapper.current, router.isReady]});

	const isMenuOpen = useSelector((state) => state.menu.open);

	useEffect(() => {
		isMenuOpen ? animationTl.current.timeScale(1.5).reverse() : isAfterThreshold && animationTl.current.timeScale(1.5).play();
	}, [isMenuOpen]);

	const handleOnClick = useCallback((targetId) => {
		const target = document.getElementById(targetId)
		if (target) {
			scroll.scrollTo({
				top: target,
				offset: -80,
				behavior: 'smooth'
			})
		}

	}, [scroll, data])

	const doAnyHeaderHasValue = list.some(item => !isStringEmpty(item?.recap?.topBarRecapHeader || ''))

	if (!doAnyHeaderHasValue) {
		return null
	}

	return <StyledWrapper
		ref={wrapperRef}
		key={router.asPath}
	>
		{list.map((item, index) => {
			const key = item?.id || index
			const topBarRecapHeader = item?.recap?.topBarRecapHeader || ''
			const targetId = item?.id || ''
			return !isStringEmpty(topBarRecapHeader) ? <ListItemWrapper
				key={key}
				onClick={() => handleOnClick(targetId)}
				className='typo-strawford typo-strawford-35'
			>
				{topBarRecapHeader}
			</ListItemWrapper> : null
		})}
	</StyledWrapper>
}