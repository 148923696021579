import {getCalendarDateRange} from "@/components/Calendar/utils";

export const CMS_ELEMENT_TYPENAME = '__typename'
export const EASE = [0.16, 1, 0.3, 1];
// export const EASE = [0.47, 0.75, 0.7, 0.88];

export const ICONS = {
	ACCESSIBILITY: 'accessibility',
	ARROW_DIAGONAL: 'arrow-diagonal',
	ARROW_DOWN: 'arrow-down',
	ARROW_LEFT: 'arrow-left',
	ARROW_RIGHT: 'arrow-right',
	ARROW_UP: 'arrow-up',
	ATTACHMENT: 'attachment',
	CALENDAR: 'calendar',
	CHEVRON: 'chevron',
	CITY: 'city',
	CLOSE: 'close',
	CONTACT: 'contact',
	COPY: 'copy',
	CROSS: 'cross',
	CROSS_FAT: 'cross-fat',
	DOWNLOAD: 'download',
	FACEBOOK: 'fb',
	FALLING_CROSS: 'falling-cross',
	FOOTER_SLOGAN_EN: 'footer-slogan-en',
	FOOTER_SLOGAN_PL: 'footer-slogan-pl',
	GRID: 'grid',
	HOME: 'home',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	LIST: 'list',
	LOUPE: 'loupe',
	MAP: 'map',
	MENU: 'menu',
	NAME: 'name',
	PAY: 'pay',
	PAYMENTS_SLOGAN_EN: 'payments-slogan-en',
	PAYMENTS_SLOGAN_PL: 'payments-slogan-pl',
	PHONE: 'phone',
	PIN_MAP: 'pin-map',
	PILL: 'pill',
	PLAY: 'play',
	RESET: 'reset',
	SNAP: 'snap',
	STATES_MAP: 'states-map',
	STREET: 'street',
	SURNAME: 'surname',
	TIME: 'time',
	TRIANGLE: 'triangle',
	TWITTER: 'twitter',
	WHAT_WE_DO: 'what-we-do',
	YOUTUBE: 'yt',
	ZEPSUJ: 'zepsuj',
	ZIP_CODE: 'zip-code',
	MINUS: 'minus',
	COOKIE: 'cookie'
};

export const CONTENT_TYPES = {
	WHAT_WE_DO_PREVIEW: 'WhatWeDoPreviewRecord',
	NEWS_THUMBNAIL: 'NewsThumbnailRecord',
	RULES_TILES: 'RulesTilesModuleRecord',
	GLOBAL_BASED_BANNER: 'GlobalBasedBannerRecord',
	DONOR_STORIES: 'DonorStoriesModelRecord',
	EXPANDABLE_VIDEO: 'ExpandableVideoPreviewRecord',
	DOWNLOAD_FILES: 'DownloadFileRecord',
	IMAGE: 'ImageRecord',
	LARGE_IMAGE: 'LargeImageRecord',
	HIGHLIGHTED_HEADING_AND_DESCRIPTION: 'HighlightedHeadingAndDescriptionRecord',
	MEDIUM_IMAGE: 'MediumImageRecord',
	VIDEO: 'VideoRecord',
	MARGIN: 'MarginRecord',
	GALLERY: 'GalleryRecord',
	SINGLE_PAGE: 'SinglePageRecord',
	NEWS: 'NewsRecord',
	TABS_AND_IMAGE: 'TabsAndImageModulePreviewRecord',
	TEXT_AND_DESCRIPTION_WITH_BUTTON: 'TextAndDescriptionWithButtonRecord',
	STATES_MAP: 'StatesMapRecord',
	CONTACT_FORM: 'ContactFormModuleRecord',
	CONTACT_FORM_PREVIEW: 'ContactFormPreviewModuleRecord',
	CALENDAR: 'CalendarRecord',
	FAQ_PREVIEW: 'FaqPreviewRecord',
	STRUCTURED_TEXT_MODULE: 'StructuredTextModuleRecord',
	SOCIAL_MEDIA_FEED: 'SocialMediaFeedRecord',
	DESCRIPTION_WITH_IMAGE: 'DescriptionWithImageModuleRecord',
	RECAP_SUMMARY_ITEM: 'RecapSummaryItemRecord',
	ALL_NEWS_PAGE: 'AllNewsPageRecord',
	WHAT_WE_DO_SINGLE_ACTION: 'WhatWeDoSingleActionRecord',
	WHAT_WE_DO_CATEGORY: 'WhatWeDoCategoryRecord',
	BE_WITH_US_PAGE: 'BeWithUsPageRecord',
	SINGLE_BE_WITH_US_PAGE: 'SingleBeWithUsPageRecord',
	HEADER_AND_DESCRIPTION_MODULE: 'HeaderDescriptionModuleRecord',
	HEADER_AND_DESCRIPTION_BLOCK: 'HeaderDescriptionRecord',
	HEADER_AND_DESCRIPTION_TIGHT_BLOCK: 'HeaderDescriptionTightRecord',
	HEADER_AND_DESCRIPTION_TWO_COLUMN_BLOCK: 'HeaderDescriptionTwoColumnRecord',
	HEADER_AND_DESCRIPTION_FILES_BLOCK: 'HeaderDescriptionFilesBlockRecord',
	HEADER_AND_DESCRIPTION_BUTTON: 'HeaderDescriptionButtonRecord',
	STATS: 'StatsModuleRecord',
	STATISTIC: 'StatisticRecord',
	STATISTIC_GROUP: 'StatisticGroupRecord',
	BANNER_CALL_TO_ACTION_MODULE: 'BannerCallToActionRecord',
	BANNER_CALL_TO_ACTION_MODULE_PREVIEW: 'BannerCallToActionModulePreviewRecord',
	IMAGE_WITH_CAPTION: 'ImageWithCaptionRecord',
	PAYMENT_MODULE: 'PaymentModuleRecord',
	TEAM_MODULE: 'TeamModuleRecord',
	PARTNERS: 'PartnersModuleRecord',
	PARALLAX_IMAGE_AND_DESCRIPTION: 'ParallaxImageAndDescriptionRecord',
	SINGLE_PAYMENT: 'SinglePaymentRecord',
	ANNOUNCEMENTS_AND_TENDERS_YEARS: 'AnnouncementsAndTendersYearRecord',
	EXPANDING_LIST_AND_DESCRIPTION: 'ExpandingListAndDescriptionRecord',
	TABLE_MODULE: 'TableModuleRecord',
	TABLE_PREVIEW_MODULE: 'TableModulePreviewRecord',
	PARALLAX_SLIDER: 'ParallaxSliderRecord',
	GALLERY_MODULE: 'GalleryModuleRecord',
	HIGHLIGHTED_HEADING: 'HighlightedHeadingRecord',
	EXTERNAL_LINK: 'ExternalLinkRecord',
	LOCAL_LINK: 'LocalLinkRecord',
	SCROLL_LINK_RECORD: 'ScrollLinkRecord',
	MENU: 'MenuRecord',
	TILES_MODULE: 'TilesModuleRecord',
	TILES_BLOCK_SIMPLE: 'TilesBlockSimpleRecord',
	TILES_BLOCK_IMAGE_HEADER_DESCRIPTION: 'TilesBlockImageHeaderDescriptionRecord',
	TILES_BLOCK_IMAGE_HEADER_TEXT_BUTTON: 'TilesBlockImageHeaderTextButtonRecord',
	TILES_BLOCK_RECTANGLE_GRID_ADDITIONAL_TOP_SECTION: 'TilesBlockRectangleGridAdditionalTopSectionRecord',
	TILES_BLOCK_FILE: 'TilesBlockFileRecord',
	TILES_BLOCK_WHO_HELP: 'TilesBlockWhoHelpRecord',
	TILES_BLOCK_WHO_HELP_PARTNER_BLOCK: 'TilesBlockWhoHelpPartnerBlockRecord',
	TILES_BLOCK_RECTANGLE_GRID: 'TilesBlockRectangleGridRecord',
	TILES_BLOCK_HOW_WE_HELP: 'TilesBlockHowWeHelpRecord',
	TILES_BLOCK_HEADER_DESCRIPTION_IRREGULAR: 'TilesBlockHeaderDescriptionIrregularRecord',
	TILES_BLOCK_HEADER_DESCRIPTION_IRREGULAR_ROW: 'TilesBlockHeaderDescriptionIrregularRowRecord',
	TILES_BLOCK_BE_WITH_US_TILE: 'TilesBlockBeWithUsTileRecord',
	TILES_BLOCK_MANAGEMENT: 'TilesBlockManagementRecord',
	TILES_BLOCK_MANAGEMENT_TILE: 'TilesManagementTileRecord',
	TILES_BLOCK_LOGO: 'TilesBlockLogoRecord',
	TILES_BLOCK_LOGO_TILE: 'TilesBlockLogoTileRecord',
	TILES_BLOCK_CONTACT_CARD: 'TilesBlockContactCardRecord',
	TILES_BLOCK_CONTACT_CARD_TILE: 'TilesBlockContactCardTileRecord',
	TILES_BLOCK_SQUARES_GRID_BACKGROUND: 'TilesBlockSquaresGridBackgroundRecord',
	TILES_BLOCK_SQUARES_GRID_BACKGROUND_TILE: 'TilesBlockSquaresGridBackgroundTileRecord',
	TILES_BLOCK_IMAGES_NAVIGATION: 'TilesBlockImagesNavigationRecord',
	TILES_BLOCK_IMAGES_NAVIGATION_TILE: 'TilesBlockNavigationTilesTileRecord',
	TILES_BLOCK_IMAGES_NAVIGATION_TILE_CORNER_BADGE: 'TilesBlockNavigationTilesTileCornerBadgeRecord',
	TILES_BLOCK_CONTACT_CARDS_SIMPLE: 'TilesBlockContactCardsSimpleRecord',
	BUTTON: 'ButtonRecord',
	ARROW_BUTTON: 'ArrowButtonRecord',
	CAREER_MODULE: 'CareerModuleRecord',
	HEADER_DESCRIPTION_BOX: 'HeaderDescriptionBoxRecord',
	VARIANT_CARD: 'VariantCardRecord',
	HISTORY_SECTION: 'HistorySectionRecord',
	PLACEHOLDER: 'PlaceholderRecord',
	ASSET_BLOCK: 'AssetBlockRecord',
	HEADER_DESCRIPTION_AND_AUDIO_BLOCK: 'HeaderDescriptionAudioRecord',
	HEADER_DESCRIPTION_AND_IMAGE_BLOCK: 'HeaderDescriptionImageRecord',
	HEADER_DESCRIPTION_AND_SPOT_IMAGE_BLOCK: 'HeaderDescriptionSpotimageRecord',
	HEADER_DESCRIPTION_AND_ICONS_GRID_BLOCK: 'HeaderDescriptionIconsGridRecord',
	PAYMENT_SINGLE_WITH_HEADING_BLOCK: 'PaymentSingleWithHeadingRecord',
	PAYMENT_WITH_STEPS_SINGLE_OR_MULTIPLE_BLOCK: 'PaymentWithStepsSingleOrMultipleRecord',
	PAYMENT_NO_RIGHT_SIDE_IMAGE: 'PaymentNoRightSideImageRecord',
	PINNED_HISTORY: 'PinnedHistoryRecord',
	HISTORY_ITEM: 'HistoryItemRecord',
	VARIANT_IMAGE: 'VariantImageRecord',
	HIGHLIGHTED_HEADING_BLOCK: 'HighlightedHeadingBlockRecord',
	GLOBAL_PAYMENT_RIGHT_IMAGE_TEXT_LINK_BLOCK: 'GlobalPaymentRightBlockImageTextLinkRecord',
	GLOBAL_PAYMENT_RIGHT_IMAGE_TEXT_ACCOUNT_BLOCK: 'GlobalPaymentRightBlockImageTextAccountRecord',
	GLOBAL_PAYMENT_BANNER_KRS: 'GlobalPaymentKrsBannerRecord',
	VIDEO_WITH_COVER_MODULE: 'VideoWithCoverModuleRecord',
	GALLERY_DESCRIPTION_TEXT_MODULE: 'GalleryDescriptionTextModuleRecord',
	GALLERY_DESCRIPTION_TEXT_ITEM_BLOCK: 'GalleryDescriptionTextItemBlockRecord',
	COLORFUL_SQUARES_MODULE: 'ColorfulSquaresModuleRecord',
	ARCHIVE: 'ArchiveRecord',
	SIGNUP_FORM_MODULE: 'SignupFormModuleRecord',
	AUDIO_FILE: 'AudioFileRecord',
	PRESS_MEDIA_FEED: 'PressMediaFeedRecord',
	SINGLE_DOWNLOAD_FILE_BLOCK: 'SingleDownloadFileBlockRecord',
	SITE_MAP_LINKS_GROUP_BLOCK: 'SiteMapLinksGroupRecord',
	SITE_MAP_SINGLE_LINK_BLOCK: 'SiteMapSingleLinkRecord',
	TABLE_BLOCK: 'TableBlockRecord',
	DRAGGABLE_LETTERS: 'DraggableLettersBannerRecord',
	BULLET_POINTS_WITH_ACTIONS_BLOCK: 'BulletPointsWithActionsBlockRecord',
	BULLET_POINTS_WITH_ACTIONS_SINGLE_ITEM_BLOCK: 'BulletPointsWithActionsSingleItemBlockRecord',
	TWO_IMAGES_IN_ROW_MODULE: 'TwoImagesInRowModuleRecord',
	EXPANDING_TABS_MODULE: 'ExpandingTabsModuleRecord',
	HEADER_DESCRIPTION_BLOCK: 'HeaderDescriptionBlockRecord',
	BRANCH_NAVIGATOR: 'BranchNavigatorRecord',
	HEADING_GRAPHIC_MODULE:'HeadingGraphicModuleRecord',
	PRODUCT_PAGE: 'ProductPageRecord',
	FACT_PAGE: 'FactPageRecord',
	FACTS_MODULE: 'FactsModuleRecord',
	BANNER_TYPOGRAPHY: 'BannerTypographyRecord',
	BANNER_TYPOGRAPHY_ITEM: 'BannerTypographyItemRecord',
	ARTICLE: 'ArticleRecord',
	CHOSEN_NEWS_MODULE:'ChosenNewsModuleRecord',
	ARTICLES_LIST_PREVIEW_MODULE: 'ArticlesListPreviewModuleRecord',
	COOKIES_BUTTON_BLOCK: 'CookiesButtonBlockRecord',
	CANCEL_SUBSCRIPTION_BUTTON_BLOCK: 'CancelSubscriptionButtonBlockRecord',
	KRS_BANNER: 'KrsBannerRecord',
	BANNER_MODULE_PREVIEW: 'BannerModulePreviewRecord'
}

export const PADDING = {
	EXTRA_SMALL: 'extra-small',
	SMALL: 'small',
	MEDIUM: 'medium',
	LARGE: 'large',
	EXTRA_LARGE: 'extra-large',
	NONE: 'none'
}

export const PAGES = {
	WHAT_WE_DO: 'co-robimy',
	OTHERS: 'pozostale',
	LOOKING_FOR_HELP: 'szukam-pomocy',
	POLISH_RED_CROSS: 'polski-czerwony-krzyz',
	NEWS: 'aktualnosci',
	I_PAY: 'wplacam',
	HOME: 'home',
	BE_WITH_US: "badz-z-nami",
	ANNOUNCEMENTS_AND_TENDERS: "ogloszenia-i-przetargi",
	PREVIEW: 'preview',
	THANK_YOU: 'thank-you',
	PRODUCTS: 'projekty',
	PROJECTS_AND_FACTS: 'projekty-i-fakty',
	ARTICLES: 'artykuly',
	CONTACT: 'kontakt',
	OUR_EVENTS: 'nasze-wydarzenia'
}

export const SINGLE_PAGE_TYPES = {
	SINGLE: 'single',
	OTHERS: 'others',
	LOOKING_FOR_HELP: 'looking-for-help',
	ANNOUNCEMENTS: 'announcements',
	POLISH_RED_CROSS: 'polish-red-cross'
}

export const TRANSFER_TYPES = {
	KRS: 'krs',
	ACCOUNT: 'account',
	SWIFT: 'swift',
	PLN_IBAN: 'pln-iban',
	EUR_IBAN: 'eur-iban',
	USD_IBAN: 'usd-iban',
	GBP_IBAN: 'gbp-iban',
}

export const LOCALES = {
	PL: 'pl',
	EN: 'en',
	DE: 'de'
}

export const DEVICE_TYPES = {
	MOBILE: 'mobile',
	TABLET: 'tablet',
	DESKTOP: 'desktop'
}

export const IMAGE_FALLBACK = {
	url: '',
	focalPoint: {
		x: 0.5,
		y: 0.5
	},
	height: 0,
	width: 0,
	alt: 'Image',
	blurUpThumb: '',
	placeholder: 'empty'
}
export const GLOBAL_STATE = 'global'

export const WHO_HELPS_VARIANTS = {
	DEFAULT: 'single-team-member',
	TWO_TEAM_MEMBERS: 'two-team-members',
	MANY: 'many-team-members',
}

export const MODAL_VARIANT = {
	TEXT: 'text',
	NEW_TAB: 'newtab',
	FORM: 'form',
	VIDEO: 'video',
	AUDIO: 'audio'
}

export const MODAL_COLOR_VARIANT = {
	LIGHT: 'light',
	DARK: 'dark',
	LIGHT_DARK: 'light-dark'
}

export const BUTTON_VARIANTS = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary',
	TERTIARY: 'tertiary',
	QUATERNARY: 'quaternary',
	QUINARY: 'quinary',
	SENARY: 'senary',
	SEPTENARY: 'septenary',
}

export const BUTTON_TYPE = {
	CLASSIC: 'classic',
	CHIP: 'chip',
	UNSTYLED: 'unstyled',
	SUBMIT: 'submit',
	ANIMATED: 'animated',
}
export const CALENDAR_RANGE = getCalendarDateRange(3, 3) //in DatoCMS API seconds & miliseconds are truncated

export const UNDERLINE_ANIMATION_CLASSNAME = 'underline-animation'

export const ASSET_TYPE = {
	VIDEO: 'video',
	IMAGE: 'image'
}

export const INPUT_TYPE = {
	TEXT: 'text',
	EMAIL: 'email',
	TEXTAREA: 'textarea',
	SELECT: 'select',
	VOIVODESHIP: 'voivodeship',
	ATTACHMENT: 'attachment'
}

export const WIDTH = {
	FULL: 'full',
	MEDIUM: 'medium',
	SMALL: 'small',
	VERY_SMALL: 'very-small'
}

export const NAVIGATOR_VIEW_TYPE = {
	MAP: 'map',
	PIN_MAP: 'pin-map'
}

export const UNIT_CATEGORY = {
	REGIONAL: 'regional',
	DELEGATIONS: 'delegations',
	HOME: 'pck-home'
}

export const TYPOGRAPHY_BANNER_VARIANTS = {
	WHITE: 'white',
	GRAY: 'gray'
}

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_AND_TIME_FORMAT = `${DATE_FORMAT} HH:mm`

