import React, {useEffect, useRef} from 'react';
import styled from 'styled-components'
import Portal, {OVERLAY_CLASS} from "@/components/common/Portals/Portal";
import {getCssSizeValue, isFunction, lowercaseFirstLetter} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {ICONS, MODAL_COLOR_VARIANT, MODAL_VARIANT} from "@/constants";
import Icon from "@/components/common/Icon/Icon";
import useScrollContext from "@/hooks/useScrollContext";
import useGlobals from "@/hooks/useGlobals";

const Wrapper = styled.div`
    --size: ${getCssSizeValue(350, BREAKPOINTS.MOBILE)};
	width: var(--size);
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: white;
	justify-content: space-between;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
    padding: 
			${getCssSizeValue(70, BREAKPOINTS.MOBILE)} 
			${getCssSizeValue(36, BREAKPOINTS.MOBILE)} 
			${getCssSizeValue(16, BREAKPOINTS.MOBILE)} 
			${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
    row-gap: 20px;

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(800, BREAKPOINTS.DESKTOP)};
        padding: ${getCssSizeValue(80)} ${getCssSizeValue(42)} ${getCssSizeValue(32)};
        row-gap: 40px;

        figure{
            min-height: calc(var(--size) * 0.45);
        }
	}
	
	&.${MODAL_COLOR_VARIANT.LIGHT_DARK} {
        background-color: ${({ theme }) => theme.colors.richBlue};
	}
	
	&.${MODAL_COLOR_VARIANT.DARK} {
		background-color: ${({ theme }) => theme.colors.richBlack};
	}
`

const CloseBtn = styled.div`
    --size: ${getCssSizeValue(42, BREAKPOINTS.MOBILE)};
	--svg-size: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	width: var(--size);
	height: var(--size);
	background: ${({ theme }) => theme.colors.chilliRed};

    cursor: pointer;
	display: grid;
	place-items: center;
    svg{
		width: var(--svg-size);
		height: var(--svg-size);
        path,circle{
            fill: #fff;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        --size: ${getCssSizeValue(40)};
        --svg-size: ${getCssSizeValue(16)};
	}
`

const Row = styled.div`
	position: relative;
	display: flex;
    color: ${({theme}) => theme.colors.richBlack};
	
	
	&.top {
		position: absolute;
		top: 0;
		right: 0;
	}
	
	&.main{
        max-height: 60svh;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
	}
	
	&.${MODAL_VARIANT.TEXT} {
		max-height: 80svh;
		
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            max-height: 60svh;
		}
	}
	
	&.second-row-${MODAL_VARIANT.TEXT} {
        max-height: 40vh;
	}
`

const Modal = ({
	id= '',
	isOpen= false,
	children,
	handleClose,
	className= '',
	variant= MODAL_VARIANT.TEXT,
	colorVariant = MODAL_COLOR_VARIANT.LIGHT,
	extraComponent = () => {},
	...props
}) => {
	const close = useRef(() => {})
	const scroll = useScrollContext()
	if(isFunction(handleClose)) {
		close.current = handleClose
	} else {
		close.current = () => {}
	}

	useEffect(() => {
		const closeOnEscapeKey = e => e.key === "Escape" ? close.current() : null;
		const closeOnOutside = (e) => {
			const targetIsOverlay = e.target.classList.contains(OVERLAY_CLASS)
			if(!targetIsOverlay) return
			close.current()
		}
		document.body.addEventListener("keydown", closeOnEscapeKey);
		if(isOpen){
			scroll.lock()
			document.body.addEventListener("click", closeOnOutside);
		}
		return () => {
			if(isOpen){
				scroll.unlock()
				document.body.removeEventListener("keydown", closeOnEscapeKey);
				document.body.removeEventListener("click", closeOnOutside);
			}

		};
	}, [isOpen]);

	if (!isOpen) return null;

	return (
		<Portal wrapperId={id}>
			<Wrapper className={`modal ${className} ${colorVariant}`}>
				<Row className="top">
					<CloseBtn onClick={close.current}>
						<Icon name={ICONS.CLOSE} />
					</CloseBtn>
				</Row>
				<Row className={`${variant} main`} data-scroll-ignore-lock>
					{children}
				</Row>
				{extraComponent}
			</Wrapper>
		</Portal>
	);
};

export function useModalData(){
	return useGlobals((state) => {
		return Object.keys(state).reduce((prev, current) => {
			if (current.startsWith('modal')) return {
				[lowercaseFirstLetter(current.slice(5))]: state[current],
				...prev
			}
			return prev
		}, {})
	})
}

export default Modal;
