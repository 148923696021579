import {createGlobalStyle} from 'styled-components';

const AccessibilityStyles = createGlobalStyle`
    :root {
		--typoSize: 1;
        --activeFilter: none;
		--zoom: 1;
		--headings-highlight: none;
		--links-highlight: none;
    }
	
    html {
        background-color: ${({theme}) => theme.colors.white};
        filter: var(--activeFilter);
    }

	body {
        zoom: var(--zoom);
	}
	
    a[href] {
        border: 2px solid rgba(0, 0, 255, var(--highlightLinks));
    }

    h1, h2, h3, h4, h5, h6 {
		border: 2px solid rgba(255, 0, 0, var(--highlightHeadings));
    }
`

export default AccessibilityStyles;