import React from 'react';
import {useRouter} from "next/router";
import Button from "@/components/common/Buttons/Button";
import {BUTTON_VARIANTS, ICONS, PAGES} from "@/constants";
import CommonIcon from "@/components/common/Icon/Icon";
import styled from "styled-components";
import useGlobals from "@/hooks/useGlobals";

const Icon = styled(CommonIcon)`
    height: 24px;
    //height: 14px;
    width: 24px;
    //width: 14px;
`

const IPayButton = ({ className= '', variant=BUTTON_VARIANTS.PRIMARY, ...props }) => {
	const { locale } = useRouter();
	const iPay = useGlobals('iPayButtonText')
	const iPayLink = locale === 'pl' ? `/${PAGES.I_PAY}` : locale === "en" ? `/en/${PAGES.I_PAY}` : `/de/${PAGES.I_PAY}`;
	const handleClick = () => {
		sessionStorage.setItem('currentPath', iPayLink);
	}
	return (
		<Button
			className={`${className} i-pay-medium`}
			variant={variant}
			onClick={handleClick}
			href={`/${PAGES.I_PAY}`}
			as="link"
			{...props}
		>
			{iPay}
			<Icon name={ICONS.CHEVRON}/>
		</Button>
	);
};

export default IPayButton;
