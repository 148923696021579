import React, {forwardRef, memo, useMemo} from 'react';
import styled from 'styled-components';
import useColor from "@/hooks/useColor";
import SVGR from "@/components/common/Icon/SVGR";

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    --cl: ${({$color}) => $color || 'black'};
    //height: 100%;
    transition: all var(--rgr-duration) var(--ease);

    svg {
        display: block;
        width: 100%;
        height: 100%;

        path,
        circle {
            pointer-events: none;
        }

        transition: all var(--rgr-duration) var(--ease);
    }

    &.colored {
        svg {
            path, circle, polygon, rect {
                fill: var(--cl);
            }
        }
    }

    &.hover {
        svg:hover {
            transform: scale(1.113);
        }
    }
`;

const Icon = forwardRef(function Icon({ id = '', name = '', color='', className='', children, svgProps, hover = false,...props }, ref) {
	const standardizedId = useMemo(() => {
		// const uuid = id || v4();
		const uuid  = id ? `-${id}` : ''
		return `icon-${name}${uuid}`.trim().replaceAll('/--|- -/g', '-'); //replace "--" or "- -" with "-"
	}, [name, id]); //use passed id or generate one
	const cl = useColor(color)
	return (
		<Wrapper
			id={standardizedId}
			ref={ref}
			$color={cl}
			className={`${className} ${name} ${color ? 'colored' : ''} ${hover ? 'hover' : ''} icon`}
			{...props}
		>
			<SVGR name={name} {...svgProps} />
			{children}
		</Wrapper>
	);
});

export default memo(Icon);