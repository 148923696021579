const anim = (variants) => {
	return {
		initial: "initial",
		animate: "enter",
		exit: "exit",
		variants
	}
}

const slide = {
	initial: {
		y: '0%'
	},
	enter: {
		y: '-100%',
		transition: {
			duration: 0.8,
			delay: 0.2,
			ease: [0.215, 0.61, 0.355, 1]
		},
		// transitionEnd: { y: "100%" }
	},
	exit: {
		y: ['100%', '0%'],
		transition: {
			duration: 0.6,
			ease: [0.215, 0.61, 0.355, 1],
		}
	}
}

export { anim, slide }
