import React, {memo} from "react";
import {ICONS} from "@/constants";
import dynamic from "next/dynamic";

const SVGMap = {
	[ICONS.ACCESSIBILITY]: dynamic(() => import('./Accessibility'), {ssr: false}),
	[ICONS.ARROW_DIAGONAL]: dynamic(() => import('./ArrowDiagonal'), {ssr: false}),
	[ICONS.ARROW_DOWN]: dynamic(() => import('./ArrowDown'), {ssr: false}),
	[ICONS.ARROW_LEFT]: dynamic(() => import('./ArrowLeft'), {ssr: false}),
	[ICONS.ARROW_RIGHT]: dynamic(() => import('./ArrowRight'), {ssr: false}),
	[ICONS.ARROW_UP]: dynamic(() => import('./ArrowUp'), {ssr: false}),
	[ICONS.ATTACHMENT]: dynamic(() => import('./Attachment'), {ssr: false}),
	[ICONS.CALENDAR]: dynamic(() => import('./Calendar'), {ssr: false}),
	[ICONS.CHEVRON]: dynamic(() => import('./Chevron'), {ssr: false}),
	[ICONS.CITY]: dynamic(() => import('./City'), {ssr: false}),
	[ICONS.CLOSE]: dynamic(() => import('./Close'), {ssr: false}),
	[ICONS.CONTACT]: dynamic(() => import('./Contact'), {ssr: false}),
	[ICONS.COOKIE]: dynamic(() => import('./Cookie'), {ssr: false}),
	[ICONS.COPY]: dynamic(() => import('./Copy'), {ssr: false}),
	[ICONS.CROSS]: dynamic(() => import('./Cross'), {ssr: false}),
	[ICONS.CROSS_FAT]: dynamic(() => import('./CrossFat'), {ssr: false}),
	[ICONS.DOWNLOAD]: dynamic(() => import('./Download'), {ssr: false}),
	[ICONS.FACEBOOK]: dynamic(() => import('./Facebook'), {ssr: false}),
	[ICONS.FALLING_CROSS]: dynamic(() => import('./FallingCross'), {ssr: false}),
	[ICONS.FOOTER_SLOGAN_EN]: dynamic(() => import('./FooterSloganEN'), {ssr: false}),
	[ICONS.FOOTER_SLOGAN_PL]: dynamic(() => import('./FooterSloganPL'), {ssr: false}),
	[ICONS.GRID]: dynamic(() => import('./Grid'), {ssr: false}),
	[ICONS.HOME]: dynamic(() => import('./Home'), {ssr: false}),
	[ICONS.INSTAGRAM]: dynamic(() => import('./Instagram'), {ssr: false}),
	[ICONS.LINKEDIN]: dynamic(() => import('./LinkedIn'), {ssr: false}),
	[ICONS.LIST]: dynamic(() => import('./List'), {ssr: false}),
	[ICONS.LOUPE]: dynamic(() => import('./Loupe'), {ssr: false}),
	[ICONS.MAP]: dynamic(() => import('./Map'), { ssr: false }),
	[ICONS.MENU]: dynamic(() => import('./Menu'), {ssr: false}),
	[ICONS.NAME]: dynamic(() => import('./Name'), {ssr: false}),
	[ICONS.PAY]: dynamic(() => import('./Pay'), {ssr: false}),
	[ICONS.PAYMENTS_SLOGAN_EN]: dynamic(() => import('./PaymentsSloganEN'), {ssr: false}),
	[ICONS.PAYMENTS_SLOGAN_PL]: dynamic(() => import('./PaymentsSloganPL'), {ssr: false}),
	[ICONS.PHONE]: dynamic(() => import('./Phone'), {ssr: false}),
	[ICONS.PIN_MAP]: dynamic(() => import('./PinMap'), { ssr: false }),
	[ICONS.PILL]: dynamic(() => import('./Pill'), {ssr: false}),
	[ICONS.PLAY]: dynamic(() => import('./Play'), {ssr: false}),
	[ICONS.RESET]: dynamic(() => import('./Reset'), {ssr: false}),
	[ICONS.SNAP]: dynamic(() => import('./Snap'), {ssr: false}),
	[ICONS.STATES_MAP]: dynamic(() => import('./StatesMap'), {ssr: false}),
	[ICONS.STREET]: dynamic(() => import('./Street'), {ssr: false}),
	[ICONS.SURNAME]: dynamic(() => import('./Surname'), {ssr: false}),
	[ICONS.TIME]: dynamic(() => import('./Time'), {ssr: false}),
	[ICONS.TRIANGLE]: dynamic(() => import('./Triangle'), {ssr: false}),
	[ICONS.TWITTER]: dynamic(() => import('./Twitter'), {ssr: false}),
	[ICONS.WHAT_WE_DO]: dynamic(() => import('./WhatWeDo'), {ssr: false}),
	[ICONS.YOUTUBE]: dynamic(() => import('./YouTube'), {ssr: false}),
	[ICONS.ZEPSUJ]: dynamic(() => import('./Zepsuj'), {ssr: false}),
	[ICONS.ZIP_CODE]: dynamic(() => import('./ZipCode'), {ssr: false}),
	[ICONS.MINUS]: dynamic(() => import('./Minus'), {ssr: false}),
};


const SVGR = ({name, ...props}) => {
	const SvgIcon = SVGMap[name];
	if (!SvgIcon) return null;
	return <SvgIcon {...props} />;
};

export default memo(SVGR)
