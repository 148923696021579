import React from 'react';
import {ThemeProvider} from "styled-components";
import GlobalStyles from "@/styles/globalStyles";
import {TypographyStyles} from "@/styles/Typography/globalTypography";
import {theme} from "@/styles/themeSettings";
import Paddings from "@/styles/Paddings";
import Grid from "@/styles/Grid";
import AccessibilityStyles from "@/styles/Accessibility";
import {useSelector} from "react-redux";

const Theme = ({children}) => {
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyles isSearchBarVisible={isSearchBarVisible}/>
			<TypographyStyles/>
			<Paddings/>
			<AccessibilityStyles />
			<Grid/>
			{children}
		</ThemeProvider>
	);
};

export default Theme;
