import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import CommonText from "@/components/common/Text";
import CommonIcon from "@/components/common/Icon/Icon";
import CommonButton from "@/components/common/Buttons/Button";
import useCurrentBranchData from "@/hooks/useCurrentBranchData";
import useGlobals from "@/hooks/useGlobals";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {BUTTON_VARIANTS, ICONS} from "@/constants";
import {useBranch} from "@/hooks/useBranch";

const Wrapper = styled.div`
	position: fixed;
    display: none;
	justify-content: center;
	align-items: center;
	min-height: ${getCssSizeValue(50)};
	padding: ${getCssSizeValue(7.5)} ${getCssSizeValue(18)};
	background-color: ${({ theme }) => theme.colors.green };
	bottom: 0;
	z-index: 100003;
	translate: 0 100%;
	transition: translate var(--rgr-duration) var(--ease);
	margin: 0 var(--paddingX);
    width: calc(100vw - var(--paddingX)*2);
	
	&.active{
		translate: 0;
	}
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        display: flex;
    }
`

const Text = styled(CommonText)`
	font-size: ${getFontSize(16)};
	margin: 0 auto;
	span{
		color: ${({ theme }) => theme.colors.white };
		text-transform: uppercase;
		margin-right: 3px;
	}
`

const Button = styled(CommonButton)`
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		--spacing-y: ${getCssSizeValue(8)} !important;
    }
`

const Icon = styled(CommonIcon)`
	cursor: pointer;
	width: ${getCssSizeValue(15)};
	margin-left: ${getCssSizeValue(41)};
`

const time = 8 //s

const BranchInformator = () => {
	const [isActive, setIsActive] = useState(false)
	const currentBranch = useCurrentBranchData()
	const { handle, fullName } = useMemo(() => ({
		handle: currentBranch?.handle || '',
		fullName: currentBranch?.fullName || ''
	}), [currentBranch])
	const { setSelectedOption } = useBranch()
	const states = useGlobals('states')
	const { branchInformatorText1, branchInformatorText2, branchInformatorText3 } = useGlobals()
	const timeout = useRef()
	const globalStateData = useMemo(() => {
		const source = Array.isArray(states) ? states : []
		return source.find((item) => {
			const handle = item?.handle?.toLowerCase() || ''
			return handle === 'global'
		})
	}, [states])

	const close = useCallback(() => {
		setIsActive(false)
		clearTimeout(timeout.current)
	},[])

	const backToGlobal = useCallback(() => {
		setSelectedOption(globalStateData?.handle)
	},[setSelectedOption, globalStateData])

	useEffect(() => {
		clearTimeout(timeout.current)

		//don't render if branch changes to global
		if (handle === globalStateData?.handle) {
			close()
			return
		}

		setIsActive(true)
		timeout.current = setTimeout(() => {
			setIsActive(false)
		}, time*1000)
	}, [handle, globalStateData?.handle, close]);


	return (
		<Wrapper className={`${isActive ? 'active' : ''} `}>
			<Text>
				{branchInformatorText1 || ''}
				<span> {fullName || ''}</span>
				{branchInformatorText2 || ''}
				<span> {globalStateData?.fullName || ''}</span>
				{branchInformatorText3 || ''}
			</Text>
			<Button variant={BUTTON_VARIANTS.SECONDARY} onClick={backToGlobal} typo={98}>
				{globalStateData?.fullName || ''}
			</Button>
			<Icon name={ICONS.CLOSE} onClick={close} />
		</Wrapper>
	);
};

export default BranchInformator;
