import {createGlobalStyle, css} from 'styled-components';
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {REVEAL_CLASSNAME} from "@/animations/Reveal";
import {EASE, UNDERLINE_ANIMATION_CLASSNAME} from "@/constants";
import {duration} from "@/utils/gsap";
import {OVERLAY_CLASS} from "@/components/common/Portals/Portal";

const GlobalStyles = createGlobalStyle`
    :root {
        --ease: cubic-bezier(${EASE.join(', ')});
        --paddingX: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)}; //70px
        --inner-paddingX: ${getCssSizeValue(110, BREAKPOINTS.DESKTOP)};
		--rgr-duration: ${duration}s; //odwrócone golden ratio
    }

    html {
        background-color: ${({theme}) => theme.colors.white};
		//scroll-behavior: auto;
        //overscroll-behavior: none; //disable overscroll on IOS devices
    } 

    html, body, #__next {
        margin: 0;
        padding: 0;
        width: 100%;
        //height: 100%;
        overflow: initial;
		
		
        //disabled scrollbar
        //scrollbar-width: none;

        //&::-webkit-scrollbar {
        //    display: none;
        //}
    }
	
    body {
        //-webkit-tap-highlight-color: red !important;
        -webkit-tap-highlight-color: transparent;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: ${({theme}) => theme.colors.white};
        overflow-y: ${({isSearchBarVisible}) => isSearchBarVisible ? 'hidden' : 'auto'};

        &.no-scroll{
            overflow: hidden;
            //position: fixed;
        }
		
        *::selection {
            background-color: ${({ theme }) => theme.colors.periwinkle};
        }
    }

	
    *, *::before, *::after {
        box-sizing: border-box;
    }

    textarea:focus, input:focus {
        outline: none;
    }
    
    a {
		color: inherit;
        text-decoration: none;
    }
    a:-webkit-any-link {
        color: unset;
    }

    h1, h2, h3, h4, h5, h6, p, ul {
        margin: 0;
        padding: 0;
    }

    button {
        margin: 0;
        padding: 0;
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .${REVEAL_CLASSNAME}-parent{
        //overflow-y: hidden;
        &.axis-y {
            &:not(&.disabled){
                //clip-path: inset(0); //alternative
                overflow-y: hidden;
			}
        }
        &.axis-x {
            &:not(&.disabled){
                //clip-path: inset(0); //alternative
                overflow-x: hidden;
			}
        }
    }

    &.no-select {
        //user-select: none;
        //-webkit-user-select: none;
        //-moz-user-select: none;
        //-ms-user-select: none;
        //pointer-events: none;
    }
	
	.${OVERLAY_CLASS}{
		position: fixed;
		inset: 0;
		z-index: 10000000; //wchui
		background-color: rgba(125,125,125,0.5);
	}
	
	.loading{
        position: relative;
        z-index: 1;
        --bg-size: 10%;
        --bg-inset: calc((100% - var(--bg-size)) /2);
        --color: ${({ theme }) => theme.colors.chilliRed};
        --dur: 1.25s;
        --delay: calc(var(--dur) * ${({ $index }) => Math.max($index, 0) }) ;

        &:before, &:after{
            content: ' ';
            position: absolute;
            inset: var(--bg-inset);
			aspect-ratio: 1;
        }

        &:before{
            opacity: 0.8;
            background:
                    linear-gradient(to bottom right,#0000 calc(50% - 40px),#fff 50%,#0000 calc(50% + 40px))
                    bottom right/calc(200% + 80px) calc(200% + 80px)
                    var(--color);
            animation: shine var(--dur) infinite ease-in-out;
            animation-delay: var(--delay);
        }

        &:after{
            --c:no-repeat linear-gradient(white 0 0);
            background: var(--c),var(--c),var(--c),var(--c);
            background-size: 35% 35%;
            background-position: -2%  -2%,102% -2% ,102% 102%,-2% 102%
        }


        @keyframes shine {
            100% {background-position: top left}
        }
	}
	
	
	.${UNDERLINE_ANIMATION_CLASSNAME} {
        position: relative;
		
		&.bright {
			&:after {
                background-color: ${({theme}) => theme.colors.white};
			}
		}

		&:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
			background-color: ${({theme}) => theme.colors.richBlack};
            transform-origin: bottom right;
            transition: transform 250ms ease-out;
		}
		
		&:hover:after {
            transform: scaleX(1);
            transform-origin: bottom left;
		}
	}
`

export const insetPaddingRightCss = css`
    &::after {
        left: 100%;
        top: 0;
        content: '';
        position: absolute;
        width: calc(var(--paddingX));
        height: 100%;
        background-color: inherit;
        display: block;
    }
`

export const insetPaddingLeftCss = css`
    &::before {
        right: 100%;
        top: 0;
        content: '';
        position: absolute;
        width: var(--paddingX);
        height: 100%;
        background-color: inherit;
        display: block;
    }
`

export const insetGridGapLeftCss = css`
    &::before {
        right: 100%;
        top: 0;
        content: '';
        position: absolute;
        width: var(--grid-gap);
        height: 100%;
        background-color: inherit;
        display: block;
    }
`

export const insetGapLeftCss = css`
    &::before {
        right: 100%;
        top: 0;
        content: '';
        position: absolute;
        width: var(--grid-gap);
        height: 100%;
        background-color: inherit;
        display: block;
    }
`

export default GlobalStyles;
