import React, {useRef, useState} from 'react';
import TopBarRecap from "@/components/topBar/TopBarRecap";
import SearchMain from "@/components/search/SearchMain";
import Button from "@/components/common/Buttons/Button";
import IPayButton from "@/components/common/Buttons/IPayButton";
import {setIsMenuOpen} from "@/state/menuSlice";
import {BUTTON_VARIANTS, ICONS} from "@/constants";
import {useIsDesktop, useIsTablet} from "@/hooks/useDevice";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import CommonIcon from "@/components/common/Icon/Icon";
import {setIsSearchBarOpened} from "@/state/searchBarSlice";
import BeWithUsButton from "@/components/common/Buttons/BeWithUsButton";
import {useRouter} from "next/router";
import Loupe from "@/components/common/Icon/SVGR/Loupe";
import classNames from "classnames";
import useTranslateElementOnScroll from "@/hooks/useTranslateElementOnScroll";
import useRecap from "@/components/Recap/RecapProvider";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index: var(--layer-3);
    margin-left: auto;

    .icon {
        max-width: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            max-width: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
        }
    }

    .gap {
        margin-right: 14px;
    }
`
const Icon = styled(CommonIcon)`
    margin: 0 ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin: 0;
        min-height: ${getCssSizeValue(20)};
        min-width: ${getCssSizeValue(20)};
    }
`

const MobileLoupeWrapper = styled.div`
    //margin-right: 24px;
    top: 36px;
    right: 96px;
    position: fixed;
    display: flex;
    z-index: 1;

    svg {
        width: 19px;
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        top: 40px;
        right: 120px;
        transition: right 0.3s ease-in-out;
        svg {
            width: 26px;
        }
    }

    &.open {
        svg {
            path {
                fill: ${({theme}) => theme.colors.richBlack} !important;
            }
        }

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
            //right: 500px;
            right: calc(100vw - 56px - 18px);
            transition: right 0.3s ease-in-out;
        }
    }

    svg {
        path {
            fill: ${({theme}) => theme.colors.slateGray} !important;
        }
    }
`

const MobileSearchContainer = styled.div`
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: auto;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.1s;
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        //height: 88px;
		height: auto;
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        height: 0;
    }

    &.visible {
        opacity: 1;
        transition: opacity 0.3s ease-in-out 0.15s;
    }
`

const Right = ({isDarkSearch}) => {
	const { recapExpanded } = useRecap()
	const dispatch = useDispatch();
	const isDesktop = useIsDesktop(false)
	const isTablet = useIsTablet();
	const {locale, asPath} = useRouter();
	const isMenuOpened = useSelector((state) => state.menu.open);
	const isSearchBarVisible = useSelector((state) => state.searchBar.isOpen);
	const searchIndex = `all_content_versions_${locale}`;
	const { recapData } = useRecap()

	const recap = {
		enabled: recapData?.topBarRecapEnabled,
		data: recapData
	}

	const handleCloseSearch = (e) => {
		dispatch(setIsSearchBarOpened(false));
		e.stopPropagation();
	};

	const handleClickSearchBar = () => {
		if (isMenuOpened) {
			dispatch(setIsMenuOpen(false))
			setTimeout(() => {
				dispatch(setIsSearchBarOpened(true))
			}, 200)
		} else {
			dispatch(setIsSearchBarOpened(true))
		}
	}

	const { translateElementRef, translateElementContainerRef } = useTranslateElementOnScroll(recap.enabled, recapExpanded, recap.enabled)

	return (
		<Wrapper ref={translateElementContainerRef}>
			{isDesktop && (
				<>
					<>
						<SearchMain
							recapEnabled={recap.enabled}
							className={isSearchBarVisible ? 'visible' : ''}
							searchIndex={searchIndex}
							isVisible={isSearchBarVisible}
							handleCloseSearch={handleCloseSearch}
							onClick={handleClickSearchBar}
							isDarkSearch={isDarkSearch}
						/>
						<div
							key={asPath}
							ref={translateElementRef}
						>
							<BeWithUsButton
								variant={BUTTON_VARIANTS.SECONDARY}
								className={`${isSearchBarVisible ? 'search-visible' : ''} gap `}
							/>
						</div>
						{recap.enabled && (
							<TopBarRecap />
						)}
					</>
					<IPayButton className={`${isSearchBarVisible ? 'search-visible' : ''} gap`}/>
				</>
			)}
			{!isDesktop && (
				<MobileLoupeWrapper className={isSearchBarVisible ? 'open' : ''} onClick={handleClickSearchBar}>
					<Loupe className='loupe'/>
				</MobileLoupeWrapper>
			)}
			<MobileSearchContainer className={isSearchBarVisible ? 'visible' : ''}>
				{!isDesktop && (
					<SearchMain
						className={isSearchBarVisible ? 'visible' : ''}
						searchIndex={searchIndex}
						isVisible={isSearchBarVisible}
						handleCloseSearch={handleCloseSearch}
						onClick={handleClickSearchBar}
						isDarkSearch={isDarkSearch}
					/>
				)}
			</MobileSearchContainer>
			<Button
				className={
					classNames({
						'search-visible': isSearchBarVisible,
						'search-visible-tablet': isSearchBarVisible && isTablet,
						'toggle': true,
					})
				}
				variant={BUTTON_VARIANTS.SECONDARY}
				onClick={() => {
					if (isSearchBarVisible && !isDesktop) {
						dispatch(setIsSearchBarOpened(false))
					} else {
						dispatch(setIsMenuOpen(!isMenuOpened))
					}
				}}
			>
				<Icon
					name={(isMenuOpened || isSearchBarVisible) ? ICONS.CLOSE : ICONS.MENU}
					className="colored"
				/>
			</Button>
		</Wrapper>
	);
};

export default Right;
