import styled from "styled-components";
import Newsletter from "./Newsletter";
import {getCssSizeValue, getHtmlFromRedactorField} from "@/utils/utils";
import {BREAKPOINTS, colors} from "@/styles/themeSettings";
import Socials, {VARIANTS} from "@/components/common/Socials";
import LocaleSelect from "@/components/common/LocaleSelect";
import useGlobals from "@/hooks/useGlobals";
import Button, {getButtonLinkData} from "@/components/common/Buttons/Button";
import LinkButton from "@/components/common/Buttons/LinkButton";
import {BUTTON_TYPE, BUTTON_VARIANTS, ICONS} from "@/constants";
import UniversalLink from "@/components/common/Links/UniversalLink";
import ExternalLink from "@/components/common/Links/ExternalLink";
import SimpleIconButton, {SIMPLE_ICON_BUTTON_ANIMATIONS} from "@/components/common/SimpleIconButton";
import React from "react";
import useScrollContext, {useScroll} from "@/hooks/useScrollContext";
import {ScrollTrigger} from "@/utils/gsap";
import CommonIcon from "@/components/common/Icon/Icon";

const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
    padding-bottom: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-bottom: ${getCssSizeValue(36, BREAKPOINTS.TABLET)};
	}
	
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		min-height: unset;
		padding-bottom: ${getCssSizeValue(32)};
    }
`

const StyledSocialsAndLanguage = styled.div`
    display: flex;
	flex-direction: column;
    margin-top: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    margin-bottom: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
	gap: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		flex-direction: row;
        justify-content: space-between;
        margin-bottom: ${getCssSizeValue(75, BREAKPOINTS.TABLET)};
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(56)};
		margin-bottom: unset;
		gap: unset;
    }
`

const StyledText = styled.div`
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        text-align: end;
	}
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        //margin-top: ${getCssSizeValue(56)};
        margin-top: ${getCssSizeValue(36)};
    }
`

const BackToTopButtonContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		justify-content: flex-end;
		margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-bottom: ${getCssSizeValue(36)};
		justify-content: flex-end;
	}
`

const ArrowIconWrapper = styled.div`
	cursor: pointer;
`

const Icon = styled(CommonIcon)`
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin: 0;
        height: ${getCssSizeValue(20)};
        width: ${getCssSizeValue(20)};
    }
`


export default function NewsletterAndSocials() {
	const { footer } = useGlobals()
	const allRightsReservedText = footer?.allRightsReservedText || ''
	const scroll = useScrollContext()
	const toggleScrollTriggers = (selectors, enable = true) => {
		ScrollTrigger.getAll().forEach(trigger => {
			if (selectors.includes(trigger.vars.trigger)) {
				enable ? trigger.enable() : trigger.disable(false);
			}
		});
	};

	const handleBackToTop = () => {
		const triggersToToggle = ScrollTrigger.getAll().map(trigger => trigger.vars.trigger);
		toggleScrollTriggers(triggersToToggle, false);
		scroll.scrollTo({ top: 0, behavior: "smooth" });
		setTimeout(() => {
			toggleScrollTriggers(triggersToToggle, true);
		}, 1000);
	};

	return (
		<StyledWrapper className='col-mobile-8 col-tablet-8 col-desktop-6'>
			<div>
				<Newsletter />
				<StyledSocialsAndLanguage>
					<Socials variant={VARIANTS.BIG} color="gunmetal" />
					<LocaleSelect className="locale-select" />
				</StyledSocialsAndLanguage>
			</div>
			<StyledText>
				<BackToTopButtonContainer>
					<Button variant={BUTTON_VARIANTS.SECONDARY} className='back-to-top'>
						<ArrowIconWrapper onClick={handleBackToTop} className='arrow-icon'>
							<Icon
								name={ICONS.ARROW_UP}
							/>
						</ArrowIconWrapper>
					</Button>
				</BackToTopButtonContainer>
				<div
					className='typo-strawford typo-strawford-51'
					dangerouslySetInnerHTML={getHtmlFromRedactorField(allRightsReservedText)}
				/>
			</StyledText>
		</StyledWrapper>
	)
}
