import React, {useMemo} from 'react';
import {useBranch} from "@/hooks/useBranch";
import styled from "styled-components";
import Icon from "@/components/common/Icon/Icon";
import {BUTTON_TYPE, ICONS} from "@/constants";
import {getCssSizeValue} from "@/utils/utils";
import {getLinkData} from "@/components/common/Links/utils";
import ExternalLink from "@/components/common/Links/ExternalLink";
import useGlobals from "@/hooks/useGlobals";
import {BREAKPOINTS} from "@/styles/themeSettings";

const Wrapper = styled.div`
	display: flex;
	column-gap: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
	align-items: center;
	margin: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)} 0 0;
	.icon{
        animation: fade-in var(--rgr-duration) var(--ease);
		cursor: pointer;
		width: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	}
	
	&.big {
		margin: unset;
		column-gap: ${getCssSizeValue(28, BREAKPOINTS.MOBILE)};
		.icon {
            width: ${getCssSizeValue(30, BREAKPOINTS.MOBILE)};
		}
	}

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px){
        column-gap: ${getCssSizeValue(28, BREAKPOINTS.TABLET)};

        .icon {
            width: ${getCssSizeValue(30, BREAKPOINTS.TABLET)};
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        column-gap: ${getCssSizeValue(20, BREAKPOINTS.DESKTOP)};
		
		.icon{
           width: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
	   }
		
		&.big {
            margin: unset;
            column-gap: ${getCssSizeValue(28)};
            .icon {
                width: ${getCssSizeValue(30)};
            }
        }
	}
`

const Social = ({ data, color }) => {
	const iconName = data?.media || ICONS.FACEBOOK
	const linkData = getLinkData(data)
	const { externalLink, newCard} = linkData
	return (
		<ExternalLink href={externalLink} newCard={newCard} type={BUTTON_TYPE.UNSTYLED} >
			<Icon name={iconName} color={color}/>
		</ExternalLink>
	)
}

export const VARIANTS = {
	SMALL: 'small',
	BIG: 'big'
}

const Socials = ({ variant = VARIANTS.SMALL, className='', color="chineseViolet", children, ...props }) => {
	const { selectedOption } = useBranch()
	const { states } = useGlobals()

	const stateSocials = useMemo(() => {
		const state = states?.find((state) => state?.handle.toLowerCase() === selectedOption)
		return state?.socials || []
	},[states, selectedOption])


	return (
		<Wrapper className={`${variant === VARIANTS.BIG ? VARIANTS.BIG : ''} ${className}`} {...props}>
			{stateSocials.map((social, index) => <Social data={social} key={social?.id || index} color={color} />)}
			{children}
		</Wrapper>
	)
}

export default Socials;
