import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-40, &-40i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(33.8, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(46, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(59.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(46.8, BREAKPOINTS.DESKTOP)};
        }
    }
    &-41, &-41i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(18.6, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
        }
    }
    &-42, &-42i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height: 100%;
		font-weight: ${({theme}) => theme.fontWeight.medium};
		letter-spacing: 2.6px;
		text-transform: uppercase;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(13, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(14.3, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(13, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(15.6, BREAKPOINTS.DESKTOP)};
        }
    }
    &-43, &-43i {
        font-size: ${getFontSize(15, BREAKPOINTS.MOBILE)};
        line-height: normal;
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(20, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(24, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
        }
    }
	&-44, &-44i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(31.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(46, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(55.2, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(69, BREAKPOINTS.DESKTOP)};
        }
    }
	&-45, &-45i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.DESKTOP)};
        }
    }
	&-46, &-46i {
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(32, BREAKPOINTS.DESKTOP)};
        }
    }
	&-47, &-47i {
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(26, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.bold};
            font-size: ${getFontSize(28, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(33.6, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-48, &-48i {
        font-size: ${getFontSize(21, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(28.8, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-49, &-49i {
        font-size: ${getFontSize(20, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(26, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(23.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-weight: ${({theme}) => theme.fontWeight.black};
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(23.4, BREAKPOINTS.DESKTOP)};
        }
    }
`
