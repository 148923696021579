import React, {createContext, useEffect, useMemo, useRef, useState} from 'react';
import useCustomContext from "@/hooks/useCustomContext";
import {formatContentWithRecap} from "@/utils/utils";
import {getRecapDataFromData} from "@/components/Recap/index";
import {useRouter} from "next/router";
import useGlobals from "@/hooks/useGlobals";

const INIT_CONTEXT = {}
const RecapContext = createContext(INIT_CONTEXT);

export const RecapProvider = ({data, children}) => {
	const actions = data?.actions || []
	const aRecap = useGlobals((state) => ({
		id: 'category-actions',
		recap: {
			header: state.recapWwdActionsHeader,
			subHeader: state.recapWwdActionsSubHeader,
			inRecap: actions.length > 0
		}
	}))
	const router = useRouter()
	const content = useMemo(() => formatContentWithRecap(data?.content || []), [data])
	const [recapData, setRecapData] = useState(() => getRecapDataFromData(data, content, aRecap));
	const [recapExpanded, setRecapExpanded] = useState(false)
	const searchWrapper = useRef(null)

	useEffect(() => {
		setRecapData(getRecapDataFromData(data, content, aRecap))
	}, [data, content, router]);

	useEffect(() => {
		setRecapExpanded(false)
	}, [router])

	const context = {
		recapData,
		recapExpanded,
		setRecapExpanded,
		searchWrapper
	}

	return (
		<RecapContext.Provider value={context}>
			{children}
		</RecapContext.Provider>
	);
};

export default function useRecap(selector) {
	return useCustomContext(RecapContext, selector)
}