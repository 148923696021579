import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-50, &-50i {
		line-height: normal;
        font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-51, &-51i {
        font-size: ${getFontSize(15, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(15, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
        }
    }
	
	&-52, &-52i {
        font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(19.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
        }
    }
	&-53, &-53i {
    font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.medium};
    line-height:${getFontSize(36, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(24, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(36, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(24, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
		}
	}
	&-54, &-54i {
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    font-weight: ${({theme}) => theme.fontWeight.black};
    line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
    text-transform: uppercase;
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(21, BREAKPOINTS.DESKTOP)};
		}
	}
	&-55, &-55i {
    font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
    line-height:${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(25.2, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(25.2, BREAKPOINTS.DESKTOP)};
		}
	}
	&-56, &-56i {
    font-size: ${getFontSize(40, BREAKPOINTS.MOBILE)};
    line-height: 100%;
    font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(60, BREAKPOINTS.TABLET)};
			line-height: ${getFontSize(42, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(60, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(42, BREAKPOINTS.DESKTOP)};
		}
	}
	&-57, &-57i {
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(32, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(39.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(36, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(43.2, BREAKPOINTS.DESKTOP)};
        }
    }	
    &-58, &-58i {
        font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(28.8, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(38, BREAKPOINTS.TABLET)};
            line-height:  ${getFontSize(45.6, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(38, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(45.6, BREAKPOINTS.DESKTOP)};
        }
    }    
    &-59, &-59i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        line-height: normal;
        font-weight: ${({theme}) => theme.fontWeight.bold};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(16, BREAKPOINTS.TABLET)};
            //line-height:  ${getFontSize(26.4, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(22, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(26.4, BREAKPOINTS.DESKTOP)};
        }
    }
`
