import styled from "styled-components";
import React, {useCallback, useMemo, useState} from "react";
import useSubscribeToNewsletter, {RESPONSE_MESSAGES} from "@/components/footer/Newsletter/useSubscribeForNewsletter";
import {insetPaddingLeftCss, insetPaddingRightCss} from "@/styles/globalStyles";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Button from "@/components/common/Buttons/Button";
import useGlobals from "@/hooks/useGlobals";
import NewsletterModal from "@/components/footer/Newsletter/NewsletterModal";
import CustomInput, {INPUT_VARIANT} from "@/components/common/CustomInput";
import {ICONS, MODAL_VARIANT} from "@/constants";
import CustomCheckbox, {CHECKBOX_VARIANT} from "@/components/common/CustomCheckbox";
import Text from "@/components/common/Text";

export const NEWSLETTER_VARIANT = {
	DEFAULT: 'default',
	MODAL: 'modal'
}

const StyledWrapper = styled.div`
	background:  ${({theme}) => theme.colors.richBlue};
    color: ${({theme}) => theme.colors.platinum};
	position: relative;
	${insetPaddingRightCss};
    padding-top: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
    padding-bottom: ${getCssSizeValue(40, BREAKPOINTS.MOBILE)};
	
	@media (max-width: ${({theme}) => theme.breakpoints.tablet}px) {
    	${insetPaddingLeftCss};
	}

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        ${insetPaddingLeftCss};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		padding: 
			${getCssSizeValue(58)}
        	0
            ${getCssSizeValue(45)}
            ${getCssSizeValue(113)};
		
		&:before {
			display: none;
		}
    }
	
	&.${NEWSLETTER_VARIANT.MODAL} {
		padding: unset;
	
		.header {
			margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
			padding-top: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
			border-top: 1px solid ${({theme}) => theme.colors.antiflashWhite};
	
			@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
				margin-bottom: ${getCssSizeValue(80)};
				padding-top: ${getCssSizeValue(16)};
			}
		}
	}
`

const StyledHeader = styled.div`
    margin-bottom: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
    
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
    	margin-bottom: ${getCssSizeValue(35)};
    }
`

const Form = styled.form`
    &.${NEWSLETTER_VARIANT.MODAL} {
    	width: 84%;
    }
`

const Row = styled.div`
	display: flex;
	justify-content: flex-end;
	
	&.first {
        margin-bottom: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        	margin-bottom: ${getCssSizeValue(16)};
        }
	}
	
	&.second {
        margin-bottom: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
		
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            margin-bottom: ${getCssSizeValue(24)};
        }
	}
	
	&.third {
		display: flex;
		flex-direction: column;
		gap: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        	flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            flex-direction: column;
			align-items: flex-start;
            gap: ${getCssSizeValue(16)};
		}

        .checkbox-container {
			margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
			align-items: flex-start;
			padding: unset;

            @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            	margin: unset;
			}

            @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
                margin-bottom: ${getCssSizeValue(32)};
			}
			
			.checkbox-box {
				margin-top: ${getCssSizeValue(4)};
			}
		}
	}
`

const INITIAL_FORM_DATA = {
	email: '',
	name: ''
}

export default function Newsletter({ variant = NEWSLETTER_VARIANT.DEFAULT }) {
	const footer = useGlobals(state => state.footer)

	const buttonText = footer?.newsletter?.buttonText || ''
	const emailInputPlaceholder = footer?.newsletter?.emailInputPlaceholder || ''
	const nameInputPlaceholder = footer?.newsletter?.nameInputPlaceholder || ''
	const header = footer?.newsletter?.header || ''
	const consent = footer?.newsletter?.consent || ''

	const [formValues, setFormValues] = useState(INITIAL_FORM_DATA)
	const { subscribe, loading, error, success } = useSubscribeToNewsletter()
	const [isConsentGiven, setIsConsentGiven] = useState(false)
	const [modalOpen, setModalOpen] = useState(false)

	const handleModalOpen = useCallback(() => {
		setModalOpen(true)
	},[])

	const handleChangeFormData = (e) => {
		const { name, value } = e.target;

		setFormValues((prevState) => {
			return { ...prevState, [name]: value }
		})
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		handleModalOpen()
		await subscribe({...formValues, consentContent: consent})
	}

	const handleChangeConsent = async (value) => {
		setIsConsentGiven(value)
	}

	const modalData = useMemo(() => {
		let modalData = footer?.newsletter?.modalSuccess || {}
		if (success) modalData = footer?.newsletter?.modalSuccess || {}
		if (error) {
			switch (error) {
				case RESPONSE_MESSAGES.EMAIL_ALREADY_REGISTERED:
					modalData = footer?.newsletter?.modalEmailTaken || {}
					break;
				case RESPONSE_MESSAGES.SOMETHING_WENT_WRONG:
					modalData = footer?.newsletter?.modalError || {}
					break
			}
		}

		return modalData
	}, [success, error])

	const isSubmitButtonDisabled = useMemo(() => {
		return loading || !isConsentGiven || formValues.email === '' || formValues.name === ''
	}, [formValues.email, formValues.name, isConsentGiven, loading])

	return (
		<StyledWrapper className={variant}>
			<NewsletterModal
				modalOpen={modalOpen}
				setModalOpen={setModalOpen}
				modalData={modalData}
				loading={loading}
			/>
			{variant === NEWSLETTER_VARIANT.DEFAULT && (
				<StyledHeader className='typo-borna typo-borna-69'>{header}</StyledHeader>
			)}
			{variant === NEWSLETTER_VARIANT.MODAL && (
				<Text className="typo-borna typo-borna-70 header">
					{header}
				</Text>
			)}
			<Form onSubmit={handleSubmit} className={variant}>
				<Row className='first'>
					<CustomInput
						name='email'
						icon={ICONS.CONTACT}
						placeholder={emailInputPlaceholder}
						type='email'
						value={formValues.email}
						onChange={handleChangeFormData}
						required
						variant={INPUT_VARIANT.SECONDARY}
					/>
				</Row>
				<Row className='second'>
					<CustomInput
						name='name'
						icon={ICONS.NAME}
						placeholder={nameInputPlaceholder}
						type='text'
						value={formValues.name}
						onChange={handleChangeFormData}
						required
						variant={INPUT_VARIANT.SECONDARY}
					/>
				</Row>
				<Row className='third'>
					<CustomCheckbox
						name='terms'
						agreement={consent}
						checked={isConsentGiven}
						onChange={handleChangeConsent}
						required
						variant={CHECKBOX_VARIANT.SECONDARY}
					/>
					<Button disabled={isSubmitButtonDisabled} type='submit'>{buttonText}</Button>
				</Row>
			</Form>
		</StyledWrapper>
	)
}