import useGlobals from "@/hooks/useGlobals";
import {useBranch} from "@/hooks/useBranch";
import {useMemo} from "react";
import {GLOBAL_STATE} from "@/constants";


export function useBranchData(branch=GLOBAL_STATE){
	const branches = useGlobals('states')
	return useMemo(() => {
		const source = Array.isArray(branches) ? branches : []
		return source.find((item) => {
			const handle = item?.handle?.toLowerCase() || ''
			return handle === branch
		})
	}, [branch, branches])
}
export default function useCurrentBranchData(){
	const { selectedOption: currentBranch } = useBranch()
	return useBranchData(currentBranch)
}
