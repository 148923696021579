import {createGlobalStyle} from 'styled-components';
import {BREAKPOINTS} from './themeSettings';
import {getCssSizeValue} from "@/utils/utils";
import {PADDING} from "@/constants";


const PaddingsStyles = createGlobalStyle`
    .px {
        &-main {
            padding-left: var(--paddingX);
            padding-right: var(--paddingX);
        }
    }


    //Mobile
    :root {
        // --paddingX: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
        --paddingX: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    }
	
	.pt {
		&-${PADDING.EXTRA_SMALL} {
			--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xs, BREAKPOINTS.MOBILE)};
			padding-top: var(--pt);
		}

		&-${PADDING.SMALL} {
			--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].s, BREAKPOINTS.MOBILE)};
			padding-top: var(--pt);
		}

		&-${PADDING.MEDIUM} {
			--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].m, BREAKPOINTS.MOBILE)};
			padding-top: var(--pt);
		}

		&-${PADDING.LARGE} {
			--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].l, BREAKPOINTS.MOBILE)};
			padding-top: var(--pt);
		}

		&-${PADDING.EXTRA_LARGE}{
			--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xl, BREAKPOINTS.MOBILE)};
			padding-top: var(--pt);
		}
	}

	.pb {
		&-${PADDING.EXTRA_SMALL} {
			--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xs, BREAKPOINTS.MOBILE)};
			padding-bottom: var(--pb);
		}

		&-${PADDING.SMALL} {
			--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].s, BREAKPOINTS.MOBILE)};
			padding-bottom: var(--pb);
		}

		&-${PADDING.MEDIUM} {
			--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].m, BREAKPOINTS.MOBILE)};
			padding-bottom: var(--pb);
		}

		&-${PADDING.LARGE} {
			--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].l, BREAKPOINTS.MOBILE)};
			padding-bottom: var(--pb);
		}

		&-${PADDING.EXTRA_LARGE}{
			--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.MOBILE].xl, BREAKPOINTS.MOBILE)};
			padding-bottom: var(--pb);
		}
	}

    //Tablet
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
        :root {
            --paddingX: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
        }
		
		.pt {
			&-${PADDING.EXTRA_SMALL} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xs, BREAKPOINTS.TABLET)};
				padding-top: var(--pt);
			}

			&-${PADDING.SMALL} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].s, BREAKPOINTS.TABLET)};
				padding-top: var(--pt);
			}

			&-${PADDING.MEDIUM} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].m, BREAKPOINTS.TABLET)};
				padding-top: var(--pt);
			}

			&-${PADDING.LARGE} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].l, BREAKPOINTS.TABLET)};
				padding-top: var(--pt);
			}

			&-${PADDING.EXTRA_LARGE}{
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xl, BREAKPOINTS.TABLET)};
				padding-top: var(--pt);
			}
		}

		.pb {
			&-${PADDING.EXTRA_SMALL} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xs, BREAKPOINTS.TABLET)};
				padding-bottom: var(--pb);
			}

			&-${PADDING.SMALL} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].s, BREAKPOINTS.TABLET)};
				padding-bottom: var(--pb);
			}

			&-${PADDING.MEDIUM} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].m, BREAKPOINTS.TABLET)};
				padding-bottom: var(--pb);
			}

			&-${PADDING.LARGE} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].l, BREAKPOINTS.TABLET)};
				padding-bottom: var(--pb);
			}

			&-${PADDING.EXTRA_LARGE}{
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.TABLET].xl, BREAKPOINTS.TABLET)};
				padding-bottom: var(--pb);
			}
		}
    }

    //Desktop
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        :root {
            --paddingX: ${getCssSizeValue(70, BREAKPOINTS.DESKTOP)};
        }
		
		.pt {
			&-${PADDING.EXTRA_SMALL} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xs, BREAKPOINTS.DESKTOP)};
				padding-top: var(--pt);
			}
	
			&-${PADDING.SMALL} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].s, BREAKPOINTS.DESKTOP)};
				padding-top: var(--pt);
			}
	
			&-${PADDING.MEDIUM} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].m, BREAKPOINTS.DESKTOP)};
				padding-top: var(--pt);
			}
	
			&-${PADDING.LARGE} {
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].l, BREAKPOINTS.DESKTOP)};
				padding-top: var(--pt);
			}
	
			&-${PADDING.EXTRA_LARGE}{
				--pt: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xl, BREAKPOINTS.DESKTOP)};
				padding-top: var(--pt);
			}
		}
	
		.pb {
			&-${PADDING.EXTRA_SMALL} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xs, BREAKPOINTS.DESKTOP)};
				padding-bottom: var(--pb);
			}
	
			&-${PADDING.SMALL} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].s, BREAKPOINTS.DESKTOP)};
				padding-bottom: var(--pb);
			}
	
			&-${PADDING.MEDIUM} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].m, BREAKPOINTS.DESKTOP)};
				padding-bottom: var(--pb);
			}
	
			&-${PADDING.LARGE} {
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].l, BREAKPOINTS.DESKTOP)};
				padding-bottom: var(--pb);
			}
	
			&-${PADDING.EXTRA_LARGE}{
				--pb: ${({ theme }) => getCssSizeValue(theme.spacing[BREAKPOINTS.DESKTOP].xl, BREAKPOINTS.DESKTOP)};
				padding-bottom: var(--pb);
			}
		}
    }
`;

export default PaddingsStyles;
