import styled from "styled-components";
import useGlobals from "@/hooks/useGlobals";
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import {PAGES} from "@/constants";
import React from "react";
import LinkButton from "@/components/common/Buttons/LinkButton";
import GridContainer from "@/components/common/GridContainer";
import {useIsTablet} from "@/hooks/useDevice";

const DEFAULT_COLOR = 'richBlack'

const StyledWrapper = styled(GridContainer)`
	align-items: center;
	padding-top: ${getCssSizeValue(48, BREAKPOINTS.MOBILE)};
	gap: 0;
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        padding-top: ${getCssSizeValue(56, BREAKPOINTS.TABLET)};
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		gap: var(--grid-gap);
        padding: ${getCssSizeValue(40)} 0;
    }
`

const StyledMainLink = styled(PreserveSearchParamsLink)`
    color: ${({theme}) => theme.colors.richBlack} !important;
`

const StyledLinksWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	
	.links {
		display: flex;
		flex-direction: column;
		gap: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
		margin-top: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
		margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            align-items: center;
			flex-direction: row;
            margin-top: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
			gap: ${getCssSizeValue(56, BREAKPOINTS.TABLET)};
			margin-bottom: unset;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin: unset;
			gap: ${getCssSizeValue(56)};
		}
	}
`

const CustomText = styled.div`
	text-transform: lowercase;
    --color: ${({ theme, $color }) => $color || theme.colors[DEFAULT_COLOR]};
    --square-scale: 0;
	display: block;
    position: relative;
    color: var(--color);
    height: fit-content;
    justify-content: center;
    align-items: center;
    transition: all var(--rgr-duration) var(--ease);
	
	.text {
        transition: all var(--rgr-duration) var(--ease);
	}

    .square {
		position: absolute;
		top: 50%;
		left: 0;
        transform-origin: left center;
        background-color: ${({ theme }) => theme.colors.chilliRed };
        transition: all var(--rgr-duration) var(--ease);
        opacity: 0;
		transform: translateY(-50%) scale(var(--square-scale));
		width: calc(var(--square-size));
		height: calc(var(--square-size));
		display: block;
    }
	
	&:hover{
		--square-scale: 1;
		--color: black;
        --square-size: ${getFontSize(8,BREAKPOINTS.DESKTOP)};

		.text {
			transform: translateX(calc((var(--square-size) + 5px)));
		}
		
		.square {
			opacity: 1;
		}
	}
`

export default function FooterTopRow() {
	const { footer } = useGlobals()
	const topRowLinks = footer?.topRowLinks || []
	const topRowFeaturedLink = footer?.topRowFeaturedLink || null
	const isTablet = useIsTablet()

	return <StyledWrapper>
		<div className='col-desktop-2 col-mobile-8' style={{ textTransform: 'uppercase' }}>
			<StyledMainLink
				href={`/${topRowFeaturedLink?.link?.slug}` || `/${PAGES.WHAT_WE_DO}`}
				className='typo-borna typo-borna-47'
			>
				{topRowFeaturedLink?.link?.pageName || ''}
			</StyledMainLink>
		</div>
		<StyledLinksWrapper className='col-desktop-8 col-mobile-8 typo-borna typo-borna-48'>
			<div className='links'>
				{topRowLinks.map((link, index) => {
					const id = link?.link?.id || index
					const title = link?.link?.title || ''
					return (
						<PreserveSearchParamsLink
							href={`/${PAGES.WHAT_WE_DO}#${id}`}
							key={id}
						>
							<CustomText>
								<span className='square' />
								<div className='text'>
									{title}
								</div>
							</CustomText>
						</PreserveSearchParamsLink>
					)
				})}
			</div>

			{isTablet && <LinkButton data={footer?.iPayButton}/>}
		</StyledLinksWrapper>

		{!isTablet && (
			<div className='col-desktop-2'>
				<LinkButton data={footer?.iPayButton}/>
			</div>
		)}
	</StyledWrapper>
}
