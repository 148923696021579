import {LOCALES} from "@/constants";
import {useRouter} from "next/router";
import {useCallback, useEffect, useLayoutEffect, useState} from "react";

const voicesLangMap = {
	[LOCALES.PL]: 'pl-PL',
	[LOCALES.DE]: 'de-DE',
	[LOCALES.EN]: 'en-US'
};

const getVoice = (voices, locale) => {
	const voiceLang = voicesLangMap[locale];
	return voices.find(voice => voice?.lang === voiceLang);
};

const getSpeechSynthesis = () => ('speechSynthesis' in window) ? window.speechSynthesis : null;

export const useScreenReader = (enabled = false) => {
	const { locale } = useRouter()
	const [clickedElement, setClickedElement] = useState(null);

	const speak = useCallback((text) => {
		const speechSynthesis = getSpeechSynthesis();
		if (speechSynthesis) {
			speechSynthesis.cancel();
			const voicesList = speechSynthesis.getVoices();
			const voice = getVoice(voicesList, locale);
			const utterance = new SpeechSynthesisUtterance(text);
			utterance.voice = voice;
			speechSynthesis.speak(utterance);
		}
	}, [locale])

	const handleClick = useCallback((event) => {
		if (!enabled) return
		const { target } = event;
		const text = target.innerText || target.alt || target.value || '';

		if (text) {
			speak(text);
		}

		if (clickedElement) {
			clickedElement.style.border = '';
		}

		target.style.border = '1px solid red';
		setClickedElement(target);
	}, [clickedElement, speak, enabled])

	useEffect(() => {
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, [clickedElement, handleClick, speak]);

	useEffect(() => {
		const speechSynthesis = getSpeechSynthesis();
		if (speechSynthesis?.pending || speechSynthesis?.speaking) {
			speechSynthesis.cancel();
		}
	}, [locale]);
};