import React from 'react';
import Text from "@/components/common/Text";

const SpotImageSecondHeader = ({data, desc, color}) => {
	return (
		<>
			<Text tag="div" className="typo-borna typo-borna-96 description" color={color} dangerouslySetInnerHTML={desc}/>
		</>
	);
};

export default SpotImageSecondHeader;
