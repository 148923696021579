import React from 'react';
import Text from "@/components/common/Text";
import styled from "styled-components";
export const BOX_VARIANTS = {
	DEFAULT: 'default',
	TRANSPARENT: 'transparent'
}

const Box = styled.div`
	--bg-color: ${({theme}) => theme.colors.antiflashWhite};
	background: var(--bg-color);
	
	&.${BOX_VARIANTS.TRANSPARENT} {
        --bg-color: transparent;
		border: 1px solid ${({ theme }) => theme.colors.richbBlack};

		.text {
			span[style= "text-decoration: line-through;"] {
				display: inline;
				background-color: transparent !important;
				color: ${({ theme }) => theme.colors.chilliRed} !important;
				font-weight: ${({ theme }) => theme.fontWeight.bold};
    	    }
		}
	}
`


const BoxDescription = ({ desc, color, boxText, boxVariant }) => {
	return (
		<>
			<Text tag="div" className="typo-borna typo-borna-11 description" color={color} dangerouslySetInnerHTML={desc}/>
			<Box className={`box ${boxVariant}`}>
				<Text tag="div" className='typo-strawford typo-strawford-67' dangerouslySetInnerHTML={boxText} />
			</Box>
		</>
	);
};

export default BoxDescription;