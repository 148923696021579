import React, {useRef} from 'react';
import styled from "styled-components";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import {isReactRef, precise, setCssVars} from "@/utils/utils";
import useColor from "@/hooks/useColor";

const Span = styled.span`
	--height: 1px;
	--size: ${({ $size }) => $size || 2};
    position: relative;
    display: block;
    width: 100%;
    height: var(--height);
    background-color: ${({ theme }) => theme.colors.black};
    transition: all 0.35s ease-in-out;
    transform-origin: left;
	clip-path: inset(calc(var(--size) * -1 * var(--height)) 0);

    &:after {
		display: none;
		
        background-color: ${({ $color }) => $color || 'black'};
        content: ' ';
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0;
        transform: translateX(100%);
		transition: all 0.35s ease-in-out;
        width: 100%;
        height: inherit;
        transform-origin: left;
    }
	
	&.inverse{
        &:after {
            right: 0;
            transform: translateX(-100%);
            transform-origin: right;
            //transform: translateX(-100%);
        }
	}

    &.active{
        &:after {
            opacity: 1;
            transform: translateX(0) scaleX(var(--target-scale, 1)) scaleY(var(--size));
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) and (orientation: landscape){
        &:after {
			display: block;
        }
    }
`

const LineBreak = ({ active, target, color='chilliRed', inverse=false , size=6, className='',...props }) => {
	const BreakRef = useRef()
	const cl = useColor(color)

	useIsomorphicLayoutEffect(() => {
		if(!isReactRef(target) || !target?.current) return
		const { width: targetWidth } = target.current.getBoundingClientRect()
		const { width: breakWidth } = BreakRef.current.getBoundingClientRect()
		const scale = precise(targetWidth / breakWidth)
		setCssVars(BreakRef.current, {
			'target-scale': scale,
		})
	},[active])

	return (
		<Span
			ref={BreakRef}
			$color={cl}
			$size={size}
			className={`line-break ${active ? 'active' : ''} ${inverse ? 'inverse' : ''} ${className}`}
			{...props}
		/>
	);
};

export default LineBreak;
