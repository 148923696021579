import {CONTENT_TYPES} from "@/constants";
import {getItemType, getItemUrl} from "@/utils/utils";

export function getLinkData(data){
	const id = data?.link?.link?.id || ''
	const text = data?.text ||''
	const typename = getItemType(data?.link?.link)
	const isExternal =getItemType(data?.link) === CONTENT_TYPES.EXTERNAL_LINK;
	const isScrollTo = getItemType(data?.link) === CONTENT_TYPES.SCROLL_LINK_RECORD;
	const slug = data?.link?.link?.slug || ''
	const pageType = data?.link?.link?.pageType || '';
	const newCard = !isExternal ? 'no' : data?.link?.newCard || 'no'
	const externalLink = !isExternal ? '' : data?.link?.url || '';
	const ariaLabel = data?.link?.label || '';
	const pageName = data?.link?.link?.pageName || '';

	let url = ''
	if(isExternal){
		url = externalLink
	} else {
		const item = data?.link?.link || data?.link
		url = getItemUrl(item)
	}

	return {
		id,
		text,
		typename,
		isExternal,
		slug,
		pageType,
		newCard,
		externalLink,
		ariaLabel,
		pageName,
		url,
		isScrollTo
	}
}

export function getLinkAriaLabel(props){
	const {  ['aria-label']: label, ...rest } = props
	return {
		label: label || 'link',
		rest
	}
}
