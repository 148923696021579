import React, {createContext} from 'react';
import useCustomContext from "@/hooks/useCustomContext";


const INIT_CONTEXT = {}

const GlobalsContext = createContext(INIT_CONTEXT);

export const GlobalsProvider = ({ data={}, children }) => {
	// const memoData = useMemo(() => {
	// 	const modalData = Object.keys(data).
	// 	console.log(modalData);
	// 	return data
	// }, [data])
	return (
		<GlobalsContext.Provider value={data}>
			{children}
		</GlobalsContext.Provider>
	)
}

export default function useGlobals(selector) {
	return useCustomContext(GlobalsContext, selector)
}


