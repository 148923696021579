const filters = [
	{ label: 'Invert Colors', filter: 'invert(100%)' },
	{ label: 'Monochrome', filter: 'grayscale(100%)' },
	{ label: 'Dark Contrast', filter: 'contrast(200%)' },
	{ label: 'Light Contrast', filter: 'contrast(10%)' },
	{ label: 'Low Saturation', filter: 'saturate(50%)' },
	{ label: 'High Saturation', filter: 'saturate(200%)' }
];

const rangeConfigs = [
	{ label: 'Content scaling', cssVar: 'zoom', min: 60, max: 140, step: 5, defaultValue: 100, divider: 100 },
	{ label: 'Font Size', cssVar: 'typoSize', min: 60, max: 140, step: 5, defaultValue: 100, divider: 100 },
];

export {
	filters,
	rangeConfigs
}