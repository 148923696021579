import React, {useState} from 'react';
import styled from 'styled-components';
import {getCssSizeValue, getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import Icon from "@/components/common/Icon/Icon";
import {ICONS} from "@/constants";
import isError from "next/dist/lib/is-error";

export const INPUT_VARIANT = {
	PRIMARY: 'primary',
	SECONDARY: 'secondary'
}

const CustomInputWrapper = styled.div`
    position: relative;
    background-color: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
        //margin-right: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
    padding: 0 16px;
    border: 1px solid var(--color);
    width: 100%;
    height: 40px;
    min-height: 40px;
    gap: ${getCssSizeValue(10, BREAKPOINTS.DESKTOP)};

    --color: ${({theme}) => theme.colors.richBlack};

    &.error {
        border: 2px solid ${({theme}) => theme.colors.chilliRed};
    }

    .icon {
        width: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
        aspect-ratio: 1;
		margin-right: ${getCssSizeValue(20, BREAKPOINTS.MOBILE)};
    }

    p {
        color: var(--color);
        font-weight: ${({theme}) => theme.fontWeight.medium} !important;
    }

    svg {
        path, circle {
            fill: var(--color) !important;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        height: 50px;
        min-height: 50px;
    }
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        .icon {
            width: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
        }

        width: 100%;
        height: ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)};
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            left: 0;
            transition: transform 0.3s ease-in-out;
            height: 100%;
            z-index: -1;
            background-color: inherit;
        }
    }

    &.textarea {
        align-items: flex-start;
		height: 100px;
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			height: 200px;
		}


        .icon {
			translate: 0 16px;
        }
    }

    &.${INPUT_VARIANT.PRIMARY} {
        --color: ${({theme}) => theme.colors.richBlack};
    }

    &.${INPUT_VARIANT.SECONDARY} {
        --color: ${({theme}) => theme.colors.slateGray};
    }
`

const StyledInput = styled.input`
	background-color: transparent;
	--color: ${({ theme }) => theme.colors.richBlack};
	--placeholderColor: ${({ theme }) => theme.colors.richBlack};
	color: var(--color);
	caret-color: var(--color);
	outline: none;
	border: none;
	width: 100%;
	font-family: ${({theme}) => theme.fontFamily.StrawfordFont} !important;
	font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
	line-height:${getFontSize(16.8, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.bold} !important;
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
	}
	@media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
		font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
	}
	&::placeholder {
        color: var(--placeholderColor);
		font-family: ${({theme}) => theme.fontFamily.StrawfordFont} !important;
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
        line-height:${getFontSize(16.8, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular} !important;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.TABLET)};
			font-weight: ${({theme}) => theme.fontWeight.medium};
        }
        @media (min-width: ${({theme}) => theme.breakpoints.desktop}px) {
            font-size: ${getFontSize(18, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(21.6, BREAKPOINTS.DESKTOP)};
        }
	}
	
	&.textarea {
		padding-top: 14px;
		height: 200px;

	}
	
	&.${INPUT_VARIANT.PRIMARY} {
		--color: ${({ theme }) => theme.colors.richBlack};
        --placeholderColor: ${({ theme }) => theme.colors.richBlack};

        &::placeholder {
			
		}
	}
	
	&.${INPUT_VARIANT.SECONDARY} {
        --color: ${({ theme }) => theme.colors.platinum};
        --placeholderColor: ${({ theme }) => theme.colors.slateGray};
		
	}
`;

const StyledErrorText = styled.div`
	position: absolute;
	top: 100%;
	right: 0;
	color: ${({ theme }) => theme.colors.chilliRed};
	font-size: 12px;
	margin-top: 4px;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
	}
	@media(min-width: 1440px) {
		font-size: 12px;
	}
`;

const CustomInput = ({
	name,
	className,
	placeholder,
	type,
	value,
	required,
	onChange,
	validate,
	pattern = null,
	errorMessage,
	maxlength = 50,
	icon=ICONS.LOUPE,
	iconColor = 'richBlue',
	onceSubmitted= false,
	isTextarea = false,
	variant = INPUT_VARIANT.PRIMARY,
	locale
}) => {
	const [isTouched, setIsTouched] = useState(false);
	const showError =
		required &&
		isTouched &&
		validate &&
		value !== undefined &&
		value !== null &&
		!validate(value);
	// const showError = (onceSubmitted || isTouched) && validate && !validate(value);
	const handleBlur = () => {
		setIsTouched(true);
	};

	return (
		<CustomInputWrapper className={`input ${showError ? 'error' : ''} ${variant} ${isTextarea ? 'textarea' : ''}`}>
			<Icon name={icon} color={iconColor}/>
			<StyledInput
				className={`${className} ${variant} ${isTextarea ? 'textarea' : ''}`}
				type={type}
				name={name}
				value={value}
				placeholder={placeholder}
				aria-label={placeholder}
				onChange={onChange}
				required={required}
				pattern={pattern}
				maxLength={maxlength}
				onBlur={handleBlur}
				as={isTextarea ? 'textarea' : 'input'}
				rows="4"
			/>
			{showError && <StyledErrorText className='typo-borna'>{errorMessage}</StyledErrorText>}
		</CustomInputWrapper>
	);
};

export default CustomInput;
