import {css} from 'styled-components'
import {getFontSize} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";

export default css`
    &-70, &-70i {
		font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(64.4, BREAKPOINTS.DESKTOP)};
		}
	}
	&-71, &-71i {
		font-size: ${getFontSize(24, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(31.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.medium};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(38, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(53.2, BREAKPOINTS.DESKTOP)};
		}
	}
	&-72, &-72i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(21, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(27, BREAKPOINTS.DESKTOP)};
		}
	}
	&-73, &-73i {
		font-size: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(16, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.bold};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
		}
	}
	&-74, &-74i {
		font-size: ${getFontSize(14, BREAKPOINTS.MOBILE)};
		line-height: ${getFontSize(25.2, BREAKPOINTS.MOBILE)};
		font-weight: ${({theme}) => theme.fontWeight.regular};
		@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
			font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(32.4, BREAKPOINTS.TABLET)};
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			font-size: ${getFontSize(16, BREAKPOINTS.DESKTOP)};
			line-height: ${getFontSize(25, BREAKPOINTS.DESKTOP)};
		}
	}	
    &-75, &-75i {
        font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.medium};
        line-height:${getFontSize(21.6, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(33.8, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(33.8, BREAKPOINTS.DESKTOP)};
        }
	}    
    &-76, &-76i {
        font-size: ${getFontSize(12, BREAKPOINTS.MOBILE)};
        font-weight: ${({theme}) => theme.fontWeight.regular};
        line-height:${getFontSize(18, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(15, BREAKPOINTS.TABLET)};
            line-height: ${getFontSize(22.5, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(15, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(22.5, BREAKPOINTS.DESKTOP)};
        }
	}
    &-77, &-77i {
        font-weight: ${({theme}) => theme.fontWeight.semiBold};
        font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
        line-height: ${getFontSize(28.6, BREAKPOINTS.MOBILE)};
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(26, BREAKPOINTS.MOBILE)};
            line-height: ${getFontSize(28.6, BREAKPOINTS.MOBILE)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(46, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(50.6, BREAKPOINTS.DESKTOP)};
        }
	}
    &-78, &-78i {
        font-weight: ${({theme}) => theme.fontWeight.regular};
        font-size: ${getFontSize(56, BREAKPOINTS.MOBILE)};
        line-height: 100%;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(126, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(124, BREAKPOINTS.DESKTOP)};
        }
	}
	&-79, &-79i {
        font-weight: ${({theme}) => theme.fontWeight.medium};
        font-size: ${getFontSize(13, BREAKPOINTS.MOBILE)};
        line-height: 100%;
        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            font-size: ${getFontSize(14, BREAKPOINTS.TABLET)};
        }
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
            font-size: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
            line-height: ${getFontSize(14, BREAKPOINTS.DESKTOP)};
        }
	}
`
