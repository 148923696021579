import React from 'react';
import {Hits, useHits, Configure} from 'react-instantsearch';
import SingleResultItem from "@/components/search/SingleResultItem";
import GridContainer from "@/components/common/GridContainer";
import styled from "styled-components";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {getCssSizeValue, getFontSize, getHtmlFromRedactorField} from "@/utils/utils";
// import BothMaps from "@/components/Maps/BothMaps";
import useGlobals from "@/hooks/useGlobals";
import {useIsDesktop} from "@/hooks/useDevice";
// import NoResultsLottie from "@/components/noResultsLottie/NoResultsLottie";
import * as animationDataGray from "@/assets/lottie/animacja-szukajka-pck-gray.json";
import * as animationDataWhite from "@/assets/lottie/animacja-szukajka-pck-white.json";
import {getLinkData} from "@/components/common/Links/utils";
import {useRouter} from "next/router";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

const ResultsContainer = styled(GridContainer)`
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 0;
	transition: max-height 0.3s ease-in-out;
	transform-origin: top;
	margin-left: 0;
	margin-right: 0;
	width: 100%;
	position: absolute;
	top: 88px;
	background-color: ${({theme}) => theme.colors.antiflashWhite};
	.ais-Hits-item {
		background-color: transparent;
		//padding-right: 0;
		list-style-type: none !important;
		padding: 0;
		
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		top: 200px;
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		position: unset;
		top: unset;
		margin-left: var(--grid-margin-l);
		margin-right: var(--grid-margin-r);
		width: unset;
		.ais-Hits-item {
			background-color: transparent;
			margin-left: initial;
			margin-right: initial;
			//padding-right: 0;
			list-style-type: none !important;
			padding: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)} 0 ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)} ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};

		}	
	}
	::-webkit-scrollbar {
		display: none;
	}
	&.visible {
		transition: max-height 0.3s ease-in-out 0.4s;
		transform-origin: top;
		max-height: 100vh;
		min-height: 100vh;
		@media(min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			//max-height: 75vh;
			max-height: calc(80vh - ${getCssSizeValue(200, BREAKPOINTS.TABLET)} - 40px);
			min-height: unset;
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			max-height: calc(80vh - ${getCssSizeValue(220, BREAKPOINTS.DESKTOP)} - ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)} - 40px);
			min-height: unset;
		}
	}
`

const ResultsWrapper = styled.div`
	width: 100%;
	height: 100%;
`

const NoQueryContentContainer = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	max-height: 0;
	transition: max-height 0.3s ease-in-out;
	//border: 1px solid red;
	&.visible {
		max-height: 100vh;
		height: 100vh;
		transform-origin: top;
		transition: max-height 0.3s ease-in-out 0.6s;
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
			max-height: calc(75vh - ${getCssSizeValue(200, BREAKPOINTS.TABLET)} - ${getCssSizeValue(50, BREAKPOINTS.TABLET)} - 40px);
		}
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			max-height: calc(80vh - ${getCssSizeValue(220, BREAKPOINTS.DESKTOP)} - ${getCssSizeValue(50, BREAKPOINTS.DESKTOP)} - 40px);
			height: 500px;
			padding: 0;
		}
	}
`

const NoQueryContentWrapper = styled(GridContainer)`
	//position: absolute;
	top: 20px;
	left: 20px;
	//width: 100%;
	//display: flex;
	//flex-direction: column;
	//justify-content: flex-start;
	//padding-right: 100px;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		left: 32px;
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		//flex-direction: row;
		//justify-content: flex-end;
		//padding-right: 100px;
		top: 0;
		left: 0;
	}
	p {
		margin-right: 10vw;
	}
	section {
		margin-bottom: 40px;
		@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin-bottom: 0;
		}
	}
	// >div {
	// 	display: flex;
	// 	width: 100%;
	// 	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
	// 		width: 50%;
	// 	}
	// 	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
	// 		width: 400px;
	// 	}
	// }
`

const NoResultsText = styled.div`
	color: ${({theme}) => theme.colors.richBlack};
	&.has-query {
		color:  ${({theme}) => theme.colors.chilliRed};
	}
`

const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
`

const VioletBackground = styled.div`
	position: absolute;
	height: ${getCssSizeValue(140, BREAKPOINTS.MOBILE)};
	right: 0;
	top: 0;
	width: 100vw;
	background-color: ${({theme}) => theme.colors.periwinkle};
	z-index: 0;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		height: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
	}
`

const BottomContainer = styled.div`
	margin-top: ${getCssSizeValue(100, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-top: 0;
	}
`

const VioletContainer = styled.div`
	background-color: ${({theme}) => theme.colors.periwinkle};
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: ${getCssSizeValue(140, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		padding-top: ${getCssSizeValue(32, BREAKPOINTS.DESKTOP)};
		padding-bottom: ${getCssSizeValue(32, BREAKPOINTS.DESKTOP)};
		height: ${getCssSizeValue(140, BREAKPOINTS.DESKTOP)};
		margin-bottom: ${getCssSizeValue(80, BREAKPOINTS.DESKTOP)};
	}
`

const BoldHeader = styled.div`
	font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.bold};
	line-height:${getFontSize(21.6, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		font-size: ${getFontSize(26, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(33.8, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		font-size: ${getFontSize(26, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(33.8, BREAKPOINTS.DESKTOP)};
		margin-bottom: ${getCssSizeValue(8, BREAKPOINTS.DESKTOP)};
	}
`

const MediumText = styled.div`
	font-size: ${getFontSize(18, BREAKPOINTS.MOBILE)};
	line-height:${getFontSize(21, BREAKPOINTS.MOBILE)};
	font-weight: ${({theme}) => theme.fontWeight.medium};
	@media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		font-size: ${getFontSize(18, BREAKPOINTS.TABLET)};
		line-height: ${getFontSize(21, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		font-size: ${getFontSize(20, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(34, BREAKPOINTS.DESKTOP)};
	}
`

const LottieWrapper = styled.div`
	display: none;
	aspect-ratio: 642/407;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		display: block;
		grid-column: 12/17;
	}
`
const GlobalLinksContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	column-gap: 0;
	margin-top: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		column-gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
		grid-template-columns: repeat(8, 1fr);
		margin-top: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
	}
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		margin-top: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
		grid-template-columns: repeat(6, 1fr);
		column-gap: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
	}
`

const GlobalLinksWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(2, 1fr);
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.TABLET]}px) {
		column-gap: ${getCssSizeValue(24, BREAKPOINTS.TABLET)};
	}
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
         display: grid;
         grid-template-columns: 1fr 1fr;
         grid-template-rows: repeat(2, 1fr);
		 column-gap: ${getCssSizeValue(24, BREAKPOINTS.DESKTOP)};
    }
`

const SingleGlobalLink = styled.div`
    border: 1px solid ${({theme, bgColor}) => theme.colors[bgColor]};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out;
    background-color: transparent;
    color: ${({theme, bgColor}) => theme.colors[bgColor]};
    font-size: ${getFontSize(15, BREAKPOINTS.MOBILE)};
    line-height: ${getFontSize(19.5, BREAKPOINTS.MOBILE)};
    font-weight: ${props => props.theme.fontWeight.semiBold};
	padding-left: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	height: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
    &.last {
        background-color: ${({theme}) => theme.colors.chilliRed};
        color: ${({theme}) => theme.colors.white};

        img.white {
            opacity: 1 !important;
        }

        img.dark {
            opacity: 0 !important;
        }

        &:hover {
            transition: background-color 0.2s ease-in-out;
            background-color: ${({theme}) => theme.colors.pennRed} !important;
        }
    }

    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        padding-left: ${getCssSizeValue(16, BREAKPOINTS.DESKTOP)};
		height: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
		font-size: ${getFontSize(15, BREAKPOINTS.DESKTOP)};
		line-height: ${getFontSize(19.5, BREAKPOINTS.DESKTOP)};
    }

    &:last-child, &:nth-child(3) {
        border-bottom: 1px solid ${({theme, bgColor}) => theme.colors[bgColor]};
    }

    .light {
        img.white {
            opacity: 0;
            transition: opacity 0.2s ease-in-out
        }

        img.black {
            opacity: 1;
            transition: opacity 0.2s ease-in-out
        }
    }

    .dark {
        img.white {
            opacity: 1;
            transition: opacity 0.2s ease-in-out
        }

        img.black {
            opacity: 0;
            transition: opacity 0.2s ease-in-out
        }
    }

    img {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out !important;
        transform: translate(-50%, -50%) scale(1);
    }

    &:hover {
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out !important;
        background-color: ${({theme}) => theme.colors.periwinkle};
        color: ${({theme}) => theme.colors.white};

        img {
            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out !important;
            transform: translate(-50%, -50%) scale(1.1);
        }

        .light {
            img.white {
                opacity: 1;
                transition: opacity 0.2s ease-in-out
            }

            img.black {
                opacity: 0;
                transition: opacity 0.2s ease-in-out
            }
        }

        .dark {
            img.white {
                opacity: 1;
                transition: opacity 0.2s ease-in-out
            }

            img.black {
                opacity: 0;
                transition: opacity 0.2s ease-in-out
            }
        }
    }
`

const GlobalLinkIconWrapper = styled.div`
    min-width: ${getCssSizeValue(60, BREAKPOINTS.MOBILE)};
        // height: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
    height: 100%;
    position: relative;
	border-left: 1px solid ${({theme}) => theme.colors.richBlack};
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		min-width: ${getCssSizeValue(60, BREAKPOINTS.DESKTOP)};
	}
`

const IconLocal = styled.img`
    width: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		width: 24px;
	}
`

const HitsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		grid-template-columns: repeat(16, 1fr);
	}
`

const InnerHitsWrapper = styled.div`
	grid-column: 1/9;
	@media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
		grid-column: 4/17;
	}
`


const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationDataWhite,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice'
	}
};

const SearchResults = ({indices, className, locale, show, hasQuery}) => {
	const { hits } = useHits();
	const noResults = hits.length === 0;
	const globals = useGlobals();
	const noQueryText = globals?.noQueryText || '';
	const noResultsText = globals?.noResultsText || '';
	const searchSuggestionsText = globals?.searchSuggestionsText || '';
	const noResultsHeader = globals?.noResultsHeader || '';
	const noResultsSubheader = globals?.noResultsSubheader || '';
	const isDesktop = useIsDesktop();
	const router = useRouter();
	defaultOptions.animationData = animationDataGray;
	const seeAlsoFixedLinks = useGlobals('seeAlsoFixedLinks');
	const globalLinks = Array.isArray(seeAlsoFixedLinks) ? seeAlsoFixedLinks : []
	return (
		<ResultsContainer className={show ? 'visible' : ''}>
			<ResultsWrapper className='indent-desktop-3' style={isDesktop ? { gridColumnStart: 1, gridColumnEnd: 17 }: { gridColumnStart: 1, gridColumnEnd: 9 }}>
				{/*<NoResultsText className='typo-borna typo-borna-18'>Wpisz zapytanie</NoResultsText>*/}
				{/*{!noResults && show && hasQuery && <Hits className="Hits" hitComponent={SingleResultItem} />}*/}
				<NoQueryContentContainer className={show && !hasQuery ? 'visible' : ''}>
					<NoQueryContentWrapper className='indent-desktop-3 no-margin-desktop'>
						<LeftContainer
							className='col-desktop-6 col-mobile-8 col-tablet-8'
						>
							<VioletBackground/>
							<VioletContainer>
								<BoldHeader className='typo-borna'>{noResultsText}</BoldHeader>
								<MediumText className='typo-strawford' dangerouslySetInnerHTML={getHtmlFromRedactorField(noQueryText)}/>
							</VioletContainer>
							<div>
								<BottomContainer>
									<BoldHeader className='typo-borna'>{searchSuggestionsText}</BoldHeader>
								</BottomContainer>
								<GlobalLinksContainer>
									<GlobalLinksWrapper className='col-desktop-4 col-tablet-4 col-mobile-6'>
										{globalLinks.map((item, key) => {
											const id = item?.link?.id || '';
											const pageName = item?.link?.link?.pageName || '';
											const pictogramBlackUrl = item?.pictogramBlack?.url || '';
											const pictogramWhiteUrl = item?.pictogramWhite?.url || '';
											const {url} = getLinkData(item.link);
											return (
												<SingleGlobalLink
													isLast={key === globalLinks.length - 1}
													bgColor={'richBlack'}
													onClick={() => router.push(url)}
													className={`dark ${key === globalLinks.length - 1 ? 'last' : ''} typo-borna`}
													key={id}
												>{pageName}
													<GlobalLinkIconWrapper className='light'>
														<IconLocal className='black' src={pictogramBlackUrl} alt='pictogram-black'/>
														<IconLocal className='white' src={pictogramWhiteUrl} alt='pictogram-white'/>
													</GlobalLinkIconWrapper>
												</SingleGlobalLink>
											)
										})}
									</GlobalLinksWrapper>
								</GlobalLinksContainer>
							</div>
						</LeftContainer>
						{/*<section className='typo-strawford typo-strawford-22' dangerouslySetInnerHTML={getHtmlFromRedactorField(noQueryText)}/>*/}
						<LottieWrapper className='gray'>
							<Lottie options={defaultOptions} isClickToPauseDisabled ariaRole={"generic"} />
						</LottieWrapper>
					</NoQueryContentWrapper>
				</NoQueryContentContainer>
				{/*{noResults && hasQuery && <NoResultsText className='typo-borna typo-borna-18 has-query'>{noResultsText}</NoResultsText>}*/}
				{/*{noResults && hasQuery && <NoResultsLottie variant='gray'/>}*/}
				{noResults && hasQuery && (
					<NoQueryContentContainer className={'visible'}>
						<NoQueryContentWrapper className='indent-desktop-3 no-margin-desktop'>
							<LeftContainer
								className='col-desktop-6 col-mobile-8 col-tablet-8'
							>
								<VioletBackground/>
								<VioletContainer>
									<BoldHeader className='typo-borna'>{noResultsText}</BoldHeader>
									<MediumText className='typo-strawford' dangerouslySetInnerHTML={getHtmlFromRedactorField(noResultsSubheader)}/>
								</VioletContainer>
								<div>
									<BottomContainer>
										<BoldHeader className='typo-borna'>{searchSuggestionsText}</BoldHeader>
									</BottomContainer>
									<GlobalLinksContainer>
										<GlobalLinksWrapper className='col-desktop-4 col-tablet-4 col-mobile-6'>
											{globalLinks.map((item, key) => {
												const id = item?.link?.id || '';
												const pageName = item?.link?.link?.pageName || '';
												const pictogramBlackUrl = item?.pictogramBlack?.url || '';
												const pictogramWhiteUrl = item?.pictogramWhite?.url || '';
												const {url} = getLinkData(item.link);
												return (
													<SingleGlobalLink
														isLast={key === globalLinks.length - 1}
														bgColor={'richBlack'}
														onClick={() => router.push(url)}
														className={`dark ${key === globalLinks.length - 1 ? 'last' : ''} typo-borna`}
														key={id}
													>{pageName}
														<GlobalLinkIconWrapper className='light'>
															<IconLocal className='black' src={pictogramBlackUrl} alt='pictogram-black'/>
															<IconLocal className='white' src={pictogramWhiteUrl} alt='pictogram-white'/>
														</GlobalLinkIconWrapper>
													</SingleGlobalLink>
												)
											})}
										</GlobalLinksWrapper>
									</GlobalLinksContainer>
								</div>
							</LeftContainer>
							{/*<section className='typo-strawford typo-strawford-22' dangerouslySetInnerHTML={getHtmlFromRedactorField(noQueryText)}/>*/}
							<LottieWrapper className='gray'>
								<Lottie options={defaultOptions} isClickToPauseDisabled ariaRole={"generic"} />
							</LottieWrapper>
						</NoQueryContentWrapper>
					</NoQueryContentContainer>
				)}
				{/*{!noResults && show && hasQuery && <Hits className="Hits" hitComponent={SingleResultItem} />}*/}
				{!noResults && show && hasQuery && (
					<HitsContainer>
						<InnerHitsWrapper>
							<Configure hitsPerPage={100}/>
							<Hits className="Hits"  hitComponent={SingleResultItem} />
						</InnerHitsWrapper>
					</HitsContainer>
				)}
			</ResultsWrapper>
		</ResultsContainer>
	);
};

export default SearchResults;
