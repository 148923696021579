import {gsap} from 'gsap/dist/gsap';
import {EASE} from "@/constants";

//PLUGINS
import {useGSAP} from '@gsap/react';
import {ScrollTrigger} from 'gsap/dist/ScrollTrigger';
import {CustomEase} from 'gsap/dist/CustomEase';
import {ScrambleTextPlugin} from 'gsap/dist/ScrambleTextPlugin';
import {Draggable} from 'gsap/dist/Draggable';
import {InertiaPlugin} from 'gsap/dist/InertiaPlugin';
import {DrawSVGPlugin} from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin)
gsap.registerPlugin(DrawSVGPlugin)


const golden_ratio = (1 + Math.sqrt(5)) / 2;
const reciprocal_gr = 1 / golden_ratio; //odwrócone golden ratio 0.6180339887498948
const customEase = CustomEase.create('ease', EASE.join(', '));

const duration = reciprocal_gr; //s
const delay = duration / 4
const eases = {
	custom: customEase,
	main: 'sine.inOut',
	easeOut: 'power2.out',
	easeInOut: 'power2.inOut',
};

// Configuring GSAP with custom settings that aren't Tween-specific
gsap.config({
	autoSleep: 60,
	nullTargetWarn: false,
});

// Setting default animation properties that should be inherited by ALL tweens
gsap.defaults({
	duration,
	ease: eases.custom
});

// Once the desired configurations are set, we simply export what we need to work with in the future.
export {
	duration,
	delay,
	golden_ratio,
	gsap,
	ScrollTrigger,
	eases,
	useGSAP,
	Draggable,
	InertiaPlugin
};
