import React from 'react';
import styled from 'styled-components';
import FooterTopRow from "@/components/footer/FooterTopRow";
import GridContainer from "@/components/common/GridContainer";
import Transfers from "@/components/footer/Transfers";
import SiteMap from "@/components/footer/SiteMap";
import NewsletterAndSocials from "@/components/footer/NewsletterAndSocials";
import {getCssSizeValue} from "@/utils/utils";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {useIsDesktop, useIsMobile} from "@/hooks/useDevice";
import LogoWithSlogan from "@/components/footer/LogoWithSlogan";

const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
	color: ${({theme}) => theme.colors.richBlack};
    background: ${({theme}) => theme.colors.platinum};
`

const StyledRow = styled(GridContainer)`
    flex-grow: ${props => props.$grow ? 1 : 0};
	.line-row {
		height: 1px;
        background: ${({theme}) => theme.colors.slateGray};
		transform: translateY(2px);
        margin-top: ${getCssSizeValue(56, BREAKPOINTS.MOBILE)};

        @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
            margin-top: ${getCssSizeValue(64, BREAKPOINTS.TABLET)};
		}
		
        @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
			margin: unset;
		}
	}
`

const Footer = () => {
	const isDesktop = useIsDesktop()
	const isMobile = useIsMobile()

	return (
		<>
			<FooterContainer>
				<FooterTopRow />
				<StyledRow className={`${!isDesktop ? 'no-margin' : ''}`}>
					<div className={`col-mobile-8 col-tablet-8 col-desktop-10 line-row`} />
				</StyledRow>
				<StyledRow>
					<Transfers/>
					{!isMobile && <div className="col-desktop-1"/>}
					<SiteMap/>
					<NewsletterAndSocials/>
				</StyledRow>
			</FooterContainer>
			<LogoWithSlogan />
		</>
	);
};

export default Footer;