import {useBranch} from '@/hooks/useBranch';
import SingleSelect from "@/components/selectors/SingleSelect";
import {DROPDOWN_VARIANTS} from "@/components/common/Dropdown";
import {isFunction} from "@/utils/utils";

const BranchSelect = ({ onChange=(e)=>{}, ...props }) => {
	const { selectedOption, setSelectedOption, options } = useBranch();

	const selectedOptionObject = options.find(option => option.value === selectedOption);

	const handleChange = (event) => {
		const newValue = event.target.value;
		setSelectedOption(newValue);
		if(isFunction(onChange)){
			onChange(event)
		}
	};


	return (
		<SingleSelect
			variant={DROPDOWN_VARIANTS.TERTIARY}
			options={options}
			selectedValue={selectedOptionObject}
			onChange={handleChange}
			placeholder="Choose branch"
			{...props}
		/>

	);
};

export default BranchSelect;
