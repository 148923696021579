import {forwardRef, memo, useImperativeHandle, useMemo, useRef} from 'react';
import styled from 'styled-components';
import {delay, gsap, useGSAP} from '@/utils/gsap';
import useInView from "@/hooks/useInView";
import useIsomorphicLayoutEffect from "@/hooks/useIsomorphicLayoutEffect";
import useIsDebug from "@/hooks/useIsDebug";


//TODO: Implement direction handling

const Parent = styled.div`
	display: block;
	position: relative;
`;

const Child = styled.div`
    display: block;
    position: relative;
	height: 100%;

	//&.axis-x {
	//	&.direction-forward {
	//		transform: translateX(-100%);
	//	}
	//	&.direction-backward {
	//		transform: translateX(100%);
	//	}
	//}
	//&.axis-y {
	//	&.direction-forward {
	//		transform: translateY(100%);
	//	}
	//	&.direction-backward {
	//		transform: translateY(-100%);
	//	}
	//}
`;
export const REVEAL_CLASSNAME = 'reveal';
const SlideInY = ({ target, timeline = null, vars = {} }) => {
	// console.log(target);
	const tl = timeline || gsap.timeline();
	tl.fromTo(target, {
		y: '100%'
	}, {
		y: 0,
		delay,
		...vars,
	});
	return tl;
};
const SlideInX = ({ target, timeline = null, vars = {}}) => {
	const tl = timeline || gsap.timeline();
	tl.fromTo(target, {
		x: '-100%'
	},{
		x: 0,
		delay,
		...vars,
	});
	return tl;
};

const DEFAULT_PROPS = {
	id: '',
	onInView : false,
	animationVars : {},
	timeline: null,
	pinnedContainer : { current: null },
	axis : 'y',
	once : false,
	paused: false,
	debug:false,
	disabled: false,
}

const TRIGGERS = {
	DEFAULT: {
		start: `top bottom`,
		end: `bottom top`
	},
	ONCE: {
		start: `top bottom`,
		end: `top bottom`
	}
}

export function useReveal({
	el,
	target,
	id: passedId = DEFAULT_PROPS.id,
	onInView = DEFAULT_PROPS.onInView,
	animationVars = DEFAULT_PROPS.animationVars,
	timeline=DEFAULT_PROPS.timeline,
	pinnedContainer = DEFAULT_PROPS.pinnedContainer,
	axis = DEFAULT_PROPS.axis,
	once = DEFAULT_PROPS.once,
	paused= DEFAULT_PROPS.paused,
	debug: forceDebug=DEFAULT_PROPS.debug,
	disabled=DEFAULT_PROPS.disabled,
  	}){
	const debug = useIsDebug(forceDebug)
	const memoPinnedContainer = useRef(null)
	memoPinnedContainer.current = pinnedContainer?.current
	const props = useMemo(() => {
		const trigger = once ? TRIGGERS.ONCE : TRIGGERS.DEFAULT
		return {
			id: `${REVEAL_CLASSNAME}-${passedId}`,
			disabled: !onInView || disabled,
			trigger,
			markers: debug,
			once: debug ? !debug : once,
			pinnedContainer: memoPinnedContainer
		}
	},[debug, onInView, disabled, passedId, once])

	// console.log(props.once);

	const inViewProps = useInView(el, props);
	const trigger = useMemo(() => {
		const trigger = once ? 'intersected' : 'inView'
		return inViewProps[trigger]
	},[inViewProps, once])

	const animationTL = useMemo(() => timeline || gsap.timeline(), [timeline]);
	const memoAnimationVars = useRef(animationVars)

	useIsomorphicLayoutEffect(() => {
		memoAnimationVars.current = animationVars
	},[animationVars]);

	useGSAP(() => {
		// console.log('once:', once);
		// console.log('trigger:', trigger);
		if(!disabled && target?.current){
			animationTL.pause()
			const vars = {
				...memoAnimationVars.current,
			}
			if (axis === 'x') {
				SlideInX({
					target: target.current,
					vars,
					timeline: animationTL
				});
			} else {
				SlideInY({
					target: target.current,
					vars,
					timeline: animationTL
				});
			}

			if(trigger && !paused){
				// console.log('play');
				animationTL.play(0)
			}
		}
	},{
		dependencies: [trigger, paused],
		revertOnUpdate: true
	})
}

const Reveal = forwardRef(function Reveal({
	children,
	as='div',
	direction = 'forward',
	className,
	id = DEFAULT_PROPS.id,
	onInView = DEFAULT_PROPS.onInView,
	animationVars = DEFAULT_PROPS.animationVars,
	timeline=DEFAULT_PROPS.timeline,
	pinnedContainer = DEFAULT_PROPS.pinnedContainer,
	axis = DEFAULT_PROPS.axis,
	once = DEFAULT_PROPS.once,
	paused= DEFAULT_PROPS.paused,
	debug=DEFAULT_PROPS.debug,
	disabled=DEFAULT_PROPS.disabled,
	parentProps,
	childProps,
	...props
}, fref){
	const ParentRef = useRef()
	const TargetRef = useRef()

	useReveal({
		el: ParentRef,
		target: TargetRef,
		id,
		onInView,
		animationVars ,
		timeline,
		pinnedContainer,
		axis,
		once,
		paused,
		debug,
		disabled,
		...props
	})

	useImperativeHandle(fref, () => ParentRef.current, [])

	return (
		<Parent
			id={id}
			as={as}
			ref={ParentRef}
			className={`${REVEAL_CLASSNAME}-parent axis-${axis || ''} ${disabled ? 'disabled' : ''} ${className || ''}`}
			// className={`${REVEAL_CLASSNAME}-parent ${className || ''}`}
			{...parentProps}
		>
			<Child
				as={as}
				ref={TargetRef}
				// className={`${REVEAL_CLASSNAME}-child ${!disabled ? `axis-${axis}` : ''} ${disabled ? 'disabled' : ''} direction-${direction}`}
				className={`${REVEAL_CLASSNAME}-child ${disabled ? 'disabled' : ''}`}
				{...childProps}
			>
				{children}
			</Child>
		</Parent>
	);
})

export default memo(Reveal);
