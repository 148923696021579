import styled from "styled-components";
import useGlobals from "@/hooks/useGlobals";
import PreserveSearchParamsLink from "@/components/common/Links/PreserveSearchParamsLink";
import {BREAKPOINTS} from "@/styles/themeSettings";
import {getCssSizeValue, getItemType, getItemUrl} from "@/utils/utils";
import {useState} from "react";
import {motion} from "framer-motion";
import Text from "@/components/common/Text";
import {insetPaddingLeftCss, insetPaddingRightCss} from "@/styles/globalStyles";
import {CONTENT_TYPES} from "@/constants";
import {getLinkData} from "@/components/common/Links/utils";

const StyledWrapper = styled.div`
    margin-top: ${getCssSizeValue(16, BREAKPOINTS.MOBILE)};
	margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-top: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-top: ${getCssSizeValue(58)};
		margin-bottom: unset;
        padding-bottom: ${getCssSizeValue(32)};
    }
`

const StyledTitle = styled.div`
	position: relative;
	text-transform: uppercase;
    margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.MOBILE)};
	
    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        margin-bottom: ${getCssSizeValue(32, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        margin-bottom: ${getCssSizeValue(32)};
    }
`

const StyledTopBorder = styled.div`
	position: absolute;
	top: ${getCssSizeValue(-16, BREAKPOINTS.MOBILE)};
	${insetPaddingLeftCss};
	${insetPaddingRightCss};
	width: 100%;
	height: 1px;
	background: ${({theme}) => theme.colors.slateGray};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
		display: none;
    }
`

const StyledMainLinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
    gap: ${getCssSizeValue(24, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        gap: ${getCssSizeValue(20, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(20)};
    }
`

const StyledExpandedLinksWrapper = styled(motion.div)`
	display: flex;
	overflow: hidden;
	flex-direction: column;
    gap: ${getCssSizeValue(12, BREAKPOINTS.MOBILE)};

    @media (min-width: ${({theme}) => theme.breakpoints.tablet}px) {
        transform: translateX(${getCssSizeValue(34, BREAKPOINTS.TABLET)});
        gap: ${getCssSizeValue(6, BREAKPOINTS.TABLET)};
    }
	
    @media (min-width: ${({theme}) => theme.breakpoints[BREAKPOINTS.DESKTOP]}px) and (orientation: landscape) {
        gap: ${getCssSizeValue(16)};
		transform: translateX(${getCssSizeValue(34)});
    }
`

const StyledMotionLinkWrapper = styled(motion.div)`
	cursor: pointer;
`

const StyledLink = styled(Text)`
	&.header {
		color: ${({theme}) => theme.colors.richBlack} !important;
		--square-scale: 0;
		width: fit-content;
		&:before{
			transform-origin: left center;
			background-color: ${({ theme }) => theme.colors.chilliRed };
			transition: all var(--rgr-duration) var(--ease);
			opacity: 0;
		}
		&:not(&.disabled) {
			&:hover{
				--square-scale: 1;
				--color: black;

				font-weight: ${({theme}) => theme.fontWeight.black};
				&:before{
					opacity: 1;
				}
			}
		}
	}
`

export default function SiteMap() {
	const footer = useGlobals(state => state.footer)
	const title = footer?.siteMap?.title || ''
	const options = footer?.siteMap?.options || []

	const [expandedItemId, setExpandedItemId] = useState(null)

	return <StyledWrapper className='col-mobile-8 col-tablet-3 col-desktop-5'>
		<StyledTitle className='typo-strawford typo-strawford-49'>
			<StyledTopBorder />
			{title}
		</StyledTitle>
		<StyledMainLinksWrapper>
			{options.map((option, index) => {
				const id = option?.link?.id || index
				const typename = getItemType(option)

				if (typename === CONTENT_TYPES.SITE_MAP_SINGLE_LINK_BLOCK) {
					const { url, pageName } = getLinkData(option)
					return (
						<PreserveSearchParamsLink
							className='typo-borna typo-borna-68'
							href={url}
							key={id}
						>
							<StyledLink className='square header'>
								{pageName}
							</StyledLink>
						</PreserveSearchParamsLink>
					)
				}

				if (typename === CONTENT_TYPES.SITE_MAP_LINKS_GROUP_BLOCK) {
					const name = option?.name || ''
					const links = option?.links || []
					return (
						<div key={id}>
							<StyledMotionLinkWrapper
								animate={{
									paddingBottom: id === expandedItemId ? 16 : 0
								}}
								onClick={() => {
									id === expandedItemId ? setExpandedItemId(null) : setExpandedItemId(id)
								}}
							>
								<StyledLink className='square header typo-borna typo-borna-68'>
									{name}
								</StyledLink>
							</StyledMotionLinkWrapper>
							<StyledExpandedLinksWrapper
								initial={false}
								animate={{
									height: id === expandedItemId ? 'auto' : 0
								}}
							>
								{links.map((link, index) => {
									const id = link?.link?.id || index
									const pageName = link?.link?.pageName || link?.label ||  ''
									const url = getItemUrl(link?.link)

									return (
										<PreserveSearchParamsLink
											className='typo-strawford typo-strawford-51'
											href={url}
											key={id}
										>
											<StyledLink className='square header'>
												{pageName}
											</StyledLink>
										</PreserveSearchParamsLink>
									)
								})}
							</StyledExpandedLinksWrapper>
						</div>
					)
				}

				return null
			})}
		</StyledMainLinksWrapper>
	</StyledWrapper>
}
