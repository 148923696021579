const today = new Date();

function addMonths(date, months) {
	const result = new Date(date);
	result.setMonth(result.getMonth() + months);
	return result;
}

export function setToFirstDayOfMonth(date) {
	const result = new Date(date);
	result.setDate(1);
	result.setHours(0, 0, 0, 0); // Set time to midnight
	return result;
}

export function setToLastDayOfMonth(date) {
	const result = new Date(date);
	result.setMonth(result.getMonth() + 1);
	result.setDate(0); // Setting date to 0 gets the last day of the previous month
	result.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
	return result;
}

// Function to get the first day of the month a certain number of months ago
function getFirstDayOfMonthMonthsAgo(date, months) {
	const dateWithMonthsAdded = addMonths(date, -months);
	return setToFirstDayOfMonth(dateWithMonthsAdded);
}

// Function to get the last day of the month a certain number of months forward
function getLastDayOfMonthMonthsForward(date, months) {
	const dateWithMonthsAdded = addMonths(date, months);
	return setToLastDayOfMonth(dateWithMonthsAdded);
}

// Function to format date to the desired ISO 8601 string with timezone offset
export function formatToISO(date) {
	const pad = (num) => String(num).padStart(2, '0');

	const year = date.getFullYear();
	const month = pad(date.getMonth() + 1); // getMonth() is zero-based
	const day = pad(date.getDate());
	const hours = pad(date.getHours());
	const minutes = pad(date.getMinutes());
	const seconds = pad(date.getSeconds());

	const timezoneOffset = date.getTimezoneOffset();
	const sign = timezoneOffset > 0 ? '-' : '+';
	const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
	const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

	return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`;
}


export function getCalendarDateRange(monthsAgo, monthsForward){
	// Get the first day of the month, x months ago
	const firstDayOfMonthMonthsAgo = getFirstDayOfMonthMonthsAgo(today, monthsAgo);

	// Get the last day of the month, x months forward
	const lastDayOfMonthMonthsForward = getLastDayOfMonthMonthsForward(today, monthsForward);

	// Format the dates
	const formattedStart = formatToISO(firstDayOfMonthMonthsAgo);
	const formattedEnd = formatToISO(lastDayOfMonthMonthsForward);

	return {
		start: firstDayOfMonthMonthsAgo,
		end: lastDayOfMonthMonthsForward,
		ISOStart: formattedStart,
		ISOEnd: formattedEnd
	}
}

